import React, { useState } from "react";
import { Box, Flex, Spacer } from "@chakra-ui/react";
import Breadcrumbs from "../components/breadcrumbs";
import { AddManufacturerModal } from "../components/addManufacturerModal";
import SearchFilter from "../components/searchFilter";
import ManufacturersTable from "../components/manufacturersTable";
import { useSelector } from "react-redux";

export default function Manufacturers() {
  const manufacturerData = useSelector(
    (state) => state.manufacturer.manufacturerData,
  );
  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
    },
  };

  const [searchParams, setSearchParams] = useState({
    name: "",
  });
  const [filteredManufacturerData, setfilteredManufacturerData] =
    useState(manufacturerData);

  const handleSearch = () => {
    const newfilteredManufacturerData = manufacturerData.filter((data) => {
      const modelMatch =
        !searchParams.name ||
        data.manufacturer
          .toLowerCase()
          .includes(searchParams.name.toLowerCase());

      return modelMatch;
    });

    setfilteredManufacturerData(newfilteredManufacturerData);
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
    });

    setfilteredManufacturerData(manufacturerData);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Flex>
        <Box>
          <Breadcrumbs />
        </Box>
        <Spacer />

        <AddManufacturerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
        />
      </Flex>

      <Box {...styles.topBox}>
        <SearchFilter
          type="manufacturer"
          styles={styles}
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <ManufacturersTable manufacurers={filteredManufacturerData} />
      </Box>
    </Box>
  );
}
