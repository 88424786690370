import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import customTheme from "./assets/theme/switchTheme";
import Navigations from "./routes/navigation";

function App() {
  const authState = useSelector((state) => state?.auth?.is_authenticated);
  const authData = useSelector((state) => state?.auth);

  if (
    authState &&
    window.location.pathname === "/" &&
    authData?.is_setup_complete === false
  ) {
    window.location.href = "/dashboard";
  } else if (
    authState &&
    window.location.pathname === "/" &&
    authData?.is_setup_complete
  ) {
    window.location.href = "/dashboard";
  }
  return (
    <ChakraProvider theme={customTheme}>
      <RouterProvider
        router={Navigations()}
        fallbackElement={<p>Initial Load...</p>}
      />
    </ChakraProvider>
  );
}

export default App;
