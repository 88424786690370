import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Select,
  Text,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaUpload } from "react-icons/fa";
import {
  addFirmwareAPI,
  getFirmwareListAPI,
} from "../redux/helpers/firmwareAPI";
import { FieldArray, Formik, Field } from "formik";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { addModelAPI, getModelsAPI } from "../redux/helpers/controllerAPI";
import Loader from "./loader";
import { addAttributeButtontext, deviceDataAttributeHeadText, deviceDataAttributeHelperText } from "../utills/labels";

function ModelsTabs() {
  const location = useLocation();
  const toast = useToast();
  const { customer } = location.state || {};
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    formItem: {
      m: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
    bredcrumbsContainer: {
      mb: 6,
      spacing: "8px",
      cursor: isHovered ? "pointer" : "default",
      textDecoration: isHovered ? "underline" : "default",
    },
    cancelButton: {
      variant: "outline",
      size: "sm",
      mr: 5,
    },
    submitButton: {
      colorScheme: "facebook",
      size: "sm",
      justifyContent: "end",
    },
  };

  const dispatch = useDispatch();
  const initialRef = useRef(null);
  const types = useSelector((state) => state?.controllers?.types);
  const units = useSelector((state) => state?.controllers?.units);
  const activeType = useSelector((state) => state?.controllers?.activeType);

  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");

  const firmwareNameRegex = /^[a-zA-Z0-9-.\s]+$/;
  const nameRegex = /^[a-zA-Z0-9-\s]+$/;
  const validationSchema = Yup.object().shape({
    device_type: Yup.string().required("Type is required"),
    device_model_name: Yup.string()
      .required("Model name is required")
      .max(45, "Model name must be at most 45 characters.")
      .matches(
        /^[A-Za-z0-9\- ]+$/,
        "Model name can only contain Letters, Numbers, Hyphen & Spaces"
      ),
    seeding_serial_number:Yup.string().required("Serial number is required").matches(/^[0-9\s]+$/, "Serial number can only contain Numbers"),
    device_attributes: Yup.array().of(
      Yup.object().shape({
        monitoring_value: Yup.string()
          .required("Monitoring Value is required")
          .max(45, "Monitoring Value must be at most 45 characters.")
          .matches(
            /^[A-Za-z0-9 ]+$/,
            "Monitoring Value can only contain Letters, Numbers & Spaces"
          ),
        min: Yup.number().required("Minimum value is required"),
        max: Yup.number()
          .required("Maximum value is required")
          .test(
            "max-greater-than-min",
            "Max must be greater than Min",
            function (value) {
              const { min } = this.parent;
              return value > min;
            }
          ),
        unit_id: Yup.string().required("Unit is required"),
      })
    ),
    name: Yup.string()
      .max(45, "Firmware name must be at most 45 characters.")
      .matches(
        firmwareNameRegex,
        "Firmware name can only contain Letters, Numbers, Full Stop, Hyphen & Spaces"
      )
      .required("Firmware name is required"),
    // device_model: Yup.string().required("Firmware model is required"),
    code_name: Yup.string()
      .max(45, "Code name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Code name can only contain Letters, Hyphen, Numbers & Spaces"
      ),
  });

  const onSave = (payload, firmwareValues) => {
    setLoading(true);
    try {
      let addModelres = dispatch(addModelAPI(payload));
      addModelres.then((data) => {
        try {
          const firmwarePayload = {
            name: firmwareValues?.name,
            version_number:firmwareValues?.version_number,
            model_id: data?.payload?.model_id,
            code_name: firmwareValues?.code_name
              ? firmwareValues?.code_name
              : firmwareValues?.name,
            is_prod: firmwareValues?.is_prod === true ? 1 : 0,
          };
          if (firmwareValues?.file) {
            firmwarePayload.file = firmwareValues?.file;
          }
          const addFirmwareReq = dispatch(
            addFirmwareAPI(firmwarePayload)
          );
          addFirmwareReq
            .then(async (firmwareData) => {
              if (firmwareData?.payload?.success) {
                await dispatch(getFirmwareListAPI());
                await dispatch(getModelsAPI());
                toast({
                  title: "Success",
                  position: "top-right",
                  description:
                    "Model and Firmware has been added successfully",
                  status: "success",
                  duration: 6000,
                  isClosable: true,
                });
                handleBackClick()
                setFileName("");
                setLoading(false);
              } else {
                setLoading(false);
                toast({
                  title: "Error",
                  description:
                  firmwareData?.payload?.message ||
                    "Failed to perform the operation",
                  status: "error",
                  duration: 6000,
                  isClosable: true,
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
              toast({
                title: "Error",
                description:"Failed to perform the operation",
                status: "error",
                duration: 6000,
                isClosable: true,
              });
            });
        } catch (err) {
          setLoading(false);
          console.log("errror in api", err);
        }
        const { success, message } = data.payload;
        if (success) {
          dispatch(getModelsAPI())
            .then(() => {
              setLoading(false);
              handleBackClick();
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleSubmit = (values) => {
    onSave(
      {
        type_id: parseInt(values?.device_type),
        name: values.device_model_name,
        data_points: values.device_attributes.map((attribute) => ({
          ...attribute,
        })),
        seeding_serial_number:values.seeding_serial_number,
      },
      values
    );
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleBackClick = () => {
    navigate("/product-configuration", { state: { tourindex: 0 } });
  };

  const handleReset = () => {
    initialRef.current.reset();
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex
        direction={"row"}
        justifyContent={"space-between"}
        alignContent={"center"}
      >
        <Text
          {...styles.bredcrumbsContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Add New Model
        </Text>
      </Flex>
      <Card size={{ base: "sm", md: "md" }}>
        <CardBody>
          <Flex {...styles.flexContainer}>
            <Formik
              initialValues={{
                device_type: activeType?.id || "",
                device_model_name: "",
                seeding_serial_number:"",
                device_attributes: [],

                name: "",
                firmware_version: "",
                // file: null,
                // is_prod: false,
              }}
              validationSchema={validationSchema}
              validateOnChange
              validateOnBlur
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldTouched,
                resetForm,
                setFieldValue,
              }) => {
                useEffect(() => {
                  if (Object.keys(errors).length)
                    if (values?.device_attributes?.length === 1) {
                      setFieldTouched(
                        `device_attributes.${0}.monitoring_value`,
                        true
                      );
                      setFieldTouched(`device_attributes.${0}.unit_id`, true);
                      setFieldTouched(`device_attributes.${0}.min`, true);
                      setFieldTouched(`device_attributes.${0}.max`, true);
                    } else {
                      values?.device_attributes?.forEach((_, index) => {
                        setFieldTouched(
                          `device_attributes.${index}.monitoring_value`,
                          true
                        );
                        setFieldTouched(
                          `device_attributes.${index}.unit_id`,
                          true
                        );
                        setFieldTouched(`device_attributes.${index}.min`, true);
                        setFieldTouched(`device_attributes.${index}.max`, true);
                      });
                    }
                }, [values.device_attributes, errors]);
                return (
                  <form ref={initialRef} onSubmit={handleSubmit}>
                    <FormControl
                      isInvalid={errors?.device_type && touched?.device_type}
                    >
                      <FormLabel style={{fontWeight:'bold'}}>Product</FormLabel>
                      <Text
                        style={{
                          marginTop: 6,
                          fontWeight:'bold',
                        }}
                      >
                        {activeType?.name}
                      </Text>
                      
                      {/* <Select
                        isDisabled={true}
                        {...styles.input}
                        id="device_type"
                        ref={initialRef}
                        name="device_type"
                        placeholder="Select Product"
                        value={values?.device_type}
                        onChange={handleChange}
                        style={{
                          border: '2px solid black',
                          color: 'black',
                          backgroundColor: '#f5f5f5',
                          fontWeight: 'bold',
                          textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
                        }}                      >
                        {types
                          ?.map((val) => (
                            <option key={uuidv4()} value={val?.id}>
                              {val?.name}
                            </option>
                          ))}
                      </Select>
                      <FormErrorMessage>{errors.device_type}</FormErrorMessage> */}
                    </FormControl>

                    <FormControl
                      isInvalid={
                        errors?.device_model_name && touched?.device_model_name
                      }
                    >
                      <FormLabel marginTop={3}>Model</FormLabel>
                      <Input
                        {...styles.input}
                        placeholder="Ex: RX-100"
                        name="device_model_name"
                        value={values.device_model_name}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>
                        {errors.device_model_name}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={errors.seeding_serial_number && touched.seeding_serial_number}
                    >
                      <FormLabel marginTop={3}>Seeding Serial Number</FormLabel>
                      <Input
                        {...styles.input}
                        type="number"
                        placeholder="Seeding Serial Number"
                        id="seeding_serial_number"
                        name="seeding_serial_number"
                        value={values.seeding_serial_number}
                        onChange={handleChange}
                      />
                      <FormErrorMessage>{errors.seeding_serial_number}</FormErrorMessage>
                    </FormControl>

                    <Text style={{ marginTop: 10 }}>
                      {deviceDataAttributeHeadText}
                    </Text>
                    <Text
                      style={{
                        marginTop: 6,
                        fontSize: 12,
                        fontStyle: "normal",
                        fontWeight: 300,
                      }}
                    >
                    {deviceDataAttributeHelperText}
                    </Text>

                    <FieldArray
                      name="device_attributes"
                      style={{
                        marginBottom: 22,
                        padding: 4,
                        marginTop: 4,
                        border: "1px dotted lightgrey",
                        borderRadius: 8,
                      }}
                    >
                      {(arrayHelpers) => (
                        <>
                          {values?.device_attributes?.map(
                            (attribute, index) => {
                              const availableUnits =
                                units?.filter(
                                  (item) =>
                                    item?.name === attribute?.monitoring_value
                                )?.[0]?.measurements || [];
                              return (
                                <Box
                                  key={index}
                                  style={{
                                    marginBottom: 22,
                                    padding: 10,
                                    marginTop: 6,
                                    border: "1px dotted lightgrey",
                                    borderRadius: 8,
                                  }}
                                >
                                  <Flex justifyContent={"end"}>
                                    <IconButton
                                      zIndex={1}
                                      size={"xs"}
                                      fontSize={"10px"}
                                      color={"red"}
                                      icon={<CloseIcon />}
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </Flex>

                                  <FormControl
                                    marginTop={-4}
                                    isInvalid={
                                      errors.device_attributes &&
                                      errors.device_attributes[index]
                                        ?.monitoring_value &&
                                      touched.device_attributes &&
                                      touched.device_attributes[index]
                                        ?.monitoring_value
                                    }
                                  >
                                    <FormLabel>
                                      Measurement {index + 1}
                                    </FormLabel>
                                    <Select
                                      {...styles.input}
                                      placeholder="Select Measurement"
                                      name={`device_attributes.${index}.monitoring_value`}
                                      value={attribute?.monitoring_value || ""}
                                      onChange={handleChange}
                                    >
                                      {[
                                        ...new Set(
                                          units?.map((unit) => unit?.name)
                                        ),
                                      ]?.map((val) => (
                                        <option key={val} value={val}>
                                          {val}
                                        </option>
                                      ))}
                                    </Select>
                                    <FormErrorMessage>
                                      {errors.device_attributes &&
                                        errors.device_attributes[index]
                                          ?.monitoring_value}
                                    </FormErrorMessage>
                                  </FormControl>

                                  <FormControl
                                    pt={2}
                                    isInvalid={
                                      errors.device_attributes &&
                                      errors.device_attributes[index]
                                        ?.unit_id &&
                                      touched.device_attributes &&
                                      touched.device_attributes[index]?.unit_id
                                    }
                                  >
                                    <FormLabel>
                                      Unit of Measure {attribute?.monitoring_value || ""}
                                    </FormLabel>
                                    <Select
                                      {...styles.input}
                                      placeholder="Select Unit of Measure"
                                      name={`device_attributes.${index}.unit_id`}
                                      value={attribute?.unit_id || ""}
                                      onChange={handleChange}
                                    >
                                      {availableUnits?.map((unit) => (
                                        <option key={unit?.id} value={unit?.id}>
                                          {unit?.name}
                                        </option>
                                      ))}
                                    </Select>
                                    <FormErrorMessage>
                                      {errors.device_attributes &&
                                        errors.device_attributes[index]
                                          ?.unit_id}
                                    </FormErrorMessage>
                                  </FormControl>

                                  <Center pt={2}>
                                    <FormControl
                                      isInvalid={
                                        errors.device_attributes &&
                                        errors.device_attributes[index]?.min &&
                                        touched.device_attributes &&
                                        touched.device_attributes[index]?.min
                                      }
                                    >
                                      <FormLabel fontSize={"13px"}>
                                        Minimum Value
                                      </FormLabel>
                                      <Input
                                        {...styles.input}
                                        placeholder="Enter minimum value"
                                        type="number"
                                        name={`device_attributes.${index}.min`}
                                        value={attribute?.min || ""}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {errors.device_attributes &&
                                          errors.device_attributes[index]?.min}
                                      </FormErrorMessage>
                                    </FormControl>
                                    <Box px={2}></Box>
                                    <FormControl
                                      isInvalid={
                                        errors.device_attributes &&
                                        errors.device_attributes[index]?.max &&
                                        touched.device_attributes &&
                                        touched.device_attributes[index]?.max
                                      }
                                    >
                                      <FormLabel fontSize={"13px"}>
                                        Maximum Value
                                      </FormLabel>
                                      <Input
                                        {...styles.input}
                                        placeholder="Enter maximum value"
                                        type="number"
                                        name={`device_attributes.${index}.max`}
                                        value={attribute?.max || ""}
                                        onChange={handleChange}
                                      />
                                      <FormErrorMessage>
                                        {errors.device_attributes &&
                                          errors.device_attributes[index]?.max}
                                      </FormErrorMessage>
                                    </FormControl>
                                  </Center>
                                </Box>
                              );
                            }
                          )}
                          <Button
                            type="button"
                            style={{marginTop: 18 ,marginBottom: 18 }}
                            onClick={() =>
                              arrayHelpers.push({
                                monitoring_value: "",
                                unit_id: "",
                                min: "",
                                max: "",
                              })
                            }
                            size={"xs"}
                            colorScheme="teal"
                          >
                            {addAttributeButtontext}
                          </Button>
                        </>
                      )}
                    </FieldArray>

                    <Card size={{ base: "sm", md: "md" }}>
                      <CardBody>
                        <Text
                          {...styles.deviceHeader}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          style={{fontWeight:"bold"}}
                        >
                          New Firmware
                        </Text>

                        <FormControl
                          {...styles.formItem}
                          isInvalid={errors.name && touched.name}
                        >
                          <FormLabel> Firmware Name </FormLabel>
                          <Field
                            as={Input}
                            {...styles.input}
                            style={{ marginTop: 10 }}
                            ref={initialRef}
                            placeholder="Firmware Name"
                            id="name"
                            name="name"
                          />
                          <FormErrorMessage>{errors.name}</FormErrorMessage>
                        </FormControl>

                        <FormControl
                          {...styles.formItem}
                          isInvalid={errors.version_number && touched.version_number}
                        >
                          <FormLabel>Firmware Version</FormLabel>
                          <Field
                            as={Input}
                            {...styles.input}
                            placeholder="Firmware Version"
                            name="version_number"
                            id="version_number"
                          />
                          <FormErrorMessage>
                            {errors.version_number}
                          </FormErrorMessage>
                        </FormControl>
                      {/*  <FormControl
                          {...styles.formItem}
                          isInvalid={errors.file && touched.file}
                        >
                          <FormLabel htmlFor="file">Upload File</FormLabel>
                          <input
                            type="file"
                            id="file"
                            name="file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(event) => {
                              setFieldValue("file", event.target.files[0]);
                              setFileName(event.target.files[0].name);
                            }}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="file">
                            <Button
                              as="span"
                              leftIcon={<FaUpload />}
                              cursor="pointer"
                              variant="outline"
                              colorScheme="blue"
                            >
                              Choose File
                            </Button>
                          </label>
                          {fileName && <div>{fileName}</div>}
                          <FormErrorMessage>{errors.file}</FormErrorMessage>
                        </FormControl>

                        <FormControl
                          {...styles.formItem}
                          isInvalid={errors.is_prod && touched.is_prod}
                        >
                          <Checkbox
                            name="is_prod"
                            id="is_prod"
                            colorScheme="green"
                            isChecked={values.is_prod}
                            onChange={(e) => {
                              setFieldValue("is_prod", e.target.checked);
                            }}
                          >
                            Production
                          </Checkbox>

                          <FormErrorMessage>{errors.is_prod}</FormErrorMessage>
                        </FormControl> */}
                      </CardBody>
                    </Card>

                    <Flex mt={4}>
                      <Button
                        {...styles.cancelButton}
                        onClick={handleBackClick}
                        colorScheme="red"
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        onClick={() => {
                          resetForm();
                          handleReset();
                          setFileName();
                        }}
                        {...styles.cancelButton}
                      >
                        Reset
                      </Button>
                      <Button
                        {...styles.submitButton}
                        type="submit"
                        colorScheme="facebook"
                      >
                        Create Model
                      </Button>
                    </Flex>
                  </form>
                );
              }}
            </Formik>
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
}

export default ModelsTabs;
