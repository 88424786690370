import { Box, Flex, Spacer, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AddCustomerModal } from "../components/addCustomerModal";
import Breadcrumbs from "../components/breadcrumbs";
import CustomersTable from "../components/customersTable";
import Loader from "../components/loader";
import SearchFilter from "../components/searchFilter";
import SuccessMessageModal from "../components/successMessageModal";
import {
  addOrganizationAPI,
  // getCustomerAPI,
  getCustomerTeamAPI,
  getOrganizationsAPI,
} from "../redux/helpers/customerAPI";
import {
  setActiveCustomerOrg,
  setActiveSellerOrgId,
} from "../redux/reducers/customerReducer";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";

export default function CustomersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const toast = useToast();
  // const customerData = useSelector((state) => state?.customer?.customerData);
  const organizations = useSelector((state) =>
    [...state?.customer?.organizations]?.sort((a, b) => a.id - b.id)
  );
  const location = useLocation();
  const { isOpen } = location.state || false;

  useEffect(() => {
    // if (!customerData || customerData?.length === 0) {
      setLoading(true);
      // dispatch(getCustomerAPI())
      dispatch(getOrganizationsAPI())
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    // }
  }, []);

  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
    },
  };

  const [searchParams, setSearchParams] = useState({
    name: "",
  });
  const [filteredCustomerData, setfilteredCustomerData] =
    useState(organizations);

  const handleSearch = () => {
    const newfilteredCustomerData = organizations?.filter((customer) => {
      const nameMatch =
        !searchParams.name ||
        customer.name.toLowerCase().includes(searchParams.name.toLowerCase()) ||
        customer.first_name
          .toLowerCase()
          .includes(searchParams.name.toLowerCase()) ||
        customer.last_name
          .toLowerCase()
          .includes(searchParams.name.toLowerCase());

      return nameMatch;
    });

    setfilteredCustomerData(newfilteredCustomerData);
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
    });

    setfilteredCustomerData(organizations);
  };

  const handleRowClick = async (customer) => {
    console.log(customer);
    setLoading(true);
    try {
      await dispatch(setActiveCustomerOrg(customer));
      // const customerDataId = customerData?.filter(
      //   (val) => val?.organization === customer?.name
      // );
      // const customerDataId = customer
      const activeSellerOrgRes = await dispatch(
        setActiveSellerOrgId(customer?.id)
      );
      if (activeSellerOrgRes?.payload) {
        const getCustomerTeamRes = await dispatch(getCustomerTeamAPI());

        if (getCustomerTeamRes?.payload?.success) {
          setLoading(false);
          navigate(`/customers/${customer?.name?.replace(/ /g, "-")}`, {
            state: { customer },
          });
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const refreshCustomers = () => {
    dispatch(getOrganizationsAPI()).then((response) => {
      setfilteredCustomerData(response?.payload?.data);
    });
    setSearchParams({
      name: "",
    });
  };

  const onSaveCustomer = async (payload) => {
    setLoading(true);
    try {
      payload.role_id = 1; // 1 will be the default admin role
      const addCustomerReq = dispatch(addOrganizationAPI(payload));
      addCustomerReq
        .then(async (result) => {
          if (result?.payload?.success === true) {
            // await dispatch(getCustomerAPI());
            await dispatch(getDashboardDataAPI());
            const response = await dispatch(getOrganizationsAPI());
            setfilteredCustomerData(response?.payload?.data);
            handleCloseModal();
            setLoading(false);
            toast({
              title: "Success",
              position: "top-right",
              description: result?.payload?.message,
              status: "success",
              duration: 6000,
              isClosable: true,
            });
            // setisSuccessModalOpen(true);
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex>
        <Box>
          <Breadcrumbs />
        </Box>
        <Spacer />
        <AddCustomerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          onSave={onSaveCustomer}
          type={"Add Customer"}
        />
      </Flex>

      <Box {...styles.topBox}>
        <SearchFilter
          type="name"
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <CustomersTable
          customers={filteredCustomerData}
          handleRowClick={handleRowClick}
          refreshCustomers={refreshCustomers}
        />
      </Box>
      {/* {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={"Successfully added customer to your account"}
          onClose={() => {
            setisSuccessModalOpen(false);
            dispatch(getOrganizationsAPI());
          }}
        />
      )} */}
    </Box>
  );
}
