import { Box, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Tour from "reactour";
import { userProfileAPI, userRolesAPI, userSellerProfileAPI } from "../redux/helpers/authAPI";
import {
  getModelsAPI,
  getTypesAPI,
  getUnitsAPI,
} from "../redux/helpers/controllerAPI";
import { getDashboardDataAPI, getSellerDashboardDataAPI } from "../redux/helpers/summaryAPI";
import FirstTimeUserOnboarding from "./firstTimeUserOnboarding";
import Header from "./header";
import Loader from "./loader";
import SidebarMenu from "./sidebarMenu";
import { setNewUserTour } from "../redux/reducers/authReducer";
import _debounce from "lodash/debounce";

export default function Layout({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const pathSegments = pathname.split("/").filter(Boolean);
  const firstTimeUser = useSelector((state) => state?.auth?.is_firstTime_user);
  const is_new_user = useSelector((state) => state?.auth?.is_new_user);
  const user_type = useSelector((state) => state?.auth?.user_type);

  useEffect(() => {
    if(user_type==="Manufacturer"){
      Promise.all([
        dispatch(userProfileAPI()),
        dispatch(getTypesAPI()),
        dispatch(getModelsAPI()),
        dispatch(getUnitsAPI()),
        dispatch(userRolesAPI()),
        dispatch(getDashboardDataAPI()),
      ])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
        });
    } else {
      Promise.all([
        dispatch(userSellerProfileAPI()),
        // dispatch(getTypesAPI()),
        // dispatch(getModelsAPI()),
        // dispatch(getUnitsAPI()),
        dispatch(userRolesAPI()),
        dispatch(getSellerDashboardDataAPI()),
      ])
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data:", error);
        });
    }
    // onOpen();
  }, []);

  const debouncedNavigate = _debounce(
    (path, state) => navigate(path, { state }),
    200
  );

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < steps?.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const [isTourOpen, setIsTourOpen] = useState(is_new_user);

  const openTour = () => {
    setIsTourOpen(true);
  };

  const closeTour = () => {
    setIsTourOpen(false);
    dispatch(setNewUserTour(false));
  };

  const styles = {
    contentArea: {
      backgroundColor: "#ebebeb",
      width: "100%",
      height: "90vh",
      overflowY: "auto",
      padding: { base: "12px", md: "26px" },
    },
    card: {
      bg: "balck",
      borderRadius: "md",
      // boxShadow: "md",
      p: "2",
      // m: "4",
      maxW: "300px",
      textAlign: "center",
    },

    cardHeading: {
      fontFamily: "'Poppins', sans-serif",
      marginbottom: "10px",
      as: "h2",
      size: ".4em",
      mb: "2",
      color: "gray.700",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },

    cardSubtext: {
      fontSize: "1em",
      fontFamily: "'Poppins', sans-serif",
      color: "gray.600",
    },
  };

  const toursteps = [
    {
      selector: '[tour-guide="step-1"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            Overview of key metrics and recent activities.
          </Heading>
          <Text {...styles.cardSubtext}>
            Summary of the product inventory and important data.
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/dashboard"),
    },
    {
      selector: '[tour-guide="step-2"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            Manage and view customer information.
          </Heading>
          <Text {...styles.cardSubtext}>
            Add customers, manage their accounts and assign products.
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/customers"),
    },
    {
      selector: '[tour-guide="step-3"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            View and edit product details and inventory.
          </Heading>
          <Text {...styles.cardSubtext}>
            View inventory, manage production of devices.
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/products"),
    },
    {
      selector: '[tour-guide="step-4"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>Update and manage firmware.</Heading>
          <Text {...styles.cardSubtext}>
            View and manage firmware inventory.
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/firmware"),
    },
    {
      selector: '[tour-guide="step-5"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            Collaborate with team members and assign roles.
          </Heading>
          <Text {...styles.cardSubtext}>
            Users can manage team members, assign roles and collaboration
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/team"),
    },
    {
      selector: '[tour-guide="step-6"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            Adjust application settings and preferences.
          </Heading>
          <Text {...styles.cardSubtext}>
            Setup your account here, with types and models to be sold.{" "}
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/product-configuration"),
    },
    {
      selector: '[tour-guide="step-7"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            Categorize devices by their functionality.
          </Heading>
          <Text {...styles.cardSubtext}>
            This section allows users to classify devices based on their
            specific functions, helping in organizing and managing different
            types of devices efficiently.
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/product-configuration"),
    },
    {
      selector: '[tour-guide="step-8"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            Create new device type categories.
          </Heading>
          <Text {...styles.cardSubtext}>
            Users can define new categories for device types, helping to expand
            the classification system as new types of devices are introduced.
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/product-configuration"),
    },
    {
      selector: '[tour-guide="step-9"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            View and edit specific device models.
          </Heading>
          <Text {...styles.cardSubtext}>
            Users can access detailed information about each device model,
            including specifications and features, and make necessary updates.
          </Text>
        </Box>
      ),
      action: () =>
        debouncedNavigate("/product-configuration", {
          tourindex: 1,
        }),
    },
    {
      selector: '[tour-guide="step-10"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            Add new device models to the database.
          </Heading>
          <Text {...styles.cardSubtext}>
            This functionality enables users to introduce new device models into
            the system, providing details like model name, specifications, and
            related information.
          </Text>
        </Box>
      ),
      action: () =>
        debouncedNavigate("/product-configuration", {
          tourindex: 1,
        }),
    },
    // {
    //   selector: '[tour-guide="step-7"]',
    //   content: () => (
    //     <Box {...styles.card}>
    //       <Heading {...styles.cardHeading}>
    //         Overview of key metrics and recent activities.
    //       </Heading>
    //       <Text fontSize="md" color="gray.500">
    //         This section provides a summary of important data and recent events
    //         to give users a quick snapshot of their application's status.
    //       </Text>
    //     </Box>
    //   ),
    // },
    // {
    //   selector: '[tour-guide="step-8"]',
    //   content: () => (
    //     <Box {...styles.card}>
    //       <Heading {...styles.cardHeading}>
    //         Overview of key metrics and recent activities.
    //       </Heading>
    //       <Text fontSize="md" color="gray.500">
    //         This section provides a summary of important data and recent events
    //         to give users a quick snapshot of their application's status.
    //       </Text>
    //     </Box>
    //   ),
    // },
    // {
    //   selector: '[tour-guide="step-9"]',
    //   content: () => (
    //     <Box {...styles.card}>
    //       <Heading {...styles.cardHeading}>
    //         Overview of key metrics and recent activities.
    //       </Heading>
    //       <Text fontSize="md" color="gray.500">
    //         This section provides a summary of important data and recent events
    //         to give users a quick snapshot of their application's status.
    //       </Text>
    //     </Box>
    //   ),
    // },
    // {
    //   selector: '[tour-guide="step-10"]',
    //   content: () => (
    //     <Box {...styles.card}>
    //       <Heading {...styles.cardHeading}>
    //         Overview of key metrics and recent activities.
    //       </Heading>
    //       <Text fontSize="md" color="gray.500">
    //         This section provides a summary of important data and recent events
    //         to give users a quick snapshot of their application's status.
    //       </Text>
    //     </Box>
    //   ),
    // },
    // {
    //   selector: '[tour-guide="step-11"]',
    //   content: () => (
    //     <Box {...styles.card}>
    //       <Heading {...styles.cardHeading}>
    //         Overview of key metrics and recent activities.
    //       </Heading>
    //       <Text fontSize="md" color="gray.500">
    //         This section provides a summary of important data and recent events
    //         to give users a quick snapshot of their application's status.
    //       </Text>
    //     </Box>
    //   ),
    // },
    // {
    //   selector: '[tour-guide="step-12"]',
    //   content: () => (
    //     <Box {...styles.card}>
    //       <Heading {...styles.cardHeading}>
    //         Overview of key metrics and recent activities.
    //       </Heading>
    //       <Text fontSize="md" color="gray.500">
    //         This section provides a summary of important data and recent events
    //         to give users a quick snapshot of their application's status.
    //       </Text>
    //     </Box>
    //   ),
    // },
  ];

  const steps = [
    { title: "Welcome" },
    { title: "Add Model" },
    { title: "Invite Team" },
  ];

  return (
    <div>
      <Loader loading={loading} />
      {["login", "signup", "forgot-password", "onboarding"].includes(
        pathSegments[0]
      ) ? (
        <Outlet />
      ) : (
        <Box>
          <Header />
          <Flex>
            {"onboarding".includes(pathSegments[0]) ? null : <SidebarMenu />}
            <Box {...styles.contentArea}>
              <Outlet />

              {!loading && (
                <Tour
                  steps={toursteps}
                  isOpen={isTourOpen}
                  onRequestClose={closeTour}
                />
              )}
            </Box>
          </Flex>
          {/* {firstTimeUser && (
            <FirstTimeUserOnboarding
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
              activeStep={activeStep}
              steps={steps}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )} */}
        </Box>
      )}
    </div>
  );
}
