/**
 * Used to Manage Devices for WG
 */
import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addDeviceAPI = createAsyncThunk(
  "addDevice",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(`/manufacturer/device`, payload, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const addBulkDeviceAPI = createAsyncThunk(
  "addBulkDevice",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: getState().auth.token,
        },
      };

      const formData = new FormData();
      formData.append("devices_data_file", payload.devices_data_file);
      formData.append(
        "create_new_if_not_present",
        payload.create_new_if_not_present
      );

      let response = await API.post(
        `/manufacturer/devices/bulk-upload`,
        formData,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getDeviceAPI = createAsyncThunk(
  "getDevice",
  async ( payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let url = `/manufacturer/device`
      if (payload) {
        url = `/manufacturer/device?device_id=${payload?.id}&id=${payload?.id}`
      }
      
      let response = await API.get(url, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getSellerDeviceAPI = createAsyncThunk(
  "getSellerDeviceAPI",
  async ( payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let url = `/seller/device`
      
      let response = await API.get(url, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const updateDeviceAPI = createAsyncThunk(
  "updateDevice",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.put(
        `/manufacturer/device/${payload?.device_id}`,
        payload,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const updateDeviceDataPointsAPI = createAsyncThunk(
  "updateDeviceDataPointsAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.put(
        `/manufacturer/device/${payload?.device_id}/data-points`,
        payload,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const assignDeviceAPI = createAsyncThunk(
  "assignDevice",
  async (payload, { getState }) => {
    try {
      console.log("assignDeviceAPI", getState().auth.token);

      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/manufacturer/assign-device`,
        {
          device_id: payload?.device_id,
          assigned_to_id: payload?.assigned_to_id,
        },
        config
      );
      console.log("response", response?.data);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const assignBulkDeviceAPI = createAsyncThunk(
  "assignBulkDevice",
  async (payload, { getState }) => {
    try {
      console.log("assignBulkDeviceAPI", getState().auth.token);

      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/manufacturer/assign/bulk/devices`,
        {
          revision_id: payload?.revision_id,
          assigned_to_id: payload?.assigned_to_id,
          quantity:payload?.quantity,
        },
        config
      );
      console.log("response", response?.data);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);
