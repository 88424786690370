import { createSlice } from "@reduxjs/toolkit";
import {
  addFirmwareAPI,
  deleteFirmwareAPI,
  editFirmwareAPI,
  getFirmwareListAPI,
} from "../helpers/firmwareAPI";

const initialState = {
  firmwareData: [],
};

export const firmwareSlice = createSlice({
  name: "firmware",
  initialState,
  reducers: {
    resetStateFirmware: (state, action) => {
      state = initialState;
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFirmwareListAPI.fulfilled, (state, action) => {
        console.log(action, "editFirmwareAPI");
        state.firmwareData = action.payload.data;
      })
      .addCase(getFirmwareListAPI.rejected, (state, action) => {
        console.log(action, "editFirmwareAPI");
        // state.firmwareData = [];
      })
      .addCase(addFirmwareAPI.fulfilled, (state, action) => {
        console.log(action, "addFirmwareAPI");
        state.firmwareData = action.payload.data;
      })
      .addCase(addFirmwareAPI.rejected, (action) => {
        console.log(action, "addFirmwareAPI");
      })
      .addCase(editFirmwareAPI.fulfilled, (state, action) => {
        console.log(action, "editFirmwareAPI");
        state.firmwareData = action.payload.data;
      })
      .addCase(editFirmwareAPI.rejected, (action) => {
        console.log(action, "editFirmwareAPI");
      })
      .addCase(deleteFirmwareAPI.fulfilled, (state, action) => {
        console.log(action, "deleteFirmwareAPI");
        state.firmwareData = action.payload.data;
      })
      .addCase(deleteFirmwareAPI.rejected, (action) => {
        console.log(action, "deleteFirmwareAPI");
      });
  },
});

export const { resetStateFirmware } = firmwareSlice.actions;

export default firmwareSlice.reducer;
