import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { SearchIcon } from "@chakra-ui/icons";

const SearchFilter = ({
  type,
  searchParams,
  handleInputChange,
  handleSearch,
  handleReset,
}) => {
  useEffect(() => {
    handleSearch();
  }, [searchParams]);
  const styles = {
    topGrid: {
      templateColumns: "repeat(4, 1fr)",
      gap: { base: 1, lg: 6 },
      px: { base: 0, lg: 4 },
      pb: { base: 2, md: 6 },
      alignItems: "end",
    },

    headerText: {
      color: "#4A5568",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      letterSpacing: "0.05rem",
      fontWeight: 700,
      py: "0.25rem",
    },
    searchBar: {
      borderRadius: 6,
    },
    searchButton: {
      colorScheme: "facebook",
      width: "66%",
      size: { base: "xs", md: "sm" },
    },
    resetButton: {
      colorScheme: "facebook",
      variant: "outline",
      width: "30%",
      size: { base: "xs", md: "sm" },
    },
  };
  return (
    <Grid {...styles.topGrid}>
      <GridItem colSpan={{ base: 4, sm: 4, md: 2, lg: 1 }}>
        <Box>
          <Text {...styles.headerText}>What are you looking for?</Text>{" "}
          <InputGroup size={"sm"}>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              {...styles.searchBar}
              type="text"
              placeholder={`Search by ${type}`}
              value={searchParams.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </InputGroup>
        </Box>
      </GridItem>

      <GridItem colSpan={{ base: 4, sm: 4, md: 2, lg: 1 }}>
        <Flex>
          <Button {...styles.searchButton} onClick={handleSearch}>
            Search
          </Button>
          <Spacer />
          <Button {...styles.resetButton} onClick={handleReset}>
            Reset
          </Button>
        </Flex>
      </GridItem>
    </Grid>
  );
};

SearchFilter.propTypes = {
  type: PropTypes.string.isRequired,
  searchParams: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};

export default SearchFilter;
