import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import React, { useRef, useState } from "react";
import { FaUpload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  addFirmwareAPI,
  getFirmwareListAPI,
} from "../redux/helpers/firmwareAPI";
import Loader from "./loader";
import { getModelsAPI } from "../redux/helpers/controllerAPI";

function AddFirmware() {
  const location = useLocation();
  const navigate = useNavigate();
  const { customer } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    formItem: {
      m: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
    bredcrumbsContainer: {
      mb: 6,
      spacing: "8px",
      cursor: isHovered ? "pointer" : "default",
      textDecoration: isHovered ? "underline" : "default",
    },
    cancelButton: {
      mr: 5,
      size: "sm",
      justifyContent: "start",
    },
    submitButton: {
      colorScheme: "facebook",
      size: "sm",
      justifyContent: "end",
    },
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const dispatch = useDispatch();
  const toast = useToast();
  const initialRef = useRef(null);

  const [fileName, setFileName] = useState("");

  const nameRegex = /^[a-zA-Z0-9-.\s]+$/;
  const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .max(45, "Firmware name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Firmware name can only contain Letters, Numbers, Full Stop, Hyphen & Spaces"
      )
      .required("Firmware name is required"),
    device_model: Yup.string().required("Firmware model is required"),
    code_name: Yup.string()
      .max(45, "Code name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Code name can only contain Letters, Hyphen, Numbers & Spaces"
      ),
  });

  const models = useSelector((state) => state?.controllers?.models);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleReset = () => {
    initialRef?.current?.reset();
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex
        direction={"row"}
        justifyContent={"space-between"}
        alignContent={"center"}
      >
        <Text
          {...styles.bredcrumbsContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Add New Firmware
        </Text>
      </Flex>
      <Card size={{ base: "sm", md: "md" }}>
        <CardBody>
          <Flex {...styles.flexContainer}>
            <Formik
              initialValues={{
                name: "",
                device_model: "",
                code_name: "",
                file: null,
                is_prod: false,
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => {
                setLoading(true);
                try {
                  const payload = {
                    name: values?.name,
                    model_id: values?.device_model,
                    code_name: values?.code_name ? values?.code_name:values?.name,
                    is_prod: values?.is_prod === true ? 1 : 0,
                  };
                  if (values?.file){
                    payload.file = values?.file
                  }
                  const addFirmwareReq = dispatch(addFirmwareAPI(payload));
                  addFirmwareReq
                    .then(async (data) => {
                      if (data?.payload?.success) {
                        await dispatch(getFirmwareListAPI());
                        await dispatch(getModelsAPI());
                        toast({
                          title: "Success",
                          position: "top-right",
                          description: "Firmware has been added successfully",
                          status: "success",
                          duration: 6000,
                          isClosable: true,
                        });
                        navigate(-1);
                        setFileName("");
                        setLoading(false);
                      } else {
                        setLoading(false);
                        toast({
                          title: "Error",
                          description:
                            data?.payload?.message ||
                            "Failed to perform the operation",
                          status: "error",
                          duration: 6000,
                          isClosable: true,
                        });
                      }
                    })
                    .catch((error) => {
                      setLoading(false);
                      console.log(error);
                    });
                } catch (err) {
                  setLoading(false);
                  console.log("errror in api", err);
                }
              }}
            >
              {({
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                values,
                resetForm,
              }) => (
                <form ref={initialRef} onSubmit={handleSubmit}>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.device_model && touched.device_model}
                  >
                    <FormLabel>Model</FormLabel>
                    <Select
                      {...styles.input}
                      ref={initialRef}
                      placeholder="Select Type"
                      id="device_model"
                      name="device_model"
                      onChange={(event) => {
                        setFieldValue("device_model", event.target.value);
                      }}
                    >
                      {models?.slice()?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((val, index) => {
                        return (
                          <option key={index} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{errors.device_model}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.name && touched.name}
                  >
                    <FormLabel> Firmware Name </FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      style={{ marginTop: 10 }}
                      ref={initialRef}
                      placeholder="Firmware Name"
                      id="name"
                      name="name"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.code_name && touched.code_name}
                  >
                    <FormLabel>Code Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      placeholder="Code name"
                      name="code_name"
                      id="code_name"
                    />
                    <FormErrorMessage>{errors.code_name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.file && touched.file}
                  >
                    <FormLabel htmlFor="file">Upload File</FormLabel>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      onChange={(event) => {
                        setFieldValue("file", event.target.files[0]);
                        setFileName(event.target.files[0].name);
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="file">
                      <Button
                        as="span"
                        leftIcon={<FaUpload />}
                        cursor="pointer"
                        variant="outline"
                        colorScheme="blue"
                      >
                        Choose File
                      </Button>
                    </label>
                    {fileName && <div>{fileName}</div>}
                    <FormErrorMessage>{errors.file}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.is_prod && touched.is_prod}
                  >
                    <Checkbox
                      name="is_prod"
                      id="is_prod"
                      colorScheme="green"
                      isChecked={values.is_prod}
                      onChange={(e) => {
                        setFieldValue("is_prod", e.target.checked);
                      }}
                    >
                      Production
                    </Checkbox>

                    <FormErrorMessage>{errors.is_prod}</FormErrorMessage>
                  </FormControl>

                  <Flex mt={4} justifyContent={"space-between"}>
                    <Button
                      colorScheme="red"
                      variant={"outline"}
                      size="sm"
                      mr={5}
                      onClick={handleBackClick}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => {
                        resetForm();
                        handleReset();
                        setFileName();
                      }}
                      {...styles.cancelButton}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      {...styles.submitButton}
                    >
                      Submit
                    </Button>
                  </Flex>
                </form>
              )}
            </Formik>
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
}

export default AddFirmware;
