import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/breadcrumbs";
import CustomerDevices from "../components/customerDevices";
import Loader from "../components/loader";
import ProductsTable from "../components/ProductsTable";
import SearchFilter from "../components/searchFilter";
import SuccessMessageModal from "../components/successMessageModal";
import {
  getActiveProduct,
  getAllProducts,
} from "../redux/helpers/controllerAPI";
import {
  addOrganizationAPI,
  getCustomerAPI,
  getOrganizationsAPI,
} from "../redux/helpers/customerAPI";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";

export default function ProductList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const toast = useToast();
  const sellerData = useSelector((state) => state?.customer?.customerData);
  const organizations = useSelector((state) => state?.customer?.organizations);
  const { allProducts } = useSelector((state) => state.controllers);
  const location = useLocation();
  const { isOpen } = location.state || false;
  const [searchParams, setSearchParams] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const user_type = useSelector((state) => state?.auth?.user_type);

  useEffect(() => {
    if(user_type === "Manufacturer"){
      if (!allProducts?.length) {
        setLoading(true);
        dispatch(getAllProducts()).then((res) => {
          setFilteredProducts(res?.payload?.data);
          setLoading(false);
        });
      } else {
        setFilteredProducts(allProducts);
      }
    } 
  }, []);

  useEffect(() => {
    if(user_type === "Manufacturer"){
      let filteredProductsData = allProducts?.filter((product) => {
        if (searchParams) {
          return product?.type?.name?.toLowerCase()?.includes(searchParams);
        } else {
          return product;
        }
      });
      setFilteredProducts(filteredProductsData);
    }
  }, [searchParams]);
  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "facebook",
    },
    topGrid: {
      templateColumns: "repeat(4, 1fr)",
      gap: { base: 1, lg: 6 },
      px: { base: 0, lg: 4 },
      pb: { base: 2, md: 6 },
      alignItems: "end",
    },

    headerText: {
      color: "#4A5568",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      letterSpacing: "0.05rem",
      fontWeight: 700,
      py: "0.25rem",
    },
    searchBar: {
      borderRadius: 6,
    },
    searchButton: {
      colorScheme: "facebook",
      width: "66%",
      size: { base: "xs", md: "sm" },
    },
    resetButton: {
      colorScheme: "facebook",
      variant: "outline",
      width: "30%",
      size: { base: "xs", md: "sm" },
    },
  };

  const [filteredCustomerData, setfilteredCustomerData] =
    useState(organizations);

  /**
   * Searching only based on Org, contact person firstname and last name
   */

  const handleInputChange = (value) => {
    setSearchParams(value.toLowerCase());
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
    });

    setfilteredCustomerData(organizations);
  };

  const handleRowClick = async (product) => {
    setLoading(true);
    dispatch(getActiveProduct(product?.model_revision_id)).then((res) => {
      setLoading(false);
      if (res?.payload?.success) navigate(`/products/${product?.type?.name}`);
      else
        toast({
          title: "Error",
          description: res?.payload?.error,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
    });
  };
  const showToast = () => {
    toast({
      title: "Success",
      position: "top-right",
      description: "Notification...",
      status: "success",
      duration: 6000,
      isClosable: true,
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const refreshCustomers = () => {
    dispatch(getOrganizationsAPI()).then((response) => {
      setfilteredCustomerData(response?.payload?.data);
    });
    // handleReset();

    setSearchParams({
      name: "",
    });
  };

  const onSaveCustomer = async (payload) => {
    setLoading(true);
    try {
      // payload.role_id = payload.privilege;
      payload.role_id = 1; // 1 will be the default admin role
      const addCustomerReq = dispatch(addOrganizationAPI(payload));
      addCustomerReq
        .then(async (result) => {
          if (result?.payload?.success === true) {
            await dispatch(getCustomerAPI());
            await dispatch(getDashboardDataAPI());
            const response = await dispatch(getOrganizationsAPI());
            setfilteredCustomerData(response?.payload?.data);
            handleCloseModal();
            setLoading(false);
            setisSuccessModalOpen(true);
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex>
        <Box>
          <Breadcrumbs />
        </Box>
        <Spacer />
        {user_type==="Manufacturer" && (
        <Button
          rightIcon={<AddIcon />}
          {...styles.button}
          onClick={() => {
            navigate("/add-product");
          }}
          // onClick={() => }
        >
          Add Product
        </Button>
        )}
      </Flex>

      <Box {...styles.topBox}>
      {user_type==="Manufacturer" ? (
      <>
        <Grid {...styles.topGrid}>
          <GridItem colSpan={{ base: 4, sm: 4, md: 2, lg: 1 }}>
            <Box>
              <Text {...styles.headerText}>What are you looking for?</Text>{" "}
              <InputGroup size={"sm"}>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  {...styles.searchBar}
                  type="text"
                  placeholder={`Search by Product Type`}
                  value={searchParams}
                  onChange={(e) => handleInputChange(e.target.value)}
                />
              </InputGroup>
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 4, sm: 4, md: 2, lg: 1 }}>
            <Flex>
              <Button
                {...styles.resetButton}
                onClick={() => setSearchParams("")}
              >
                Reset
              </Button>
            </Flex>
          </GridItem>
        </Grid>
        <ProductsTable
          products={filteredProducts}
          handleRowClick={handleRowClick}
          refreshCustomers={refreshCustomers}
        />
      </>
      ) : (
        <CustomerDevices/>
      )}
      </Box>
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={"Successfully added customer to your account"}
          onClose={() => {
            setisSuccessModalOpen(false);
            dispatch(getOrganizationsAPI());
          }}
        />
      )}
    </Box>
  );
}
