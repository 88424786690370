import { Box, Flex, Spacer } from "@chakra-ui/react";
import React, { useState } from "react";
// import { useSelector } from "react-redux";
import Breadcrumbs from "../components/breadcrumbs";
import ControllerButtons from "../components/controllerButtons";
import ControllerTypes from "../components/controllerTypes";
import { useDispatch } from "react-redux";
import { getModelsAPI, getTypesAPI } from "../redux/helpers/controllerAPI";
import { getDeviceAPI } from "../redux/helpers/deviceAPI";
// import ControllerSearchFilters from "../components/controllersSearchFilters";

function Types() {
  const dispatch = useDispatch();
  // const devices = useSelector((state) => state.controllers.devices);

  const [isOpenedForModify, setIsOpenedForModify] = useState(false);

  // useEffect(() => {
  //   dispatch(getDeviceAPI());
  // }, []);

  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      h: "76vh",
      overflow: "auto",
      borderRadius: 8,
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "facebook",
    },
  };

  // const [searchParams, setSearchParams] = useState({
  //   name: "",
  //   type: "",
  //   status: "",
  //   model: "",
  // });
  // const [filteredDevices, setFilteredDevices] = useState(devices);

  // useEffect(() => {
  //   const deviceReq = dispatch(getDeviceAPI());
  //   deviceReq.then((result) => {
  //     setFilteredDevices(result?.payload?.data);
  //   });
  // }, []);

  // const handleSearch = () => {
  //   const newFilteredDevices = devices?.filter((device) => {
  //     const nameMatch =
  //       !searchParams?.name ||
  //       device?.name?.toLowerCase().includes(searchParams?.name?.toLowerCase());
  //     const typeMatch =
  //       !searchParams?.type ||
  //       device?.device_type?.id == searchParams?.type?.toLowerCase();
  //     const statusMatch =
  //       !searchParams?.status ||
  //       device?.status?.toLowerCase() === searchParams?.status?.toLowerCase();
  //     const modelMatch =
  //       !searchParams?.model ||
  //       device?.device_model?.id == searchParams?.model?.toLowerCase();

  //     return nameMatch && typeMatch && statusMatch && modelMatch;
  //   });

  //   setFilteredDevices(newFilteredDevices);
  // };

  // const handleInputChange = (field, value) => {
  //   setSearchParams((prevSearchParams) => ({
  //     ...prevSearchParams,
  //     [field]: value,
  //   }));
  // };

  // const handleReset = () => {
  //   setSearchParams({
  //     name: "",
  //     type: "",
  //     status: "",
  //     model: "",
  //   });

  //   setFilteredDevices(devices);
  // };

  const reloadDataForAccount = () => {
    dispatch(getTypesAPI());
    dispatch(getDeviceAPI());
    dispatch(getModelsAPI());
  };

  const [singleDevice, setSingleDevice] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = (device, modify = false) => {
    setIsOpenedForModify(modify);

    setIsModalOpen(true);
    device = { ...device, update: true };
    setSingleDevice(device);
  };

  return (
    <Box>
      <Flex>
        <Box>
          <Breadcrumbs />
        </Box>
        <Spacer />
        <ControllerButtons
          selectedDevice={singleDevice}
          forModify={isOpenedForModify}
          modalIsOpen={isModalOpen}
          reloadDataForAccount={reloadDataForAccount}
        />
      </Flex>
      <Box {...styles.topBox}>
        {/* <ControllerSearchFilters
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        /> */}
        <ControllerTypes
          // devices={devices}
          // handleRowClick={handleRowClick}
          handleModalOpen={handleModalOpen}
        />
      </Box>
    </Box>
  );
}

export default Types;
