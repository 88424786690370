import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink /** useToast */,
} from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function Breadcrumbs() {
  const navigate = useNavigate();
  // const toast = useToast();
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter(Boolean);
  const activeType = useSelector((state) => state?.controllers?.activeType);
  const activeModel = useSelector((state) => state?.controllers?.activeModel);
  const activeDevice = useSelector((state) => state?.controllers?.activeDevice);

  // activeType: {},
  // activeModel: {},
  // activeDevice: {}

  const styles = {
    bredcrumbsContainer: {
      mb: 6,
      spacing: "8px",
    },
  };

  // console.log('pathSegments', pathSegments)

  const something = (path, index) => {
    // href={`/${pathSegments.slice(0, index + 1).join("/")}`}
    /**
     * pathSegments
     * 0: "device-types"
     1: "Pressure"
     2: "models"
     */
    // console.log('activeType', activeType)
    // console.log('activeModel', activeModel)
    // console.log('activeDevice', activeDevice)

    // console.log('path', path)
    // console.log('index', index)
    // console.log('pathSegments length', pathSegments.length)

    if (index === 0) {
      navigate("/" + pathSegments[0]);
    }

    // Do we need this?
    // navigate("/" + path);

    // // if current path is device-types/Pressure/model
    // if (index === 1 && pathSegments.length === 3 ) {
    //   console.log('do nothing')
    //   toast({
    //     // title: "Page Info",
    //     description: "You are already in the requested page",
    //     status: "info",
    //     duration: 3000,
    //     isClosable: true,
    //   });
    // }

    // if (index === 2 ) {
    //   navigate(`/products/${activeType?.name}/models`, {
    //     state: { activeType },
    //   });
    // }
  };

  return (
    <Breadcrumb
      {...styles.bredcrumbsContainer}
      separator={<ChevronRightIcon color="gray.500" />}
    >
      {pathSegments.map((segment, index) => (
        <BreadcrumbItem key={index}>
          <BreadcrumbLink
            onClick={() =>
              something(pathSegments.slice(0, index + 1).join("/"), index)
            }
          >
            {capitalizeFirstLetter(decodeURIComponent(segment))}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
