import { Box, Flex, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddTeamMemberModal from "../components/addTeamMemberModal";
import SearchFilter from "../components/searchFilter";
import SuccessMessageModal from "../components/successMessageModal";
import TeamMembersTable from "../components/teamMembersTable";
import { userRolesAPI } from "../redux/helpers/authAPI";
import { addCustomerTeamAPI, getCustomerTeamAPI } from "../redux/helpers/customerAPI";
import Loader from "./loader";

function CustomerTeam() {
  const dispatch = useDispatch();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const teamMemberData = useSelector((state) => state.customer.customerTeam);
  const userProfile = useSelector((state)=> state?.auth?.userProfile?.role?.name)

  useEffect(() => {
    Promise.all([dispatch(userRolesAPI()), dispatch(getCustomerTeamAPI())])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const styles = {
    topBox: {
      bg: "white",
      py: 2,
      borderRadius: 8,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [searchParams, setSearchParams] = useState({
    name: "",
  });

  const [filteredteamMemberData, setfilteredteamMemberData] =
    useState(teamMemberData);

  const handleSearch = () => {
    const newfilteredteamMemberData = teamMemberData.filter((data) => {
      const nameMatch =
        !searchParams.name ||
        data.first_name
          .toLowerCase()
          .includes(searchParams.name.toLowerCase()) ||
        data.last_name.toLowerCase().includes(searchParams.name.toLowerCase());

      return nameMatch;
    });

    setfilteredteamMemberData(newfilteredteamMemberData);
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
    });
    setfilteredteamMemberData(teamMemberData);
  };

  const refreshTeamMembers = () => {
    const membersRequest = dispatch(getCustomerTeamAPI());
    membersRequest.then((membersReq) => {
      setfilteredteamMemberData(membersReq?.payload?.data);
    });
  };

  const onSave = async (payload) => {
    console.log("onSave payload", payload);
    setLoading(true);
    try {
      const addMemberReq = dispatch(addCustomerTeamAPI(payload));
      addMemberReq
        .then((result) => {
          console.log(result);

          if (result?.payload?.success) {
            refreshTeamMembers();
            handleCloseModal();
            setSuccessMessage(result?.payload?.message);
            setisSuccessModalOpen(true);
            setLoading(false);
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Box>
      <Flex>
        <Loader loading={loading} />
        {isSuccessModalOpen && (
          <SuccessMessageModal
            isOpen={isSuccessModalOpen}
            message={successMessage}
            onClose={() => {
              setisSuccessModalOpen(false);
            }}
          />
        )}
      </Flex>
      <Box {...styles.topBox}>
        <SearchFilter
          type="name"
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        {userProfile==="Admin" && (
          <AddTeamMemberModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            setIsModalOpen={setIsModalOpen}
            onSave={onSave}
            type={"Add Team Member"}
            //onSuccess={}
          />
        )}
      </Box>
      <Box>
        <TeamMembersTable
          enableActions={false}
          teamMembers={filteredteamMemberData}
        />
      </Box>
    </Box>
  );
}

export default CustomerTeam;
