import { DeleteIcon, SettingsIcon, AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  useToast,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// import { GoDotFill } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  deleteModelsAPI,
  getModelsAPI,
  getTypesAPI,
  updateModelsAPI,
} from "../redux/helpers/controllerAPI";
import {
  addFirmwareAPI,
  editFirmwareAPI,
  getFirmwareListAPI,
} from "../redux/helpers/firmwareAPI";
import {
  // setActiveDevice,
  setActiveDevicesForModel,
} from "../redux/reducers/controllersReducer";
import DeleteDeviceModal from "./deleteDeviceModal";
import EditDeviceModelModal from "./editDeviceModelModal";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";

const ControllerModels = () => {
  let url_params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const models = useSelector((state) =>
    state?.controllers?.models?.length
      ? [...state?.controllers?.models].sort((a, b) => a.id - b.id)
      : state?.controllers?.models
  );
  const devices = useSelector((state) => state?.controllers?.devices);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [singleDeviceModel, setSingleDeviceModel] = useState("");
  // const [successMessage, setSuccessMessage] = useState("");
  // const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [isEditModelOpen, setIsEditModelOpen] = useState(false);
  const deletionType = "model";
  const [loading, setLoading] = useState(false);
  const activeType = useSelector((state) => state?.controllers?.activeType);
  const toast = useToast();
  const firmwareDataList = useSelector((state) => state.firmware.firmwareData);

  const [devicesData, setDevicesData] = useState();

  const modelsData = models?.filter(
    (val) => val?.device_type?.id == activeType?.id
  );

  console.log(modelsData, "modelsData");

  useEffect(() => {
    let deviceCount = [];
    let device = [];
    for (var k = 0; k < modelsData?.length; k++) {
      deviceCount.push({
        id: modelsData[k]?.device_type.id,
        count: 0,
      });
    }
    for (var l = 0; l < modelsData?.length; l++) {
      device.push({
        id: modelsData[l]?.device_type.id,
        deviceData: [],
      });
    }

    for (var i = 0; i < modelsData?.length; i++) {
      for (var j = 0; j < devices?.length; j++) {
        if (modelsData[i]?.device_type.id === devices[j]?.device_type.id) {
          var index = deviceCount.findIndex(
            (obj) => obj.id === modelsData[i]?.device_type?.id
          );
          deviceCount[index].count++;
          device[index].deviceData.push({ ...devices[j] });
        }
      }
    }
    // setCount(deviceCount);
    setDevicesData(device);

    if (!firmwareDataList?.length) {
      setLoading(true);
      let getFirmwareRes = dispatch(getFirmwareListAPI());
      getFirmwareRes.then((result) => {
        setLoading(false);
      });
    }
  }, []);

  const handleTypeClick = (device_data) => {
    // navigate(
    //   `/products/${url_params?.deviceTypeName?.replace(/ /g, "-")}/models/${val?.name?.replace(/ /g, "-")}/devices`,
    //   {
    //     state: { device_data },
    //   },
    // );

    Promise.all([
      // dispatch(setActiveModel(device_data)),
      dispatch(setActiveDevicesForModel(device_data)),
      // dispatch(setActiveDevice(val)),
    ])
      .then(() => {
        navigate(`/products/models/devices`, {
          state: { device_data },
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleEditModelOpen = (device) => {
    setIsEditModelOpen(true);
    setSingleDeviceModel(device);
  };

  const handleEditModelCloseModal = () => {
    setIsEditModelOpen(false);
  };

  const handleDeleteModalOpen = (device) => {
    setIsDeleteModalOpen(true);
    setSingleDeviceModel(device);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSaveEditModel = async (payload, firmwareValues) => {
    try {
      setLoading(true);

      // Update Model
      const updateModelRes = await dispatch(updateModelsAPI(payload));
      const { success: modelSuccess, message: modelMessage } =
        updateModelRes?.payload;

      if (firmwareValues) {
        // Add or Edit Firmware based on presence of firmwareValues.id
        const firmwareAction = firmwareValues?.firmware_id
          ? editFirmwareAPI
          : addFirmwareAPI;
        const firmwareRes = await dispatch(firmwareAction(firmwareValues));
        const { success: firmwareSuccess, message: firmwareMessage } =
          firmwareRes?.payload;

        if (firmwareSuccess) {
          await dispatch(getFirmwareListAPI());
          showToast(
            "Success",
            "Firmware has been added successfully",
            "success"
          );
          handleEditModelCloseModal()
        } else {
          showToast(
            "Error",
            firmwareMessage || "Failed to perform the operation",
            "error"
          );
        }
      }

      if (modelSuccess) {
        await dispatch(getModelsAPI());
        handleEditModelCloseModal();
        showToast("Success", modelMessage, "success");
      } else {
        showToast("Error", modelMessage || "Something went wrong!", "error");
      }
    } catch (error) {
      console.error("Error during save operation:", error);
      showToast("Error", "An unexpected error occurred", "error");
    } finally {
      setLoading(false);
    }
  };

  // Centralized Toast function for better reuse
  const showToast = (title, description, status) => {
    toast({
      title,
      position: "top-right",
      description,
      status,
      duration: 6000,
      isClosable: true,
    });
  };

  // const onSaveEditModel = (payload, firmwareValues) => {
  //   setLoading(true);
  //   let updateModelres = dispatch(updateModelsAPI(payload));
  //   updateModelres
  //     .then((data) => {
  //       try {
  //         if ( firmwareValues?.id ){
  //           const addFirmwareReq = dispatch(
  //             editFirmwareAPI(firmwareValues)
  //           )
  //         } else {
  //           const addFirmwareReq = dispatch(
  //             addFirmwareAPI(firmwareValues)
  //           )
  //         }
  //         addFirmwareReq
  //           .then(async (firmwareData) => {
  //             if (firmwareData?.payload?.success) {
  //               await dispatch(getFirmwareListAPI());
  //               toast({
  //                 title: "Success",
  //                 position: "top-right",
  //                 description:
  //                   "Firmware has been added successfully",
  //                 status: "success",
  //                 duration: 6000,
  //                 isClosable: true,
  //               });
  //               handleBackClick()
  //               setFileName("");
  //               setLoading(false);
  //             } else {
  //               setLoading(false);
  //               toast({
  //                 title: "Error",
  //                 description:
  //                 firmwareData?.payload?.message ||
  //                   "Failed to perform the operation",
  //                 status: "error",
  //                 duration: 6000,
  //                 isClosable: true,
  //               });
  //             }
  //           })
  //           .catch((error) => {
  //             setLoading(false);
  //             console.log(error);
  //           });
  //       } catch (err) {
  //         setLoading(false);
  //         console.log("errror in api", err);
  //       }
  //       const { success, message } = data?.payload;
  //       if (success) {
  //         dispatch(getModelsAPI()).then(() => {
  //           handleEditModelCloseModal();
  //           toast({
  //             title: "Success",
  //             position: "top-right",
  //             description: message,
  //             status: "success",
  //             duration: 6000,
  //             isClosable: true,
  //           });
  //           setLoading(false);
  //         });
  //       } else {
  //         dispatch(getModelsAPI());
  //         setLoading(false);
  //         toast({
  //           title: "Error",
  //           description:
  //             message || data?.payload?.error || "Something went wrong!",
  //           status: "error",
  //           duration: 6000,
  //           isClosable: true,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.error(err);
  //     });
  // };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);

    let deleteTypesRes = dispatch(deleteModelsAPI(payload));
    deleteTypesRes
      .then((result) => {
        const { success, message } = result?.payload;
        if (success) {
          dispatch(getModelsAPI()).then(() => {
            handleCloseDeleteModal();
            toast({
              title: "Success",
              position: "top-right",
              description: message,
              status: "success",
              duration: 6000,
              isClosable: true,
            });
            setLoading(false);
          });
        } else {
          setLoading(false);
          dispatch(getModelsAPI());
          toast({
            title: "Error",
            description: message || "Error in deletin device model.",
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const showStartupMessage = () => {
    return (
      <Flex flexDirection={"column"}>
        <Text m={2}>
          Setup your account by adding models to existing products
        </Text>
        <Box style={{ marginInline: 8 }}>
          <Button
            {...styles.button}
            rightIcon={<AddIcon />}
            onClick={() => navigate("/add-model")}
          >
            Add Model
          </Button>
        </Box>
      </Flex>
    );
  };

  const styles = {
    cardTop: { display: "flex" },
    card: {
      w: "32%",
      // cursor: "pointer",
    },
    cardHeading: {
      fontSize: 20,
      fontWeight: 600,
      py: "2",
    },
    align: {
      alignItems: "center",
      gap: 2,
      p: 2,
    },
    item: {
      p: 2,
      fontSize: 14,
      fontWeight: 400,
    },
    itemValue: {
      p: 2,
      fontSize: 14,
      fontWeight: 600,
    },
  };

  return (
    <>
      <Box>
        <Loader loading={loading} />
        {/* <Flex gap={2} pt={2} ml={3}>
          <Flex>
            <Center>
              <GoDotFill color={"green"} />
              Online
            </Center>
          </Flex>
          <Flex>
            <Center>
              <GoDotFill color={"red"} />
              Offline
            </Center>
          </Flex>
        </Flex> */}
        <Flex
          {...styles.cardTop}
          flexWrap="wrap"
          gap={4}
          align-items="flex-start"
        >
          {modelsData?.map((val, index) => {
            return (
              <Card
                {...styles.card}
                key={index}
                // onClick={() =>
                //   handleTypeClick(devicesData[index]?.deviceData, val)
                // }
              >
                <CardHeader py={0} bgColor="#EBEBEB" borderTopRadius="md">
                  <Flex
                    alignItems="center"
                    style={{
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <Tooltip
                      placement="auto"
                      hasArrow
                      label={val?.name}
                      fontSize="md"
                    >
                      <Heading
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                        {...styles.cardHeading}
                      >
                        {val?.name}
                      </Heading>
                      {/* <Flex {...styles.align}>
                    <FaCheckCircle color="green" />
                    <Text>Active</Text>
                  </Flex> */}
                    </Tooltip>
                  </Flex>
                </CardHeader>
                <CardBody px={4} py={2}>
                  <Flex justifyContent="space-between">
                    <Heading {...styles.item}>Devices</Heading>
                    <Heading {...styles.itemValue}>
                      {val?.number_of_devices}
                    </Heading>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Heading {...styles.item}>Data Points</Heading>
                    <Heading {...styles.itemValue}>
                      {val?.model_data_points?.length}
                    </Heading>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Heading {...styles.item}>Firmware</Heading>
                    <Heading {...styles.itemValue}>
                      {firmwareDataList?.filter(
                        (firmwareVal) =>
                          firmwareVal?.device_model?.id == val?.id
                      )[0]?.name
                        ? firmwareDataList?.filter(
                            (firmwareVal) =>
                              firmwareVal?.device_model?.id == val?.id
                          )[0]?.name
                        : "N/A"}
                    </Heading>
                  </Flex>
                  <Flex justifyContent="space-between" paddingY={2}>
                    <Button
                      size={"xs"}
                      colorScheme={"red"}
                      variant={"outline"}
                      isDisabled={
                        val?.number_of_devices > 0
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteModalOpen(val);
                      }}
                    >
                      Delete
                      <DeleteIcon
                        w={4}
                        h={4}
                        colorScheme="red"
                        marginLeft={2}
                        boxSize={3}
                      />
                    </Button>
                    <Button
                      size={"xs"}
                      colorScheme="teal"
                      color="white"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditModelOpen(val);
                        console.log(val, "handleEditModelOpen");
                      }}
                    >
                      Modify
                      <SettingsIcon
                        w={4}
                        h={4}
                        color="white"
                        marginLeft={2}
                        boxSize={3}
                      />
                    </Button>
                  </Flex>
                </CardBody>
              </Card>
            );
          })}

          {models?.length === 0 ? showStartupMessage() : <> </>}
        </Flex>
        {isDeleteModalOpen && (
          <DeleteDeviceModal
            isOpen={isDeleteModalOpen}
            onclose={handleCloseDeleteModal}
            device={singleDeviceModel}
            onSave={onSaveDeleteModel}
            type={deletionType}
          />
        )}

        {isEditModelOpen && (
          <EditDeviceModelModal
            isOpen={isEditModelOpen}
            onClose={handleEditModelCloseModal}
            setIsModalOpen={setIsEditModelOpen}
            onSave={onSaveEditModel}
            device={singleDeviceModel}
          />
        )}

        {/* {isSuccessModalOpen && (
          <SuccessMessageModal
            isOpen={isSuccessModalOpen}
            message={`${successMessage}!`}
            onClose={() => {
              setisSuccessModalOpen(false);
            }}
          />
        )} */}
      </Box>
    </>
  );
};

ControllerModels.propTypes = {
  devices: PropTypes.array,
  handleRowClick: PropTypes.func,
  handleModalOpen: PropTypes.func,
};

export default ControllerModels;
