import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
  VStack,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { MdCheck } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import PhoneInput, {
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";
import * as Yup from "yup";
import Elex from "../assets/images/Elex-partners.png";
import Loader from "../components/loader";
// import {CloseOutlined}  from 'antd/close-outlined';

import {
  changePasswordSignUpInvitationIdAPI,
  signUpAPI,
  verifySignUpInvitationIdAPI,
} from "../redux/helpers/authAPI";
import { clearMessage } from "../redux/reducers/authReducer";

var passwordValidated = {
  hasSmallLetter: false,
  hasCapitalLetter: false,
  hasNumber: false,
  hasSpecialCharacter: false,
  hasMinPassword: false,
};

export default function Signup() {
  const [phonenumber, setPhoneNumber] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();
  const [invitation_id, setInvitationId] = useState("");
  const [userVerified, setUserVerified] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const error = useSelector((state) => state?.auth?.error);

  const [showPassword, setShowPassword] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  const changeSelectHandler = (value, setFieldValue) => {
    if (value) {
      const countryData = parsePhoneNumber(value);
      // setPhoneNumber(countryData);
      setFieldValue("country", countryData?.country);
    }
  };

  const styles = {
    background: {
      justifyContent: "center",
      alignItems: "center",
      // h: "100vh",
      w: "100vw",
      // bgImage: BackgroundImage,
      // bgSize: "cover",
      // bgRepeat: "no-repeat",
      bgGradient:
        "linear-gradient(135deg, rgb(121, 241, 164) 10%, rgb(14, 92, 173) 100%)",
      flexDirection: "column",
      paddingBottom: 10,
    },
    topContainer: {
      flexDirection: "column",
      p: { base: 2, md: 10 },
      marginTop: "5vh",
      borderRadius: 10,
      backgroundColor: "whitesmoke",
      justifyContent: "center",
      alignItems: "center",
    },
    stackItems: {
      flexDirection: "column",
      mb: "2",
      width: { base: "70vw", md: "100%" },
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      size: "lg",
      fontWeight: "semibold",
    },
    description: {
      fontSize: 14,
      color: "gray",
    },
    formContainer: {
      minW: { base: "100%", md: "468px" },
    },
    formStack: {
      spacing: 4,
      align: "flex-start",
    },
    input: {
      borderRadius: 6,
      size: { base: "sm", md: "md" },
    },
    loginButton: {
      colorScheme: "twitter",
      size: { base: "sm", md: "md" },
      width: "full",
    },
    linkColor: {
      color: "#1da1f2",
    },
    footerText: {
      flexDirection: "column",
      fontSize: { base: 14, md: 16 },
      textAlign: "center",
    },
    phoneInput: {
      backgroundColor: "#f0f0f0",
      borderWidth: "1px",
      borderRadius: 6,
      height: "40px",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(255, "Email must be at most 255 characters.")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long")
      .max(36, "Password must be at most 36 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*\W)/,
        "Password must contain at least one special character"
      ),
    confirm_password: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Passwords and confirm password must match"
      )
      .required("Confirm Password is required"),
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    phonenumber: Yup.string().required("Phone Number is required"),
    companyname: Yup.string().when("hasAuthorizationCode", {
      is: false,
      then: () => Yup.string().required("Company Name is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    country: Yup.string().required("Country is required"),
    authorization_code: Yup.string().when("hasAuthorizationCode", {
      is: true,
      then: () => Yup.string().required("Authorization code is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === "c" || event.key === "v")
      ) {
        event.preventDefault();
        console.log(`Prevented ${event.key === "c" ? "Copy" : "Paste"}`);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("invitation_id");
    const is_auth = searchParams.get("is_auth");
  }, [location.search]);

  useEffect(() => {
    if (signupSuccess) navigate("/login");
  }, [signupSuccess]);

  return (
    <Flex {...styles.background}>
      <Loader loading={loading} />
      <Flex
        mt={10}
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Image height={"10vh"} src={Elex} alt="Winter Gardenz"></Image>
        </Box>
        <div>
          <Heading
            {...styles.header}
            style={{
              color: "white",
              fontSize: "2.0rem",
              fontStretch: "inherit",
            }}
          >
            Electronics
          </Heading>
          <Heading
            {...styles.header}
            style={{
              fontSize: "1.4rem",
              fontWeight: "semibold",
              color: "white",
            }}
          >
            Partners
          </Heading>
        </div>
      </Flex>
      <Flex {...styles.topContainer}>
        <Stack {...styles.stackItems}>
          <Heading {...styles.header}>Sign Up</Heading>
          {/* <p {...styles.description}>Enter details to create an account</p> */}
          <Box {...styles.formContainer}>
            <Formik
              initialValues={{
                firstname: "",
                lastname: "",
                phonenumber: "",
                companyname: "",
                password: "",
                confirm_password: "",
                email: "",
                country: "NZ",
                rememberMe: false,
                hasAuthorizationCode: true,
                authorization_code: "",
              }}
              validationSchema={loginValidationSchema}
              onSubmit={(values) => {
                setLoading(true);
                const payloadData = {
                  email: values?.email,
                  password: values?.password,
                  first_name: values?.firstname,
                  last_name: values?.lastname,
                  phone_number: values?.phonenumber,
                  organization_name: values?.companyname,
                  full_address: values?.country,
                  country: values?.country,
                  authorization_code:values?.authorization_code,
                  rememberMe: false,
                };

                const payload = Object.fromEntries(
                  Object.entries(payloadData)?.filter(
                    ([, value]) => value !== ""
                  )
                );

                if (userVerified){
                  payload.invitation_id = invitation_id
                  console.log(payload,'pay')
                  dispatch(changePasswordSignUpInvitationIdAPI(payload))
                    .then((result) => {
                      if (result?.payload?.success === false) {
                        setLoading(false);
                        toast({
                          title: "Error",
                          description: result?.payload?.message,
                          status: "error",
                          duration: 4000,
                          isClosable: true,
                        });
                      } else {
                        dispatch(clearMessage());
                        setLoading(false);
  
                        toast({
                          title: "Success",
                          position: "top-right",
                          description:
                            "Please login with your new account credentials",
                          status: "success",
                          duration: 4000,
                          isClosable: true,
                        });
                        setSignupSuccess(true);
                      }
                    })
                    .catch((error) => {
                      setLoading(false);
                      console.warn(error);
                    });
                } else {  
                  dispatch(signUpAPI(payload))
                    .then((result) => {
                      if (result?.payload?.success === false) {
                        // something went wrong
                        setLoading(false);
                        toast({
                          title: "Error",
                          description: result?.payload?.message,
                          status: "error",
                          duration: 4000,
                          isClosable: true,
                        });
                      } else {
                        dispatch(clearMessage());
                        // dispatch(changeAuthState(true))
                        setLoading(false);
  
                        toast({
                          title: "Success",
                          position: "top-right",
                          description:
                            "Please login with your new account credentials",
                          status: "success",
                          duration: 4000,
                          isClosable: true,
                        });
                        setSignupSuccess(true);
                      }
                    })
                    .catch((error) => {
                      setLoading(false);
                      console.warn(error);
                    });
                }
              }}
            >
              {({ values, handleSubmit, errors, touched, setFieldValue }) => {
                let regexToCheckSmall = /^(?=.*[a-z])/;
                let regexToCheckCapital = /^(?=.*[A-Z])/;
                let regexToCheckNumber = /^(?=.*\d)/;
                let regexToCheckSpecialCharacter =
                  /^(?=.*[!@#$%^&*()\-_"=+{}; :,<.>])/;

                passwordValidated = {
                  hasSmallLetter: regexToCheckSmall.test(values.password),
                  hasCapitalLetter: regexToCheckCapital.test(values.password),
                  hasNumber: regexToCheckNumber.test(values.password),
                  hasSpecialCharacter: regexToCheckSpecialCharacter.test(
                    values.password
                  ),
                  hasMinPassword: values?.password?.length >= 6,
                };
                
                useEffect(() => {
                  const searchParams = new URLSearchParams(location.search);
                  const id = searchParams.get("invitation_id");
                  const is_auth = searchParams.get("is_auth");
                  if (id) {
                    setInvitationId(id);
                    const payload = {
                      invitation_id: id,
                    };
                    let verifyInvitationIdRes = dispatch(
                      verifySignUpInvitationIdAPI(payload)
                    );
              
                    verifyInvitationIdRes.then((data) => {
                      if (data.payload?.user_exists) {
                        console.log(data?.payload?.data, "PAYLOAD");
                        setUserVerified(data.payload?.user_exists);
                        setUserDetails(data?.payload?.data);
              
                        setFieldValue("email", data?.payload?.data?.email);
                        setFieldValue("firstname", data?.payload?.data?.first_name);
                        setFieldValue("lastname", data?.payload?.data?.last_name);
                        setFieldValue("phonenumber", data?.payload?.data?.phone_number);
                      } else {
                        toast({
                          title: "Error",
                          description: data.payload.message,
                          status: "error",
                          duration: 6000,
                          isClosable: true,
                        });
                      }
                    });
                  }
                }, [location.search]);

                return (
                  <form onSubmit={handleSubmit}>
                    <VStack {...styles.formStack}>
                      <FormControl
                        isInvalid={errors?.firstname && touched?.firstname}
                      >
                        <FormLabel htmlFor="firstname">First Name</FormLabel>
                        <Field
                          as={Input}
                          {...styles.input}
                          id="firstname"
                          name="firstname"
                          type="text"
                          placeholder="John"
                          disabled={userVerified}
                          value={
                            userVerified
                              ? userDetails?.first_name
                              : values?.firstname
                          }
                        />
                        <FormErrorMessage>{errors?.firstname}</FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={errors?.lastname && touched?.lastname}
                      >
                        <FormLabel htmlFor="firstname">Last Name</FormLabel>
                        <Field
                          as={Input}
                          {...styles.input}
                          id="lastname"
                          name="lastname"
                          type="text"
                          placeholder="Elixir"
                          disabled={userVerified}
                          value={
                            userVerified
                              ? userDetails?.last_name
                              : values?.lastname
                          }
                        />
                        <FormErrorMessage>{errors?.lastname}</FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={errors?.email && touched?.email}>
                        <FormLabel htmlFor="email">Work Email</FormLabel>
                        <Field
                          as={Input}
                          {...styles.input}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Enter your work email"
                          disabled={userVerified}
                          value={
                            userVerified ? userDetails?.email : values?.email
                          }
                        />
                        <FormErrorMessage>{errors?.email}</FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={errors?.phonenumber && touched?.phonenumber}
                      >
                        <FormLabel htmlFor="email">Phone Number</FormLabel>
                        <PhoneInput
                          style={styles.phoneInput}
                          placeholder="275 555 76"
                          defaultCountry="NZ"
                          value={
                            userVerified
                              ? formatPhoneNumberIntl(
                                  userVerified?.phone_number
                                )
                              : formatPhoneNumberIntl(values?.phonenumber)
                          }
                          onChange={(phone) => {
                            setFieldValue("phonenumber", phone);
                            changeSelectHandler(phone, setFieldValue);
                          }}
                          disabled={userVerified}
                        />
                        <FormErrorMessage>
                          {errors?.phonenumber}
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={
                          errors.hasAuthorizationCode &&
                          touched.hasAuthorizationCode
                        }
                      >
                        <Checkbox
                          id="hasAuthorizationCode"
                          name="hasAuthorizationCode"
                          colorScheme="green"
                          isChecked={
                            userVerified
                              ? userVerified
                              : values?.hasAuthorizationCode
                          }
                          disabled={userVerified}
                          onChange={(e) => {
                            setFieldValue(
                              "hasAuthorizationCode",
                              e.target.checked
                            );
                            setFieldValue("authorization_code", "");
                            setFieldValue("companyname", "");
                            setFieldValue("country", "");
                          }}
                        >
                          Do you have Authorization Code
                        </Checkbox>
                        <FormErrorMessage>
                          {errors.hasAuthorizationCode}
                        </FormErrorMessage>
                      </FormControl>

                        {values?.hasAuthorizationCode ? (
                          <FormControl
                            {...styles.formItem}
                            isInvalid={
                              errors.authorization_code &&
                              touched.authorization_code
                            }
                          >
                            <FormLabel>Authorization Code </FormLabel>
                            <Field
                              as={Input}
                              {...styles.input}
                              placeholder="Authorization Code"
                              id="authorization_code"
                              name="authorization_code"
                            />
                            <FormErrorMessage>
                              {errors.authorization_code}
                            </FormErrorMessage>
                          </FormControl>
                        ) : (
                          <>
                            <FormControl
                              isInvalid={
                                errors.companyname && touched.companyname
                              }
                            >
                              <FormLabel htmlFor="firstname">
                                Company Name
                              </FormLabel>
                              <Field
                                as={Input}
                                {...styles.input}
                                id="companyname"
                                name="companyname"
                                type="text"
                                placeholder="Elex"
                              />
                              <FormErrorMessage>
                                {errors.companyname}
                              </FormErrorMessage>
                            </FormControl>
                            <FormControl
                              isInvalid={errors?.country && touched?.country}
                            >
                              <FormLabel> Country </FormLabel>
                              <Select
                                placeholder="Select your country"
                                name="country"
                                value={values?.country}
                                onChange={(e) => {
                                  setFieldValue("country", e.target.value);
                                }}
                                disabled={userVerified}
                              >
                                {options.map((country) => {
                                  return (
                                    <option
                                      key={country.value}
                                      value={country.value}
                                    >
                                      {country.label}
                                    </option>
                                  );
                                })}
                              </Select>
                              <FormErrorMessage>
                                {errors.country}
                              </FormErrorMessage>
                            </FormControl>
                          </>
                        )}

                      <FormControl
                        isInvalid={errors?.password && touched?.password}
                      >
                        <FormLabel htmlFor="password">Password</FormLabel>
                        <InputGroup>
                          <Field
                            as={Input}
                            id="password"
                            name="password"
                            {...styles.input}
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                          />

                          <InputRightElement onClick={handleShowClick}>
                            <ViewIcon />
                          </InputRightElement>
                        </InputGroup>

                        <FormErrorMessage>
                          {errors.password == "Password is required" &&
                            errors?.password}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={
                          errors?.confirm_password && touched?.confirm_password
                        }
                      >
                        <FormLabel htmlFor="password">
                          Confirm Password
                        </FormLabel>
                        <InputGroup>
                          <Field
                            as={Input}
                            id="confirm_password"
                            name="confirm_password"
                            {...styles.input}
                            type={"password"}
                            placeholder="Confirm password"
                          />

                          {/* <InputRightElement onClick={handleShowClick}>
                            <ViewIcon />
                          </InputRightElement> */}
                        </InputGroup>
                        <FormErrorMessage>
                          {errors?.confirm_password}
                        </FormErrorMessage>
                        <div
                          style={{
                            marginTop: 5,
                            fontSize: 14,
                          }}
                        >
                          {errors.password == "Password is required" ? null : (
                            <p style={{ padding: 5 }}>
                              <p
                                style={{
                                  display: "flex",
                                  color: !passwordValidated?.hasCapitalLetter
                                    ? "#E53E3E"
                                    : "green",
                                }}
                              >
                                {!passwordValidated?.hasCapitalLetter ? (
                                  <RxCross2
                                    style={{ marginRight: 10, marginTop: 4 }}
                                    color={"#E53E3E"}
                                    size={16}
                                  />
                                ) : (
                                  <MdCheck
                                    style={{ marginRight: 10, marginTop: 4 }}
                                    color={"green"}
                                    size={16}
                                  />
                                )}
                                Need at least One CAPITAL letter
                              </p>
                              <p
                                style={{
                                  display: "flex",
                                  color: !passwordValidated?.hasSmallLetter
                                    ? "#E53E3E"
                                    : "green",
                                }}
                              >
                                {!passwordValidated?.hasSmallLetter ? (
                                  <RxCross2
                                    style={{ marginRight: 10, marginTop: 4 }}
                                    color={"#E53E3E"}
                                    size={16}
                                  />
                                ) : (
                                  <MdCheck
                                    style={{ marginRight: 10, marginTop: 4 }}
                                    color={"green"}
                                    size={16}
                                  />
                                )}{" "}
                                Need at least One small letter
                              </p>
                              <p
                                style={{
                                  display: "flex",
                                  color: !passwordValidated?.hasNumber
                                    ? "#E53E3E"
                                    : "green",
                                }}
                              >
                                {!passwordValidated?.hasNumber ? (
                                  <RxCross2
                                    style={{ marginRight: 10, marginTop: 4 }}
                                    color={"#E53E3E"}
                                    size={16}
                                  />
                                ) : (
                                  <MdCheck
                                    style={{ marginRight: 10, marginTop: 4 }}
                                    color={"green"}
                                    size={16}
                                  />
                                )}{" "}
                                Need at least One number ( 0-9 )
                              </p>
                              <p
                                style={{
                                  display: "flex",
                                  color: !passwordValidated?.hasSpecialCharacter
                                    ? "#E53E3E"
                                    : "green",
                                }}
                              >
                                {!passwordValidated?.hasSpecialCharacter ? (
                                  <RxCross2
                                    style={{ marginRight: 10, marginTop: 4 }}
                                    color={"#E53E3E"}
                                    size={16}
                                  />
                                ) : (
                                  <MdCheck
                                    style={{ marginRight: 10, marginTop: 4 }}
                                    color={"green"}
                                    size={16}
                                  />
                                )}{" "}
                                {`Need at least One Symbol (!@#$%^&*\-_"=+{}; :,<.>)`}
                              </p>
                              <p
                                style={{
                                  display: "flex",
                                  color: !passwordValidated?.hasMinPassword
                                    ? "#E53E3E"
                                    : "green",
                                }}
                              >
                                {!passwordValidated?.hasMinPassword ? (
                                  <RxCross2
                                    style={{ marginRight: 10, marginTop: 4 }}
                                    color={"#E53E3E"}
                                    size={16}
                                  />
                                ) : (
                                  <MdCheck
                                    style={{ marginRight: 10, marginTop: 4 }}
                                    color={"green"}
                                    size={16}
                                  />
                                )}{" "}
                                Min 6 character long
                              </p>
                            </p>
                          )}
                        </div>
                      </FormControl>
                      {/* <FormControl
                      isInvalid={
                        errors?.confirmPassword && touched?.confirmPassword
                      }>
                      <FormLabel htmlFor="confirmPassword">
                        Confirm Password
                      </FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          id="confirmPassword"
                          name="confirmPassword"
                          {...styles.input}
                          type={showPassword ? "text" : "password"}
                          placeholder="Confirm your password"
                        />

                        <InputRightElement onClick={handleShowClick}>
                          <pIcon />
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage>
                        {errors?.confirmPassword}
                      </FormErrorMessage>
                    </FormControl> */}

                      <Button {...styles.loginButton} type="submit">
                        Sign Up
                      </Button>
                    </VStack>
                  </form>
                );
              }}
            </Formik>
          </Box>
        </Stack>
        <Box {...styles.footerText} mt={4}>
          <Link onClick={() => navigate("/login")}>
            <Text {...styles.linkColor}>Already have an account? Login</Text>
          </Link>
        </Box>
        <Box {...styles.footerText} mt={2}>
          <Link onClick={() => navigate("/forgot-password")}>
            <Text {...styles.linkColor}>Forgot Password?</Text>
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
}
