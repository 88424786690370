import React from "react";
import {
  Box,
  Flex,
  Spacer,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import ReactECharts from "echarts-for-react";
import { useLocation } from "react-router-dom";

function DeviceGraphTabs() {
  const location = useLocation();

  const { deviceData } = location.state || {};

  const styles = {
    graphBox: {
      p: 4,
      boxShadow: "md",
      bgColor: "white",
      borderRadius: 6,
    },
    tabHeading: {
      fontWeight: "bold",
    },
    tabindecater: {
      mt: "-1.5px",
      height: "2px",
      bg: "blue.500",
      borderRadius: "1px",
    },
    chartStyles: { width: "80vw", height: "52vh" },

    pressureReadingBox: {
      mt: "-2",
      borderRadius: 6,
      px: "4",
      py: "2",
      border: "1px",
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
    },
    pressureReadingTextContainer: {
      alignItems: "center",
    },
    pressureReadingText: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    pressureReadingTopic: {
      pt: "2",
      fontSize: "sm",
      color: "#929FB1",
    },
  };
  const readingsOptions = {
    tooltip: {
      show: false, // Disable tooltip
    },
    legend: {
      data: ["Min", "Reading", "Max"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: [
        "02 PM",
        "04 PM",
        "06 PM",
        "08 PM",
        "10 PM",
        "12 PM",
        "02 AM",
        "04 AM",
        "06 AM",
        "08 AM",
        "10 AM",
        "12 AM",
      ],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Min",
        type: "line",
        itemStyle: {
          color: "red",
        },
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        emphasis: {
          disabled: true, // Disable emphasis (hover effect)
        },
      },
      {
        name: "Reading",
        type: "line",
        itemStyle: {
          color: "blue",
        },
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        emphasis: {
          disabled: true, // Disable emphasis (hover effect)
        },
      },
      {
        name: "Max",
        type: "line",
        itemStyle: {
          color: "red",
        },
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        emphasis: {
          disabled: true, // Disable emphasis (hover effect)
        },
      },
    ],
  };

  const alaramsOptions = {
    tooltip: {
      show: false, // Disable tooltip
    },
    legend: {
      data: ["Alarms"],
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: [
          "12:00 AM",
          "01:00 AM",
          "02:00 AM",
          "03:00 AM",
          "04:00 AM",
          "05:00 AM",
          "06:00 AM",
          "07:00 AM",
          "08:00 AM",
          "09:00 AM",
          "10:00 AM",
          "11:00 AM",
          "12:00 PM",
          "01:00 PM",
          "02:00 PM",
          "03:00 PM",
          "04:00 PM",
          "05:00 PM",
          "06:00 PM",
          "07:00 PM",
          "08:00 PM",
          "09:00 PM",
          "10:00 PM",
          "11:00 PM",
        ],
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: "Alarms",
        type: "line",
        data: [
          // 22, 20, 25, 25, 23, 25, 27, 29, 30, 30, 34, 30, 28, 25, 26, 27, 26,
          // 29, 30, 30, 29, 27, 25, 28,
        ],
        emphasis: {
          disabled: true, // Disable emphasis (hover effect)
        },
      },
    ],
  };
  return (
    <Box {...styles.graphBox}>
      <Tabs position="relative" variant="unstyled">
        <Flex>
          <TabList>
            <Tab {...styles.tabHeading}>Readings</Tab>
            <Tab {...styles.tabHeading}>Alarms</Tab>
          </TabList>
          <Spacer />
          {/* <Box {...styles.pressureReadingBox}>
            <Flex {...styles.pressureReadingTextContainer}>
              {deviceData?.data_points ? (
                <Text {...styles.pressureReadingText}>
                  {deviceData?.data_points[0]?.minimum} -{" "}
                  {deviceData?.data_points[0]?.maximum} Pa
                </Text>
              ) : (
                <Text> {null}</Text>
              )}

            </Flex>
            <Text {...styles.pressureReadingTopic}>Pressure Threshold</Text>
          </Box> */}
        </Flex>

        <TabIndicator {...styles.tabindecater} />
        <TabPanels>
          <TabPanel p={0}>
            <ReactECharts option={readingsOptions} style={styles.chartStyles} />
          </TabPanel>
          <TabPanel p={0}>
            <ReactECharts option={alaramsOptions} style={styles.chartStyles} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default DeviceGraphTabs;
