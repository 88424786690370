import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const signUpAPI = createAsyncThunk("signUpAPI", async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let response = await API.post(
      `/manufacturer/sign-up`,
      // {
      //   email: payload?.email,
      //   password: payload?.password,
      // },
      payload,
      config
    );

    return response?.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

export const loginAPI = createAsyncThunk("loginAPI", async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let response = await API.post(
      `/manufacturer/signin`,
      {
        email: payload?.email,
        password: payload?.password,
      },
      config
    );

    return response?.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

export const sellerLoginAPI = createAsyncThunk("sellerLoginAPI", async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let response = await API.post(
      `/seller/signin`,
      {
        email: payload?.email,
        password: payload?.password,
      },
      config,
    );

    return response?.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
});

export const sendPassResetLinkAPI = createAsyncThunk(
  "sendPassResetLinkAPI",
  async (payload) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let response = await API.post(
        `/manufacturer/send-reset-password-link`,
        {
          email: payload?.email,
        },
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const verifyInvitationIdAPI = createAsyncThunk(
  "verifyInvitaionIdAPI",
  async (payload) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let response = await API.get(
        `/manufacturer/reset-password?invitation_id=${payload?.invitaion_id}`,

        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const setNewPasswordAPI = createAsyncThunk(
  "setNewPasswordAPI",
  async (payload) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let response = await API.post(
        `/manufacturer/reset-password?invitation_id=${payload?.invitaion_id}`,
        {
          new_password: payload?.new_password,
        },
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const userProfileAPI = createAsyncThunk(
  "userProfile",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      const response = await API.get(`/manufacturer/user-profile`, config);
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const userSellerProfileAPI = createAsyncThunk(
  "userSellerProfileAPI",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      const response = await API.get(`/seller/user-profile`, config);
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const userRolesAPI = createAsyncThunk(
  "userRoles",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      const response = await API.get(`/manufacturer/user-role`, config);
      console.log(response, "response?");
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const verifySignUpInvitationIdAPI = createAsyncThunk(
  "verifySignUpInvitationIdAPI",
  async (payload) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let response = await API.get(
        `/manufacturer/team-member/onboard/invite?invitation_id=${payload?.invitation_id}`,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);


export const changePasswordSignUpInvitationIdAPI = createAsyncThunk(
  "changePasswordSignUpInvitationIdAPI",
  async (payload) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let response = await API.post(
        `/manufacturer/team-member/onboard/invite?invitation_id=${payload?.invitation_id}`,
        {
          new_password: payload?.password,
          authorization_code: payload?.authorization_code,
        },
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);