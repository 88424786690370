import { PhoneIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ProductDetails = () => {
  const { activeProduct } = useSelector((state) => state.controllers);

  const params = useParams();
  const { modalname, revisionid } = params;

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      // textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  };
  return (
    <Card size={{ base: "sm", md: "md" }}>
      <CardBody>
        <Flex {...styles.flexContainer}>
          <Flex
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Avatar {...styles.avatar} name={activeProduct?.type?.name} />
            <Box ml={4}>
              <Flex gap={2}>
                <Heading {...styles.deviceHeader}>
                  {activeProduct?.type ? activeProduct?.type?.name : "'N/A"}
                </Heading>
              </Flex>
              <Flex {...styles.subheaderItems} mt={1}>
                Model -{" "}
                {activeProduct?.model ? activeProduct?.model?.name : "N/A"}
              </Flex>
              <Flex {...styles.subheaderItems} mt={1}>
                Revision -{" "}
                {activeProduct?.revision ? activeProduct?.revision : "N/A"}
              </Flex>
            </Box>
          </Flex>
          <Box display="flex" flexDirection="column" gap={2}>
            {/* <Button size={"sm"} width="10rem" colorScheme="facebook">
              Assign Device
            </Button>
            <Button
              variant="outline"
              width="10rem"
              colorScheme="black"
              size="sm"
            >
              Modify
            </Button>

            <Button width="10rem" variant="outline" colorScheme="red" size="sm">
              Delete
            </Button> */}
            <Heading {...styles.deviceHeader}>
              Total Devices -{" "}
              {activeProduct?.device ? activeProduct?.device?.length : 0}
            </Heading>
          </Box>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default ProductDetails;
