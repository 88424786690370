import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import authReducer from "./reducers/authReducer";
import statsReducer from "./reducers/statsReducer";
import controllersReducer from "./reducers/controllersReducer";
import firmwareReducer from "./reducers/firmwareReducer";
import customerReducer from "./reducers/customerReducer";
import manufacturerReducer from "./reducers/manufacturerReducer";
import vendorsReducer from "./reducers/vendorsReducer";
import requestsReducer from "./reducers/requestsReducer";
import teamMembersReducer from "./reducers/teamMembersReducer";
import devicesReducer from "./reducers/devicesReducer";
import summaryReducer from "./reducers/summaryReducer";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  stats: statsReducer,
  controllers: controllersReducer,
  firmware: firmwareReducer,
  customer: customerReducer,
  manufacturer: manufacturerReducer,
  vendor: vendorsReducer,
  request: requestsReducer,
  teamMember: teamMembersReducer,
  devices: devicesReducer,
  summary: summaryReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
