import { createSlice } from "@reduxjs/toolkit";
import {
  addCustomerAPI,
  addOrganizationAPI,
  deleteCustomerAPI,
  deleteOrganizationAPI,
  editCustomerAPI,
  editOrganizationAPI,
  getCustomerAPI,
  getCustomerTeamAPI,
  getOrganizationsAPI,
  getSellerOrganizationsAPI,
  suspendOrganizationAPI,
} from "../helpers/customerAPI";

const initialState = {
  customerData: [],
  devices: [],
  billingData: [],
  customerTickets: [
    {
      ticketId: "258956",
      subject: "Payment Failed",
      lastUpdated: "29 Jan 2023",
      status: "Resolved",
    },
  ],
  organizations: [],
  activeCustomerOrg: {},
  customerTeam: [],
  activeSellerOrgId: [],
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setActiveCustomerOrg: (state, action) => {
      state.activeCustomerOrg = action?.payload;
    },
    setActiveSellerOrgId: (state, action) => {
      state.activeSellerOrgId = action?.payload;
    },
    resetStateCustomer: (state, action) => {
      state = initialState;
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCustomerAPI.fulfilled, (state, action) => {
        console.log("addCustomerAPI", action);
      })
      .addCase(addCustomerAPI.rejected, (state, action) => {
        console.log("addCustomerAPI", action);
      })
      .addCase(editCustomerAPI.fulfilled, (state, action) => {
        console.log("editCustomerAPI", action);
      })
      .addCase(editCustomerAPI.rejected, (state, action) => {
        console.log("editCustomerAPI", action);
      })
      .addCase(getCustomerAPI.fulfilled, (state, action) => {
        console.log("getCustomerAPI", action);
        state.customerData = action?.payload?.data;
      })
      .addCase(getCustomerAPI.rejected, (state, action) => {
        console.log("getCustomerAPI", action);
        state.customerData = [];
      })
      .addCase(addOrganizationAPI.fulfilled, (state, action) => {
        console.log("addOrganizationAPI", action);
      })
      .addCase(addOrganizationAPI.rejected, (state, action) => {
        console.log("addOrganizationAPI", action);
      })
      .addCase(getOrganizationsAPI.fulfilled, (state, action) => {
        state.organizations = action?.payload?.data;
      })
      .addCase(getOrganizationsAPI.rejected, (state) => {
        // state.organizations = [];
      })
      .addCase(getSellerOrganizationsAPI.fulfilled, (state, action) => {
        state.organizations = action?.payload?.data;
      })
      .addCase(getSellerOrganizationsAPI.rejected, (state) => {
        // state.organizations = [];
      })
      .addCase(editOrganizationAPI.fulfilled, (state, action) => {
        console.log("editOrganizationAPI", action);
      })
      .addCase(editOrganizationAPI.rejected, (state, action) => {
        console.log("editOrganizationAPI", action);
      })
      .addCase(deleteOrganizationAPI.fulfilled, (state, action) => {
        console.log("deleteOrganizationAPI", action);
        // state.organizations = action?.payload?.data;
      })
      .addCase(deleteOrganizationAPI.rejected, (state, action) => {
        console.log("loading Organization failed", action);
      })
      .addCase(getCustomerTeamAPI.fulfilled, (state, action) => {
        state.customerTeam = action.payload.data;
      })
      .addCase(getCustomerTeamAPI.rejected, (state) => {
        state.customerTeam = [];
      })
      .addCase(deleteCustomerAPI.fulfilled, (state, action) => {
        console.log("deleteCustomerAPI", action);
        state.customerData = action?.payload?.data;
      })
      .addCase(deleteCustomerAPI.rejected, (state, action) => {
        console.log("loading CustomerData failed", action);
      })
      .addCase(suspendOrganizationAPI.pending, (state, action) => {
        console.log(action);
      })
      .addCase(suspendOrganizationAPI.fulfilled, (state, action) => {
        console.log(action);
      })
      .addCase(suspendOrganizationAPI.rejected, (state, action) => {
        console.log(action);
      });
  },
});

export const {
  setActiveCustomerOrg,
  setActiveSellerOrgId,
  resetStateCustomer,
} = customersSlice.actions;

export default customersSlice.reducer;
