import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pieChartOptions: {
    title: {
      text: "Top Models Sold",
      x: "left",
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      right: "right",
      data: ["Model 1", "Model 2", "Model 3", "Model 4"],
    },
    series: [
      {
        name: "Top Models Sold",
        type: "pie",
        radius: "55%",
        center: ["50%", "60%"],
        data: [
          { value: 48, name: "Model 1" },
          { value: 32, name: "Model 2" },
          { value: 12, name: "Model 3" },
          { value: 25, name: "Model 4" },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  },
  barChartOption: {
    title: {
      text: "Devices Sold",
      x: "left",
    },
    tooltip: {},
    legend: {
      bottom: "left",
      data: ["sold", "unsold"],
    },
    xAxis: {
      data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    },
    yAxis: {},
    series: [
      {
        name: "sold",
        type: "bar",
        data: [5, 20, 36, 10, 10, 20],
      },
      {
        name: "unsold",
        type: "bar",
        data: [2, 10, 21, 8, 9, 16],
      },
    ],
  },
};

export const statsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    resetStateStats: (state, action) => {
      state = initialState;
      return initialState;
    },
  },
  extraReducers: () => {},
});

export const {resetStateStats} = statsSlice.actions;

export default statsSlice.reducer;
