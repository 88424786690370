import { AddIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ControllerModels from "../components/controllerModels";
import { getModelsAPI } from "../redux/helpers/controllerAPI";
import { useDispatch, useSelector } from "react-redux";

const ModelConfiguration = () => {
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(
    location?.state?.tourindex ? location.state?.tourindex : 0
  );
  const dispatch = useDispatch();
  const activeType = useSelector((state) => state?.controllers?.activeType);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const navigate = useNavigate();
  const styles = {
    flexContainer: {
      gap: 4,
      flexDirection: "column",
    },
    graphBox: {
      p: 4,
      boxShadow: "md",
      bgColor: "white",
      borderRadius: 6,
    },
    tabHeading: {
      fontWeight: "bold",
    },
    tabindecater: {
      mt: "-1.5px",
      height: "2px",
      bg: "blue.500",
      borderRadius: "1px",
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "facebook",
    },
    bredcrumbsContainer: {
      mb: 6,
      spacing: "8px",
      cursor: isHovered ? "pointer" : "default",
      textDecoration: isHovered ? "underline" : "default",
    },
    cardFlexContainer: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getModelsAPI());
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  return (
    <Box>
      <Card size={{ base: "sm", md: "md" }} style={{ marginBottom: "2%" }} >
        <CardBody>
        <Heading size='md' style={{ marginBottom: "2%" }}>Product Details</Heading>
          <Flex {...styles.cardFlexContainer}>
            <Flex
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Avatar {...styles.avatar} name={activeType?.name} />
              <Box ml={4}>
                <Flex gap={2}>
                  <Heading {...styles.deviceHeader}>
                    {activeType?.name ? activeType?.name : "'N/A"}
                  </Heading>
                </Flex>
                <Flex {...styles.subheaderItems} mt={1}>
                  Notes -{" "}
                  {activeType?.note ? activeType?.note : "N/A"}
                </Flex>
              </Box>
            </Flex>
            <Box display="flex" flexDirection="column" gap={2}>
              <Heading {...styles.deviceHeader}>
                Total Models -{" "}
                {activeType?.number_of_models
                  ? activeType?.number_of_models
                  : 0}
              </Heading>
            </Box>
          </Flex>
        </CardBody>
      </Card>
      <Flex direction={"row"} justifyContent={"space-between"}>
        <Text
          {...styles.bredcrumbsContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Models
        </Text>
        <Box style={{ marginInline: 8 }}>
          <Button
            tour-guide="step-10"
            {...styles.button}
            rightIcon={<AddIcon />}
            onClick={() => navigate("/add-model")}
          >
            Add Model
          </Button>
        </Box>
      </Flex>
      <Flex {...styles.flexContainer}>
        <Box {...styles.graphBox}>
          <Tabs
            position="relative"
            variant="enclosed"
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab {...styles.tabHeading} tour-guide="step-9">
                Models
              </Tab>
            </TabList>
            <TabIndicator {...styles.tabindecater} />
            <TabPanels index={tabIndex}>
              <TabPanel>
                <ControllerModels />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Box>
  );
};

// ProductConfiguration.proptypes = {
//   tourindex: PropTypes?.number,
// };

export default ModelConfiguration;
