import {
  Button,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  approveOrganizationAPI,
  deleteCustomerAPI,
  deleteOrganizationAPI,
  editCustomerAPI,
  editOrganizationAPI,
  getCustomerAPI,
  getOrganizationsAPI,
} from "../redux/helpers/customerAPI";
import SuccessMessageModal from "./successMessageModal";
import { EditCustomerModal } from "./editCustomerModal";
import Loader from "./loader";
import { DeleteIcon, TriangleUpIcon, TriangleDownIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import DeleteDeviceModal from "./deleteDeviceModal";

export default function CustomersTable({
  customers,
  handleRowClick,
  refreshCustomers,
}) {
  const dispatch = useDispatch();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
  const deletionType = "customer";
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCustomer(null);
  };

  console.log("org_contact_person_phone_number", selectedCustomer);

  const handleApproveClick = (customerOrganization) => {
    setLoading(true);
    try {
      const organisationPayload = { organization_id:customerOrganization?.id }
      const resApproveApi = dispatch(approveOrganizationAPI(organisationPayload));
      resApproveApi.then((res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage(res?.payload?.message);
          refreshCustomers();
          setisSuccessModalOpen(true);
        } else {
          setLoading(false);
          toast({
            title: "Error while Approving Customer",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in ApproveApi", err);
    }
  };

  const handleRejectClick = (customerOrganization) => {
    setLoading(true);
    try {
      const organisationPayload = { organization_id:customerOrganization?.id }
      const resRejectApi = dispatch(deleteOrganizationAPI(organisationPayload));
      resRejectApi.then((res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage(res?.payload?.message);
          refreshCustomers();
          setisSuccessModalOpen(true);
        } else {
          setLoading(false);
          toast({
            title: "Error while Rejecting Customer",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteOrganizationApi", err);
    }  };

  const handleModifyClick = (teamMember) => {
    setSelectedCustomer(teamMember);
    setIsModalOpen(true);
  };

  const handleDeleteModalOpen = (teamMember) => {
    setIsDeleteModalOpen(true);
    setSelectedCustomer(teamMember);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDeleteModel payload", payload);

      const resDeleteApi = dispatch(deleteOrganizationAPI(payload));
      resDeleteApi.then((res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage(res?.payload?.message);
          refreshCustomers();
          handleCloseDeleteModal();
          setisSuccessModalOpen(true);
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Customer",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  const onSave = async (data) => {
    setLoading(true);
    try {
      const payloadData = {
        organization_name:
          selectedCustomer?.name === data?.organization_name
            ? ""
            : data?.organization_name,
        org_contact_person_first_name:
          selectedCustomer?.first_name === data?.org_contact_person_first_name
            ? ""
            : data?.org_contact_person_first_name,
        org_contact_person_last_name:
          selectedCustomer?.last_name === data?.org_contact_person_last_name
            ? ""
            : data?.org_contact_person_last_name,
        // role_id:
        //   selectedCustomer?.role_id == data?.role_id ? "" : data?.role_id,
        org_contact_person_email:
          selectedCustomer?.email === data?.org_contact_person_email
            ? ""
            : data?.org_contact_person_email,
        org_contact_person_phone_number:
          selectedCustomer?.phone_number ===
          data?.org_contact_person_phone_number
            ? ""
            : data?.org_contact_person_phone_number,
      };

      const payload = Object.fromEntries(
        Object.entries(payloadData)?.filter(([, value]) => value !== "")
      );
      payload.organization_id = selectedCustomer?.id;
      const editMemberReq = dispatch(editOrganizationAPI(payload));
      editMemberReq
        .then((result) => {
          console.log(result);

          if (result?.payload?.success) {
            refreshCustomers();
            handleCloseModal();
            setLoading(false);
            setSuccessMessage(result?.payload?.message);
            setisSuccessModalOpen(true);
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "60vh",
    },
    customerName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      width: "70%",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
    },
  };

  // Sorting handler
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Sorting function
  const sortedCustomers = React.useMemo(() => {
    if (sortConfig.key) {
      return customers.slice().sort((a, b) => {
        const aValue = a?.[sortConfig.key]?.name || a?.[sortConfig.key];
        const bValue = b?.[sortConfig.key]?.name || b?.[sortConfig.key];
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return customers;
  }, [customers, sortConfig]);

  // Helper function to render sort icon
  const renderSortIcon = (key) => {
    const isActive = sortConfig.key === key;
    const iconColor = isActive ? "blue.500" : "gray.400";
    const icon = sortConfig.direction === 'ascending' ? (
      <TriangleUpIcon ml={1} color={iconColor} />
    ) : (
      <TriangleDownIcon ml={1} color={iconColor} />
    );
    return isActive ? icon : <TriangleUpIcon ml={1} color="gray.400" />
  };

  return (
    <>
      <Loader loading={loading} />
      {customers?.length > 0 ? (
        <TableContainer {...styles.tableContainer}>
          <Table
            size={{ base: "sm", md: "md" }}
            variant="striped"
            colorScheme="blackAlpha"
          >
            <Thead>
              <Tr>
                <Th onClick={() => handleSort("name")}>
                  ORGANIZATION {renderSortIcon("name")}
                </Th>
                <Th onClick={() => handleSort("first_name")}>
                  FIRST NAME {renderSortIcon("first_name")}
                </Th>
                <Th onClick={() => handleSort("last_name")}>
                  LAST NAME {renderSortIcon("last_name")}
                </Th>
                <Th onClick={() => handleSort("email")}>
                  EMAIL {renderSortIcon("email")}
                </Th>
                <Th onClick={() => handleSort("phone_number")}>
                  PHONE NO. {renderSortIcon("phone_number")}
                </Th>
                <Th onClick={() => handleSort("authorization_code")}>
                  Authorization Code {renderSortIcon("authorization_code")}
                </Th>
                <Th onClick={() => handleSort("is_approved")}>
                  Approval Status {renderSortIcon("is_approved")}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedCustomers?.map((customer, index) => (
                <Tr key={index} style={{ cursor: "pointer" }}>
                  <Td
                    {...styles.customerName}
                    onClick={() => handleRowClick(customer)}
                  >
                    {customer?.name?.length > 30
                      ? `${customer?.name?.slice(0, 30)}...`
                      : customer?.name}
                  </Td>
                  <Td onClick={() => handleRowClick(customer)}>
                    <Text>{customer?.first_name}</Text>
                  </Td>
                  <Td onClick={() => handleRowClick(customer)}>
                    {customer?.last_name}
                  </Td>
                  <Td onClick={() => handleRowClick(customer)}>
                    {customer?.email}
                  </Td>
                  <Td onClick={() => handleRowClick(customer)}>
                    {customer?.phone_number}
                  </Td>

                  {/* TODO: Total Owned device Integration */}
                  <Td onClick={() => handleRowClick(customer)}>
                    {customer?.authorization_code}
                  </Td>
                  <Td>
                    {customer?.is_approved === false ? (
                      <Stack direction="row" spacing={4}>
                        <Button
                          leftIcon={<CheckIcon />}
                          colorScheme="teal"
                          variant="outline"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleApproveClick(customer);
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          rightIcon={<CloseIcon />}
                          colorScheme="red"
                          variant="outline"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRejectClick(customer);
                          }}
                        >
                          Reject
                        </Button>
                      </Stack>
                    ) : (
                      customer?.is_approved ? "Approved" : "Rejected"
                    )}
                  </Td>
                  {/* <Td>
                    <Button
                      {...styles.actionButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleModifyClick(customer);
                        console.log(customer, "customer");
                      }}
                    >
                      Modify
                    </Button>
                    <DeleteIcon
                      cursor="pointer"
                      w={8}
                      h={8}
                      mx={3}
                      color="red"
                      boxSize={6}
                      onClick={() => {
                        handleDeleteModalOpen(customer);
                      }}
                    />
                  </Td> */}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text align={"center"} p={4}>
          No customer found
        </Text>
      )}
      {isDeleteModalOpen && (
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={selectedCustomer}
          onSave={onSaveDeleteModel}
          type={deletionType}
        />
      )}

      {isModalOpen && (
        <EditCustomerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={onSave}
          customer={selectedCustomer}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={successMessage}
          onClose={() => {
            setisSuccessModalOpen(false);
            // window.location.reload();
          }}
        />
      )}
    </>
  );
}

CustomersTable.propTypes = {
  customers: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  refreshCustomers: PropTypes.func,
};
