import React from "react";
import { Box, Text, theme } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { BiAddToQueue, BiBoltCircle, BiCart } from "react-icons/bi";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
} from "@chakra-ui/react";

const DashboardUserAssetCountSection = ({
  device_count,
  active_devices_count,
  sold_devices_count,
  screen,
  product_type_count,
  customer_count,
  models_count,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <Box display={"flex"} flexDir={"row"} key={device_count}>
        <Box style={{ width: "100%" }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignContent={"center"}
            justifyContent={"flex-start"}
            // mb={2}
            // border={"2px solid black"}
            // backgroundColor={"black"}
            p={2}
            pl={0}
          >
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              flexDirection={"row"}
              alignItems={"center"}
              w={screen === "controllers" ? "16vw" : "15vw"}
              p={2}
              bg={theme.colors.white}
              borderColor={theme.colors.white[400]}
              borderRadius={8}
              marginInline={4}
              marginLeft={0}
            >
              <Stat>
                <StatLabel>Mfd Devices </StatLabel>
                <StatNumber>{device_count}</StatNumber>
                <StatHelpText>July</StatHelpText>
              </Stat>
            </Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              flexDirection={"row"}
              alignItems={"center"}
              w={screen === "controllers" ? "16vw" : "15vw"}
              p={2}
              bg={theme.colors.white}
              borderColor={theme.colors.white[400]}
              borderRadius={8}
              marginInline={4}
            >
              <Stat>
                <StatLabel>Available Devices</StatLabel>
                <StatNumber>{device_count - sold_devices_count}</StatNumber>
                <StatHelpText>July</StatHelpText>
              </Stat>
            </Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              flexDirection={"row"}
              alignItems={"center"}
              w={screen === "controllers" ? "16vw" : "15vw"}
              p={2}
              bg={theme.colors.white}
              borderColor={theme.colors.white[400]}
              borderRadius={8}
              marginInline={4}
            >
              <Stat>
                <StatLabel>Devices Sold</StatLabel>
                <StatNumber>
                  {sold_devices_count != undefined ? sold_devices_count : 0}
                </StatNumber>
                <StatHelpText>July</StatHelpText>
              </Stat>
            </Box>
          </Box>

          <Box mt={4} flexDirection={"row"} display={"flex"}>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              flexDirection={"row"}
              alignItems={"center"}
              w={screen === "controllers" ? "16vw" : "15vw"}
              p={2}
              bg={"white"}
              borderColor={theme.colors.blue[400]}
              borderRadius={8}
              marginInline={4}
              marginLeft={0}
            >
              <Stat>
                <StatLabel>Product Types </StatLabel>
                <StatNumber>{product_type_count}</StatNumber>
                <StatHelpText>July</StatHelpText>
              </Stat>
            </Box>

            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              flexDirection={"row"}
              alignItems={"center"}
              w={screen === "controllers" ? "16vw" : "15vw"}
              p={2}
              bg={"white"}
              borderColor={theme.colors.blue[400]}
              borderRadius={8}
              marginInline={4}
            >
              <Stat>
                <StatLabel>Models</StatLabel>
                <StatNumber>{models_count}</StatNumber>
                <StatHelpText>July</StatHelpText>
              </Stat>
            </Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              flexDirection={"row"}
              alignItems={"center"}
              w={screen === "controllers" ? "16vw" : "15vw"}
              p={2}
              bg={"white"}
              borderColor={theme.colors.blue[400]}
              borderRadius={8}
              marginInline={4}
            >
              <Stat>
                <StatLabel>Customers</StatLabel>
                <StatNumber>{customer_count}</StatNumber>
                <StatHelpText>July</StatHelpText>
              </Stat>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
DashboardUserAssetCountSection.propTypes = {
  device_count: PropTypes.number.isRequired,
  active_devices_count: PropTypes.number.isRequired,
  sold_devices_count: PropTypes.number.isRequired,
  screen: PropTypes.string,
};
export default DashboardUserAssetCountSection;
