import React from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  IconButton,
} from "@chakra-ui/react";
import { BiSolidPencil } from "react-icons/bi";
import PropTypes from "prop-types";

export default function ManufacturersTable({ manufacurers }) {
  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "60vh",
    },
    manufacturerName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
  };
  return (
    <TableContainer {...styles.tableContainer}>
      <Table size={"sm"} variant="striped" colorScheme="blackAlpha">
        <Thead>
          <Tr>
            <Th width={"20%"}>MANUFACTURER</Th>
            <Th width={"20%"}>EMAIL</Th>
            <Th width={"20%"}>PHONE NO.</Th>
            <Th width={"10%"}>DEVICE TYPE</Th>
            <Th width={"10%"}>DEVICE MODEL</Th>
            <Th width={"10%"}>ACTIONS</Th>
          </Tr>
        </Thead>
        <Tbody>
          {manufacurers?.map((manufacturer, index) => {
            return (
              <Tr key={index}>
                <Td>
                  <Text {...styles.manufacturerName}>
                    {manufacturer?.manufacturer}
                  </Text>
                </Td>
                <Td>{manufacturer?.email}</Td>
                <Td>{manufacturer?.phoneNumber}</Td>
                <Td>{manufacturer?.deviceType}</Td>
                <Td>{manufacturer?.devieModel}</Td>
                <Td>
                  <Flex gap={2}>
                    <IconButton
                      colorScheme="facebook"
                      size="sm"
                      fontSize={18}
                      icon={<DeleteIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        alert("Development under progress 😉");
                      }}
                    />
                    <IconButton
                      colorScheme="facebook"
                      size="sm"
                      fontSize={18}
                      icon={<BiSolidPencil />}
                      onClick={(e) => {
                        e.stopPropagation();
                        alert("Development under progress 😉");
                      }}
                    />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

ManufacturersTable.propTypes = {
  manufacurers: PropTypes.array.isRequired,
};
