import { Box, Flex, Spacer, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AddTeamMemberModal from "../components/addTeamMemberModal";
import Breadcrumbs from "../components/breadcrumbs";
import Loader from "../components/loader";
import SearchFilter from "../components/searchFilter";
import TeamMembersTable from "../components/teamMembersTable";
import { addMemberAPI, addSellerMemberAPI, getMemberAPI, getSellerMemberAPI } from "../redux/helpers/teamAPI";

function Team() {
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();
  const { isOpen } = location.state || false;
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [loading, setLoading] = useState(false);
  // const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  // const teamMemberData = useSelector((state) =>
  //   [...state?.teamMember?.teamMemberData]?.sort((a, b) => a?.id - b?.id)
  // );
  const teamMemberData = useSelector((state) => state?.teamMember?.teamMemberData )
  const [filteredteamMemberData, setfilteredteamMemberData] = useState([]);
  const user_type = useSelector((state) => state?.auth?.user_type);

  useEffect(() => {
    try {
      // if (!teamMemberData?.length) {
        setLoading(true);
        if(user_type === "Manufacturer"){
          dispatch(getMemberAPI()).then((res) => {
            const { data } = res?.payload;
            setfilteredteamMemberData([...data]?.sort((a, b) => a?.id - b?.id));
            setLoading(false);
          });
        } else {
          dispatch(getSellerMemberAPI()).then((res) => {
            const { data } = res?.payload;
            setfilteredteamMemberData([...data]?.sort((a, b) => a?.id - b?.id));
            setLoading(false);
          });
        }
      // } else {
      //   setfilteredteamMemberData(teamMemberData);
      // }
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  }, [dispatch]);

  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
    },
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [searchParams, setSearchParams] = useState({
    name: "",
  });

  const handleSearch = () => {
    const newfilteredteamMemberData = teamMemberData?.filter((data) => {
      const nameMatch =
        !searchParams.name ||
        data.first_name
          .toLowerCase()
          .includes(searchParams.name.toLowerCase()) ||
        data.last_name.toLowerCase().includes(searchParams.name.toLowerCase());

      return nameMatch;
    });

    setfilteredteamMemberData(newfilteredteamMemberData);
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
    });
    setfilteredteamMemberData(teamMemberData);
  };

  const onSave = (payload) => {
    setLoading(true);
    try {
      const addMemberReq = user_type === "Manufacturer" ? dispatch(addMemberAPI(payload)) : dispatch(addSellerMemberAPI(payload));
      addMemberReq
        .then((result) => {
          console.log(result);
          const { success, message } = result?.payload;
          if (success) {
            const getmemberReq = user_type === "Manufacturer" ? dispatch(getMemberAPI()) : dispatch(getSellerMemberAPI())
              getmemberReq.then(() => {
                handleCloseModal();
                setLoading(false);
                toast({
                  title: "Success",
                  position: "top-right",
                  description: message,
                  status: "success",
                  duration: 6000,
                  isClosable: true,
                });
              })
              .catch((error) => {
                setLoading(false);
                toast({
                  title: "Error",
                  description: "Something went wrong!",
                  status: "error",
                  duration: 6000,
                  isClosable: true,
                });
              });
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex>
        <Box>
          <Breadcrumbs />
        </Box>
        <Spacer />
        <AddTeamMemberModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          onSave={onSave}
          type={"Add Team Member"}
          //onSuccess={}
        />
        {/* {isSuccessModalOpen && (
          <SuccessMessageModal
            isOpen={isSuccessModalOpen}
            message={"Successfully added team member to your account"}
            onClose={() => {
              setisSuccessModalOpen(false);
            }}
          />
        )} */}
      </Flex>
      <Box {...styles.topBox}>
        <SearchFilter
          type="name"
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <TeamMembersTable
          enableActions={true}
          teamMembers={filteredteamMemberData}
        />
      </Box>
    </Box>
  );
}

export default Team;
