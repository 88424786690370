import React from "react";
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  // ModalCloseButton,
  Flex,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import Lottie from "react-lottie";
import animationData from "../assets/animations/tick-animation.json";
import PropTypes from "prop-types";

const SuccessMessageModal = ({ isOpen, onClose, message }) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}>
              <Box mt={1}>
                <Lottie options={defaultOptions} height={36} width={36} />
              </Box>
              <Text>Success</Text>
            </Flex>
          </ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <Box display="flex" alignItems="center">
              <Text ml={9} fontSize="md" fontWeight="normal">
                {message}
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => onClose()}> OK </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

SuccessMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
export default SuccessMessageModal;

