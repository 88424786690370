// theme.js or theme.ts
import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  colors: {
    brand: {
      500: "#f56565", // Checked color (red)
      200: "#48BB78", // Unchecked color (green)
    },
  },
  components: {
    Switch: {
      baseStyle: {
        track: {
          bg: "brand.200", // Default unchecked background color
          _checked: {
            bg: "brand.500", // Background color when checked
          },
        },
      },
    },
  },
});

export default customTheme;
