import React, { useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import ModelFooterButtons from "./modelFooterButtons";
import ModelsTriggerButton from "./modelsTriggerButton";

export const AddManufacturerModal = ({ isOpen, onClose, setIsModalOpen }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };
  return (
    <>
      <ModelsTriggerButton
        setIsModalOpen={setIsModalOpen}
        type="Manufacturer"
      />
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Manufacturer</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                {...styles.input}
                ref={initialRef}
                placeholder="Enter manufacturer name"
              />
            </FormControl>

            <FormControl {...styles.formItem}>
              <FormLabel>Phone Number</FormLabel>
              <Input {...styles.input} placeholder="Enter phone number" />
            </FormControl>
            <FormControl {...styles.formItem}>
              <FormLabel>Email</FormLabel>
              <Input {...styles.input} placeholder="Enter email" />
            </FormControl>
            <FormControl {...styles.formItem}>
              <FormLabel>Device Type</FormLabel>
              <Select {...styles.input} placeholder="Select">
                <option value="type 1">Type 1</option>
                <option value="type 2">Type 2</option>
              </Select>
            </FormControl>
            <FormControl {...styles.formItem}>
              <FormLabel>Device Model</FormLabel>
              <Select {...styles.input} placeholder="Select">
                <option value="model 1">Model 1</option>
                <option value="moddel 2">Modal 2</option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModelFooterButtons onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  );
};

AddManufacturerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
