import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FaUpload } from "react-icons/fa6";
import ModelFooterButtons from "./modelFooterButtons";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
  editFirmwareAPI,
  getFirmwareListAPI,
} from "../redux/helpers/firmwareAPI";
import SuccessMessageModal from "./successMessageModal";
import Loader from "./loader";

export const EditFirmwareModal = ({ isOpen, onClose, selectedFirmware }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  // const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileChanged, setFileChanged] = useState(false);

  useEffect(() => {
    const fileName = selectedFirmware?.file_path?.split("/").pop();
    setFileName(fileName);
    setFileChanged(false);
  }, [selectedFirmware]);

  const openFileInNewTab = () => {
    window.open(selectedFirmware?.file_path, "_blank");
  };

  const nameRegex = /^[a-zA-Z0-9-.\s]+$/;
  const versionRegex = /^[0-9-.\s]+$/;
  const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .max(45, "Firmware name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Firmware name can only contain Letters, Numbers, Full Stop, Hyphen & Spaces"
      ),
    code_name: Yup.string()
      .max(45, "Firmware name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Code name can only contain Letters, Numbers, Full Stop, Hyphen & Spaces"
      ),
    version_number: Yup.string()
      .max(6, "Version number must be at most 6 characters.")
      .matches(
        versionRegex,
        "Version number can only contain Numbers, Full Stop & Hyphen"
      ),
    file: Yup.mixed()
      .test("fileSize", "File size is too large", (value) => {
        if (!value) return true;
        return value.size <= 5 * 1024 * 1024; // 5 MB
      })
      .test("fileType", "Unsupported file type", (value) => {
        if (!value) return true;
        return ["image/jpeg", "image/png", "application/pdf"].includes(
          value.type
        );
      }),
  });

  const models = useSelector((state) => state?.controllers?.models);

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
    fileNameLink: {
      color: "blue",
      cursor: "pointer",
      textDecoration: "underline",
    },
  };

  return (
    <>
      <Loader loading={loading} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Firmware</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                name: selectedFirmware?.name,
                code_name: selectedFirmware?.code_name,
                model_id: selectedFirmware?.device_model?.id,
                file: "",
                version_number: selectedFirmware?.version_number,
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => {
                setLoading(true);
                try {
                  const payload = {
                    firmware_id: selectedFirmware?.id,
                    name:
                      selectedFirmware?.name == values?.name
                        ? ""
                        : values?.name,
                    code_name:
                      selectedFirmware?.code_name == values?.code_name
                        ? ""
                        : values?.code_name,
                    model_id:
                      selectedFirmware?.device_model?.id == values?.model_id
                        ? ""
                        : values?.model_id,
                    file: values?.file,
                    version_number:
                      selectedFirmware?.version_number == values?.version_number
                        ? ""
                        : values?.version_number,
                  };

                  const data = Object.fromEntries(
                    Object.entries(payload)?.filter(([, value]) => value !== "")
                  );

                  dispatch(editFirmwareAPI(data))
                    .then((data) => {
                      const { success, message } = data?.payload || {};
                      if (success) {
                        return dispatch(getFirmwareListAPI()).then(() => {
                          toast({
                            title: "Success",
                            position: "top-right",
                            description: message,
                            status: "success",
                            duration: 6000,
                            isClosable: true,
                          });
                          setFileName("");
                          onClose();
                        });
                      } else {
                        throw new Error(
                          message || "An unknown error occurred."
                        );
                      }
                    })
                    .catch((err) => {
                      console.error("Error:", err);
                      toast({
                        title: "Error",
                        description:
                          err.message ||
                          "An error occurred while processing the request.",
                        status: "error",
                        duration: 6000,
                        isClosable: true,
                      });
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                } catch (err) {
                  setLoading(false);
                  console.error("Unexpected Error:", err);
                  toast({
                    title: "Error",
                    description: "An unexpected error occurred.",
                    status: "error",
                    duration: 6000,
                    isClosable: true,
                  });
                }
              }}
            >
              {({ handleSubmit, errors, touched, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.device_model && touched.device_model}
                  >
                    <FormLabel>Model</FormLabel>
                    <Select
                      {...styles.input}
                      ref={initialRef}
                      placeholder="Select Type"
                      id="device_model"
                      name="device_model"
                      defaultValue={selectedFirmware?.device_model?.id}
                      onChange={(event) => {
                        setFieldValue("model_id", event.target.value);
                      }}
                    >
                      {models?.map((val, index) => {
                        return (
                          <option key={index} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{errors.device_model}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.name && touched.name}
                  >
                    <FormLabel> Firmware Name </FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      style={{ marginTop: 10 }}
                      placeholder="Firmware Name"
                      id="name"
                      name="name"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.version_number && touched.version_number}
                  >
                    <FormLabel>Version</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      placeholder="Version name"
                      name="version_number"
                      id="version_number"
                    />
                    <FormErrorMessage>{errors.version_number}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.code_name && touched.code_name}
                  >
                    <FormLabel>Code Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      placeholder="Code Name"
                      name="code_name"
                      id="code_name"
                    />
                    <FormErrorMessage>{errors.code_name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.file && touched.file}
                  >
                    <FormLabel htmlFor="file">Upload File</FormLabel>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                        setFileName(event.target.files[0].name);
                        setFileChanged(true);
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="file">
                      <Button
                        as="span"
                        leftIcon={<FaUpload />}
                        cursor="pointer"
                        variant="outline"
                        colorScheme="blue"
                      >
                        Choose File
                      </Button>
                    </label>
                    {fileName && !fileChanged ? (
                      <div
                        style={styles?.fileNameLink}
                        onClick={openFileInNewTab}
                      >
                        {fileName}
                      </div>
                    ) : (
                      <div>{fileName}</div>
                    )}
                    <FormErrorMessage>{errors.file}</FormErrorMessage>
                  </FormControl>

                  <ModelFooterButtons onClose={onClose} onSave={handleSubmit} />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={successMessage}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )} */}
    </>
  );
};

EditFirmwareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  setIsModalOpen: PropTypes.func,
  selectedFirmware: PropTypes.object,
};
