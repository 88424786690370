import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { EditCustomerModal } from "./editCustomerModal";
import SuccessMessageModal from "./successMessageModal";
import DeleteDeviceModal from "./deleteDeviceModal";
import Loader from "./loader";
import { HiStatusOnline } from "react-icons/hi";
import {
  deleteOrganizationAPI,
  editOrganizationAPI,
  getCustomerAPI,
  getOrganizationsAPI,
  suspendOrganizationAPI,
} from "../redux/helpers/customerAPI";
import SuspendCustomerModal from "./SuspendCustomerModal";
import { setActiveCustomerOrg } from "../redux/reducers/customerReducer";

function CustomerDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSuspendCustomerModal, setIsSuspendCustomerModal] = useState(false);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = useState(false);
  const activeCustomerOrg = useSelector(
    (state) => state?.customer?.activeCustomerOrg
  );
  const [filteredCustomerData, setFilteredCustomerData] =
    useState(activeCustomerOrg);
  const { customer } = location.state || {};

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toast = useToast();

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleCloseSuspendModal = () => {
    setIsSuspendCustomerModal(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleModifyClick = () => {
    setIsModalOpen(true);
  };

  const onSave = async (data) => {
    setLoading(true);
    try {
      const payloadData = {
        organization_name:
          filteredCustomerData?.name === data?.organization_name
            ? ""
            : data?.organization_name,
        org_contact_person_first_name:
          filteredCustomerData?.first_name ===
          data?.org_contact_person_first_name
            ? ""
            : data?.org_contact_person_first_name,
        org_contact_person_last_name:
          filteredCustomerData?.last_name === data?.org_contact_person_last_name
            ? ""
            : data?.org_contact_person_last_name,
        org_contact_person_email:
          filteredCustomerData?.email === data?.org_contact_person_email
            ? ""
            : data?.org_contact_person_email,
        org_contact_person_phone_number:
          filteredCustomerData?.phone_number ===
          data?.org_contact_person_phone_number
            ? ""
            : data?.org_contact_person_phone_number,
        full_address: filteredCustomerData?.location
          ? filteredCustomerData?.location?.address ===
            data?.organization_address
            ? ""
            : data?.organization_address
          : "",
        postalcode: filteredCustomerData?.location
          ? filteredCustomerData?.location?.postalcode === data?.postal_code
            ? ""
            : data?.postal_code
          : "",
        location_id: filteredCustomerData?.location
          ? filteredCustomerData?.location?.id
          : "",
        authorization_code:
          filteredCustomerData?.authorization_code === data?.authorization_code
            ? ""
            : data?.authorization_code,
      };

      const payload = Object.fromEntries(
        Object.entries(payloadData)?.filter(([, value]) => value !== "")
      );
      payload.organization_id = filteredCustomerData?.id;
      const editMemberReq = dispatch(editOrganizationAPI(payload));
      editMemberReq
        .then(async (result) => {
          const { success, message } = result?.payload;
          if (success) {
            handleCloseModal();
            // dispatch(getCustomerAPI());
            const organizationList = dispatch(getOrganizationsAPI());
            organizationList.then((result) => {
              const updatedActiveOrg = result?.payload?.data?.filter(
                (data) => data?.id == activeCustomerOrg?.id
              );
              setFilteredCustomerData(updatedActiveOrg[0]);
              // TODO: updated Redux State as well
              dispatch(setActiveCustomerOrg(updatedActiveOrg[0]));
            });
            setLoading(false);
            toast({
              title: "Success",
              position: "top-right",
              description: message,
              status: "success",
              duration: 6000,
              isClosable: true,
            });
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const onSuspendCustomer = (reason) => {
    setLoading(true);
    const payload = {
      organization_id: activeCustomerOrg?.id,
      suspended_reason: reason,
      isSespended:
        activeCustomerOrg?.is_suspended == null
          ? true
          : !activeCustomerOrg?.is_suspended,
    };
    dispatch(suspendOrganizationAPI(payload))
      .then((result) => {
        const { success, message } = result?.payload;
        if (success) {
          dispatch(getOrganizationsAPI()).then((res) => {
            if (res?.payload?.success) {
              const { data } = res?.payload;
              const activeCustomer = data?.filter(
                (activeCustomer) => activeCustomer?.id == activeCustomerOrg?.id
              );
              setFilteredCustomerData(activeCustomer[0]);
              dispatch(setActiveCustomerOrg(activeCustomer[0]));
              setLoading(false);
              handleCloseSuspendModal();
              toast({
                title: "Success",
                position: "top-right",
                description: message,
                status: "success",
                duration: 6000,
                isClosable: true,
              });
            } else {
              setLoading(false);
              handleCloseSuspendModal();
              toast({
                title: res?.payload?.message,
                description: res?.payload?.response?.data?.message,
                status: "error",
                duration: 6000,
                isClosable: true,
              });
            }
          });
        } else {
          setLoading(false);
          handleCloseSuspendModal();
          toast({
            title: result?.payload?.message,
            description: result?.payload?.response?.data?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err, "Error in Api");
        setLoading(false);
        toast({
          title: "Error while suspending Customer",
          description: "",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      });
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    try {
      const resDeleteApi = dispatch(deleteOrganizationAPI(payload));
      resDeleteApi.then((res) => {
        const { success, message } = res?.payload;
        if (success) {
          dispatch(getOrganizationsAPI())
            .then(() => {
              toast({
                title: "Success",
                position: "top-right",
                description: message,
                status: "success",
                duration: 6000,
                isClosable: true,
              });
              setLoading(false);
              handleCloseDeleteModal();
              navigate(-1);
            })
            .catch((err) => {
              console.log(err);
              toast({
                title: "Error",
                description: "Error in fetching Customers",
                status: "error",
                duration: 6000,
                isClosable: true,
              });
            });
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Customer",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  };

  const handleClick = () => {
    navigate(`${location?.pathname}/assign-device`, { state: { customer } });
  };

  return (
    <>
      <Loader loading={loading} />
      <Card size={{ base: "sm", md: "md" }}>
        <CardBody>
          <Flex {...styles.flexContainer}>
            <Flex
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Avatar {...styles.avatar} name={filteredCustomerData?.name} />
              <Box ml={4}>
                <Flex gap={2}>
                  <Heading {...styles.deviceHeader}>
                    {filteredCustomerData?.name}
                  </Heading>
                </Flex>
                <Flex {...styles.subheaderItems} mt={1}>
                  {filteredCustomerData?.first_name}{" "}
                  {filteredCustomerData?.last_name}
                </Flex>

                <Flex {...styles.subheaderItems} mt={1}>
                  <Flex {...styles.subheaderItems}>
                    <PhoneIcon />
                    <Text {...styles.subheaderText}>
                      {filteredCustomerData?.phone_number}
                    </Text>
                  </Flex>
                  <Flex {...styles.subheaderItems} style={{ marginLeft: 16 }}>
                    <EmailIcon />
                    <Text {...styles.subheaderText}>
                      {filteredCustomerData?.email}
                    </Text>
                  </Flex>
                </Flex>
                <Flex {...styles.subheaderItems} mt={1}>
                  <Flex {...styles.subheaderItems}>
                    <HiStatusOnline size={20} />
                    <Text {...styles.subheaderText}>Status</Text>
                  </Flex>

                  <Flex {...styles.subheaderItems} ml={2}>
                    <Flex
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                      mt={2}
                    >
                      <Switch
                        size="md"
                        onChange={() => {
                          if (!filteredCustomerData?.is_suspended)
                            setIsSuspendCustomerModal(true);
                        }}
                        isChecked={filteredCustomerData?.is_suspended}
                      />
                      <Flex
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          color: filteredCustomerData?.is_suspended
                            ? "#f56565"
                            : "#48BB78",
                        }}
                      >
                        {filteredCustomerData?.is_suspended
                          ? "Suspended"
                          : "Active"}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            <Box display="flex" flexDirection="column" gap={2}>
              <Button
                size={"sm"}
                width="10rem"
                colorScheme="facebook"
                onClick={handleClick}
              >
                Assign Product
              </Button>
              <Button
                variant="outline"
                width="10rem"
                colorScheme="black"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  handleModifyClick();
                }}
              >
                Modify
              </Button>
              {/* <Button
                width="10rem"
                variant="outline"
                colorScheme="red"
                size="sm"
                isDisabled={filteredCustomerData?.is_suspended}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSuspendCustomerModal(true);
                }}
              >
                Suspend
              </Button> */}
              <Button
                width="10rem"
                variant="outline"
                colorScheme="red"
                size="sm"
                isDisabled={filteredCustomerData?.total_owned_devices_count > 0}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteModalOpen();
                }}
              >
                Delete
              </Button>
            </Box>
          </Flex>
        </CardBody>
      </Card>

      {isDeleteModalOpen && (
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={filteredCustomerData}
          onSave={onSaveDeleteModel}
          type={"customer"}
        />
      )}
      {isSuspendCustomerModal && (
        <SuspendCustomerModal
          isOpen={isSuspendCustomerModal}
          onclose={handleCloseSuspendModal}
          activeCustomer={filteredCustomerData}
          onSave={onSuspendCustomer}
        />
      )}
      {isModalOpen && (
        <EditCustomerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={onSave}
          customer={filteredCustomerData}
        />
      )}
      {/* {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={successMessage}
          onClose={() => {
            setisSuccessModalOpen(false);
            // window.location.reload();
          }}
        />
      )} */}
    </>
  );
}

export default CustomerDetails;
