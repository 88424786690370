import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ModelFooterButtons from "./modelFooterButtons";
import ModelsTriggerButton from "./modelsTriggerButton";
import { Field, Formik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Loader from "./loader";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const AddTeamMemberModal = ({
  isOpen,
  onClose,
  setIsModalOpen,
  onSave,
  type,
  teamMember,
}) => {
  const [loading, setLoading] = useState(false);
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const roles = useSelector((state) => state?.auth?.roles);
  // const approvalList = [{"is_approved":true, "name":"Approved"}, {"is_approved":false, "name":"Disapprove"}]
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const nameRegex = /^[a-zA-Z\s]+$/;
  const AddTeamValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(nameRegex, "First Name must contain only letters and spaces")
      .required("First Name is required")
      .max(36, "First Name must be at most 36 characters long"),
    last_name: Yup.string()
      .matches(nameRegex, "Last Name must contain only letters and spaces")
      .required("Last Name is required")
      .max(36, "Last Name must be at most 36 characters long"),
    email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(255, "Email must be at most 255 characters.")
      .required("Email is required"),
    phone_number: Yup.string().required("Phone Number is required"),
    privilege: Yup.string().required("Privilege is required"),
    // is_approved: Yup.string().required("Approval is required"),
  });
  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  return (
    <div>
      <Loader loading={loading} />
      {!type?.includes("Edit") ? (
        <ModelsTriggerButton
          setIsModalOpen={setIsModalOpen}
          type="Team Member"
        />
      ) : null}

      <Modal
        blockScrollOnMount={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{type}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={
                type?.includes("Edit") && teamMember
                  ? {
                      email: teamMember?.email,
                      first_name: teamMember?.first_name,
                      last_name: teamMember?.last_name,
                      phone_number: teamMember?.phone_number,
                      privilege:
                        roles.find(
                          (role) => role.name === teamMember?.role?.name
                        )?.id || "",
                      //   is_approved: approvalList.find(
                      //   (approvalItem) => approvalItem?.is_approved === teamMember?.is_approved
                      // )?.is_approved||""
                    }
                  : {
                      email: "",
                      first_name: "",
                      last_name: "",
                      phone_number: "",
                      privilege: "",
                      // is_approved:""
                    }
              }
              validationSchema={AddTeamValidationSchema}
              onSubmit={(values) => {
                console.log(values);
                setLoading(true);
                if (type != "Add Team Member") {
                  const data = {
                    email:
                      teamMember?.email == values?.email ? "" : values?.email,
                    first_name:
                      teamMember?.first_name == values?.first_name
                        ? ""
                        : values?.first_name,
                    last_name:
                      teamMember?.last_name == values?.last_name
                        ? ""
                        : values?.last_name,
                    phone_number:
                      teamMember?.phone_number == values?.phone_number
                        ? ""
                        : values?.phone_number,
                    privilege:
                      roles.find(
                        (role) => role?.name === teamMember?.role?.name
                      )?.id === values?.privilege
                        ? ""
                        : values?.privilege,
                    // is_approved: teamMember?.is_approved == values?.is_approved
                    // ? ""
                    // : values?.is_approved,
                  };
                  const payload = Object.fromEntries(
                    Object.entries(data).filter(([, value]) => value !== "")
                  );
                  onSave(payload);
                  setLoading(false);
                } else if (type == "Add Team Member") {
                  onSave(values);
                  setLoading(false);
                }
              }}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    isInvalid={errors.first_name && touched.first_name}
                  >
                    <FormLabel>First Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      ref={initialRef}
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="john"
                    />
                    <FormErrorMessage>{errors?.first_name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.last_name && touched.last_name}
                  >
                    <FormLabel>Last Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Doe"
                    />
                    <FormErrorMessage>{errors?.last_name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.phone_number && touched.phone_number}
                  >
                    <FormLabel>Phone Number</FormLabel>
                    <PhoneInput
                      style={{
                        borderWidth: 1,
                        borderRadius: 5,
                        height: "30px",
                        paddingLeft: 8,
                      }}
                      placeholder="275 555 76"
                      defaultCountry="NZ"
                      value={formatPhoneNumberIntl(values?.phone_number)}
                      onChange={(phone) => {
                        setFieldValue("phone_number", phone);
                      }}
                    />
                    <FormErrorMessage>{errors?.phone_number}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.email && touched.email}
                  >
                    <FormLabel>Email</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="email"
                      name="email"
                      type="email"
                      placeholder="john_doe@example.com"
                    />
                    <FormErrorMessage>{errors?.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.privilege && touched.privilege}
                  >
                    <FormLabel>Privilege</FormLabel>
                    <Select
                      {...styles.input}
                      placeholder="Select option"
                      name="privilege"
                      value={values.privilege}
                      onChange={(e) => {
                        handleChange(e.target.value);
                        console.log("e.target.value", e.target.value);
                        setFieldValue("privilege", e.target.value);
                      }}
                    >
                      {roles.map((role) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{errors?.privilege}</FormErrorMessage>
                  </FormControl>
                  {/* <FormControl
                    {...styles.formItem}
                    isInvalid={errors.is_approved && touched.is_approved}
                  >
                    <FormLabel>Approval</FormLabel>
                    <Select
                      {...styles.input}
                      placeholder="Select option"
                      name="is_approved"
                      value={values?.is_approved}
                      onChange={(e) => {
                        handleChange(e.target.value);
                        console.log("e.approvalItem.value", e.target.value);
                        setFieldValue("is_approved", e.target.value);
                      }}
                    >
                      {approvalList?.map((approvalItem) => {
                        return (
                          <option key={approvalItem?.name} value={approvalItem?.is_approved}>
                            {approvalItem?.name}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{errors?.is_approved}</FormErrorMessage>
                  </FormControl> */}
                  <ModelFooterButtons
                    onClose={onClose}
                    onSave={handleSubmit}
                    type={type}
                    data={teamMember}
                  />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
AddTeamMemberModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  teamMember: PropTypes.object,
};
export default AddTeamMemberModal;
