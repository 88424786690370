import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteOrganizationAPI,
  editOrganizationAPI,
} from "../redux/helpers/customerAPI";
import DeleteDeviceModal from "./deleteDeviceModal";
import { EditCustomerModal } from "./editCustomerModal";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";

export default function ProductsTable({
  products,
  handleRowClick,
  refreshCustomers,
}) {
  const dispatch = useDispatch();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'type', direction: 'ascending' });

  const deletionType = "customer";
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCustomer(null);
  };

  const handleModifyClick = (teamMember) => {
    setSelectedCustomer(teamMember);
    setIsModalOpen(true);
  };

  const handleDeleteModalOpen = (teamMember) => {
    setIsDeleteModalOpen(true);
    setSelectedCustomer(teamMember);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDeleteModel payload", payload);

      const resDeleteApi = dispatch(deleteOrganizationAPI(payload));
      resDeleteApi.then((res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage(res?.payload?.message);
          refreshCustomers();
          handleCloseDeleteModal();
          setisSuccessModalOpen(true);
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Customer",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  const onSave = async (data) => {
    setLoading(true);
    try {
      const payloadData = {
        organization_name:
          selectedCustomer?.name === data?.organization_name
            ? ""
            : data?.organization_name,
        org_contact_person_first_name:
          selectedCustomer?.first_name === data?.org_contact_person_first_name
            ? ""
            : data?.org_contact_person_first_name,
        org_contact_person_last_name:
          selectedCustomer?.last_name === data?.org_contact_person_last_name
            ? ""
            : data?.org_contact_person_last_name,
        // role_id:
        //   selectedCustomer?.role_id == data?.role_id ? "" : data?.role_id,
        org_contact_person_email:
          selectedCustomer?.email === data?.org_contact_person_email
            ? ""
            : data?.org_contact_person_email,
        org_contact_person_phone_number:
          selectedCustomer?.phone_number ===
          data?.org_contact_person_phone_number
            ? ""
            : data?.org_contact_person_phone_number,
      };

      const payload = Object.fromEntries(
        Object.entries(payloadData)?.filter(([, value]) => value !== "")
      );
      payload.organization_id = selectedCustomer?.id;
      const editMemberReq = dispatch(editOrganizationAPI(payload));
      editMemberReq
        .then((result) => {
          console.log(result);

          if (result?.payload?.success) {
            refreshCustomers();
            handleCloseModal();
            setLoading(false);
            setSuccessMessage(result?.payload?.message);
            setisSuccessModalOpen(true);
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "60vh",
    },
    customerName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      width: "70%",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
    },
  };

  // Sorting handler
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Sorting function
  const sortedProducts = React.useMemo(() => {
    if (sortConfig.key) {
      return products?.slice()?.sort((a, b) => {
        const aValue = a?.[sortConfig.key]?.name || a?.[sortConfig.key];
        const bValue = b?.[sortConfig.key]?.name || b?.[sortConfig.key];
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return products;
  }, [products, sortConfig]);

  // Helper function to render sort icon
  const renderSortIcon = (key) => {
    const isActive = sortConfig.key === key;
    const iconColor = isActive ? "blue.500" : "gray.400";
    const icon = sortConfig.direction === 'ascending' ? (
      <TriangleUpIcon ml={1} color={iconColor} />
    ) : (
      <TriangleDownIcon ml={1} color={iconColor} />
    );
    return isActive ? icon : <TriangleUpIcon ml={1} color="gray.400" />
  };

  return (
    <>
      <Loader loading={loading} />
      {products?.length > 0 ? (
        <TableContainer {...styles.tableContainer}>
          <Table
            size={{ base: "sm", md: "md" }}
            variant="striped"
            colorScheme="blackAlpha"
          >
            <Thead>
              <Tr>
                <Th onClick={() => handleSort('type')}>
                  Product {renderSortIcon('type')}
                </Th>
                <Th onClick={() => handleSort('model')}>
                  Model {renderSortIcon('model')}
                </Th>
                <Th onClick={() => handleSort('revision')}>
                  Revision {renderSortIcon('revision')}
                </Th>
                <Th onClick={() => handleSort('quantity_sold')}>
                  Qty Installed {renderSortIcon('quantity_sold')}
                </Th>
                <Th onClick={() => handleSort('total_quantity')}>
                  Qty In-stock {renderSortIcon('total_quantity')}
                </Th>
                <Th onClick={() => handleSort('less_than_6_months')}>
                  {"< 6 months"} {renderSortIcon('less_than_6_months')}
                </Th>
                <Th onClick={() => handleSort('less_than_12_months')}>
                  {"< 12 months"} {renderSortIcon('less_than_12_months')}
                </Th>
                <Th onClick={() => handleSort('less_than_18_months')}>
                  {"< 18 months"} {renderSortIcon('less_than_18_months')}
                </Th>
                <Th onClick={() => handleSort('less_than_24_months')}>
                  {"< 24 months"} {renderSortIcon('less_than_24_months')}
                </Th>
                <Th onClick={() => handleSort('older')}>
                  Older {renderSortIcon('older')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedProducts?.map((product, index) => (
                    <Tr
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRowClick(product)}
                    >
                      <Td {...styles.customerName}>{product?.type?.name}</Td>
                      <Td {...styles.customerName}>{product?.model?.name}</Td>
                      <Td {...styles.customerName}>{product?.revision}</Td>
                      <Td {...styles.customerName}>{product?.quantity_sold}</Td>
                      <Td {...styles.customerName}>
                        {Number(product?.total_quantity) -
                          Number(product?.quantity_sold)}
                      </Td>
                      <Td {...styles.customerName}>{product?.less_than_6_months}</Td>
                      <Td {...styles.customerName}>{product?.less_than_12_months}</Td>
                      <Td {...styles.customerName}>{product?.less_than_18_months}</Td>
                      <Td {...styles.customerName}>{product?.less_than_24_months}</Td>
                      <Td {...styles.customerName}>{product?.older}</Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text align={"center"} p={4}>
          No product found
        </Text>
      )}
      {isDeleteModalOpen && (
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={selectedCustomer}
          onSave={onSaveDeleteModel}
          type={deletionType}
        />
      )}

      {isModalOpen && (
        <EditCustomerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={onSave}
          customer={selectedCustomer}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={successMessage}
          onClose={() => {
            setisSuccessModalOpen(false);
            // window.location.reload();
          }}
        />
      )}
    </>
  );
}

ProductsTable.propTypes = {
  customers: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  refreshCustomers: PropTypes.func,
};
