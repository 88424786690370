import { useSelector } from "react-redux";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Layout from "../components/layout";
import Controllers from "../screens/controllers";
import Dashboard from "../screens/dashboard";
import Device from "../screens/device";
import Firmware from "../screens/firmware";
import ForgotPassword from "../screens/forgotPassword";
import Login from "../screens/login";
import Manufacturers from "../screens/manufacturers";
import NotFound from "../screens/notFound";
import Settings from "../screens/settings";
import Signup from "../screens/signup";
import Team from "../screens/team";
// import Vendors from "../screens/vendors";
// import Requests from "../screens/requests";
import Types from "../screens/controllerTypesScreen";
import Models from "../screens/controllerModelsScreen";
import Customer from "../screens/customer";
import CustomersList from "../screens/customersList";
import Onboarding from "../screens/onboarding";
import ControllerDevices from "../screens/controllerDevices";
import ResetPassword from "../screens/resetPassword";
import ProductConfiguration from "../screens/productConfiguration";
import AddUnitCard from "../components/addUnitCard";
import ModelsTabs from "../components/modelsTab";
import ProductsTab from "../components/productsTab";
import AddFirmware from "../components/addFirmware";
import AssignDeviceToCustomerPop from "../screens/assignDeviceToCustomerPop";
import FirmwareDetails from "../components/firmwareDetails";
import ProductList from "../screens/ProductList";
import Product from "../screens/Product";
import AssignDeviceToCustomerPopRevamp from "../screens/assignDeviceToCustomerPopRevamp";
import UnitsTabs from "../components/unitsTab";
import ModelConfiguration from "../screens/modelConfiguration";

export default function Navigations() {
  const { is_authenticated, is_setup_complete } = useSelector(
    (state) => state.auth
  );

  const routes = [
    {
      path: "/",
      element: is_authenticated ? <Layout /> : <Navigate to="/login" />,
      children: [
        { index: true, element: <Dashboard /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "onboarding", element: <Onboarding /> },
        // { path: "products", element: <Controllers /> },
        { path: "products", element: <ProductList /> },
        { path: "products/:devicetype/:modalname", element: <Device /> },
        { path: "dashboard/:modalname", element: <Device /> },
        { path: "customers/:organization-name/:device", element: <Device /> },
        { path: "products/types", element: <Types /> },
        { path: "products/models", element: <Models /> },
        { path: "products/models/devices", element: <ControllerDevices /> },
        // { path: "products/models/devices/device", element: <Device /> },
        { path: "products/:devicetype", element: <Product /> },
        { path: "firmware/details", element: <FirmwareDetails /> },
        { path: "firmware", element: <Firmware /> },
        { path: "customers", element: <CustomersList /> },
        { path: "customers/:organization-name", element: <Customer /> },
        {
          path: "customers/:organization-name/assign-device",
          element: <AssignDeviceToCustomerPopRevamp />,
        },
        { path: "manufacturers", element: <Manufacturers /> },
        { path: "team", element: <Team /> },
        { path: "add-model", element: <ModelsTabs /> },
        { path: "add-type", element: <ProductsTab /> },
        { path: "add-firmware", element: <AddFirmware /> },
        { path: "add-product", element: <AddUnitCard /> },
        { path: "add-units", element: <UnitsTabs /> },
        { path: "product-configuration", element: <ProductConfiguration /> },
        {path:  "product-configuration/models", element:<ModelConfiguration/>}
      ],
    },
    { path: "/login", element: <Login /> },
    { path: "/signup", element: <Signup /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/reset/password", element: <ResetPassword /> },
    {
      path: "*",
      element: is_authenticated ? <NotFound /> : <Navigate to="/" />,
    },
  ];

  return createBrowserRouter(routes);
}
