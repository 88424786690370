import { createSlice } from "@reduxjs/toolkit";
import {
  addDeviceAPI,
  getDeviceAPI,
  getSellerDeviceAPI,
  updateDeviceAPI,
} from "../helpers/deviceAPI";

const initialState = {
  devices: [],
};

export const devicesReducer = createSlice({
  name: "devices",
  initialState,
  reducers: {
    resetStateDevice: (state, action) => {
      state = initialState;
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addDeviceAPI.fulfilled, (state, action) => {
        state.devices = action?.payload?.data;
      })
      .addCase(addDeviceAPI.rejected, (state, action) => {
        state.devices = [];
        console.log("adding devices failed", action);
      })
      .addCase(getDeviceAPI.fulfilled, (state, action) => {
        console.log("getDevice", action?.payload?.data);
        state.devices = action?.payload?.data;
      })
      .addCase(getDeviceAPI.rejected, (state, action) => {
        console.log("loading devices failed", action);
      })
      .addCase(getSellerDeviceAPI.fulfilled, (state, action) => {
        console.log("getDevice", action?.payload?.data);
        state.devices = action?.payload?.data;
      })
      .addCase(getSellerDeviceAPI.rejected, (state, action) => {
        console.log("loading devices failed", action);
      })
      .addCase(updateDeviceAPI.fulfilled, (state, action) => {
        state.devices = action?.payload?.data;
      })
      .addCase(updateDeviceAPI.rejected, (state, action) => {
        // state.models = action?.payload;
        console.log("updating devices failed", action);
      });
  },
});

export const { resetStateDevice } = devicesReducer.actions;

export default devicesReducer.reducer;
