import {
  Box,
  // Avatar,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Spacer,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  BiCode,
  BiCodeBlock,
  BiDevices,
  BiMemoryCard,
  BiSelectMultiple,
  BiVial,
} from "react-icons/bi";
// import { FaBoxArchive } from "react-icons/fa6";
// import { useLocation } from "react-router-dom";
import { DownloadIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteFirmwareAPI,
  getFirmwareListAPI,
} from "../redux/helpers/firmwareAPI";
import Breadcrumbs from "./breadcrumbs";
import DeleteDeviceModal from "./deleteDeviceModal";
import { EditFirmwareModal } from "./editFirmwareModal";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";

function FirmwareDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedFirmware } = location.state || {};
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [isEditModelOpen, setIsEditModelOpen] = useState(false);
  const deviceData = useSelector((state) => state?.controllers?.activeDevice);
  const configHistory = useSelector(
    (state) => state?.controllers?.activeDeviceConfigHistory
  );
  const deletionType = "firmware";
  const firmwareData = useSelector((state) => state?.firmware?.firmwareData);
  const filteredFirmware = firmwareData?.filter(
    (firmware) => firmware?.id == selectedFirmware?.id
  );
  useEffect(() => {
    if (!firmwareData) setLoading(true);
    else setLoading(false);
  }, [firmwareData]);

  const handleCloseModal = () => {
    setIsEditModelOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleModifyClick = () => {
    setIsEditModelOpen(true);
  };

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "end",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
      mt: 1,
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "10px", md: "14px" },
    },
    subheaderTextleft: {
      width: "30%",
      pl: 2,
      fontSize: { base: "10px", md: "14px" },
    },
    firmwareText: {
      fontSize: "10px",
      color: "#30C268",
      mt: "1",
    },
    greenIcon: {
      color: "#30C268",
    },
    readingsBox: {
      borderRadius: 6,
      px: "4",
      py: "2",
      border: "1px",
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
    },
    readingsTextContainer: {
      alignItems: "center",
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    readingsTopic: {
      pt: "2",
      fontSize: "sm",
      color: "#929FB1",
    },
    pressureReadingBox: {
      mt: "-2",
      borderRadius: 6,
      px: "4",
      py: "2",
      border: "1px",
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
    },
    pressureReadingTextContainer: {
      alignItems: "center",
    },
    pressureReadingText: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    pressureReadingTopic: {
      // pt: "2",
      fontSize: "16px",
      // color: "#929FB1",
    },
  };

  const handleEditModelCloseModal = () => {
    setIsEditModelOpen(false);
  };

  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };

      const resDeleteApi = dispatch(deleteFirmwareAPI(payload));
      resDeleteApi.then(async (res) => {
        if (res?.payload?.success) {
          // setSuccessMessage("Firmware Deleted successfully");
          await dispatch(getFirmwareListAPI());
          handleCloseDeleteModal();
          // setisSuccessModalOpen(true);
          setLoading(false);
          navigate(-1);
          toast({
            title: "Success",
            position: "top-right",
            description: "Firmware Deleted successfully",
            status: "success",
            duration: 6000,
            isClosable: true,
          });
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Firmware",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  const handleEditModelOpen = (device) => {
    setIsEditModelOpen(true);
    setSingleDeviceModel(device);
  };

  return (
    <>
      <Loader loading={loading} />
      <Flex>
        <Flex justifyContent="space-between">
          <Box>
            <Breadcrumbs />
          </Box>
        </Flex>
        <Spacer />
      </Flex>
      {filteredFirmware?.length && (
        <Card size={{ base: "sm", md: "md" }} style={{ display: "flex" }}>
          <CardBody key={filteredFirmware[0]?.length}>
            <Flex justifyContent={"space-between"}>
              <Box width="50%">
                <Flex gap={2}>
                  <Heading {...styles.deviceHeader}>
                    {filteredFirmware[0]?.name}
                  </Heading>
                </Flex>
                <Flex {...styles.subheaderItems}>
                  <BiCode boxsize={1} />
                  <Text {...styles.subheaderTextleft}>Code Name</Text>
                  <Text {...styles.subheaderText}>
                    : {filteredFirmware[0]?.code_name}
                  </Text>
                </Flex>
                <Flex {...styles.subheaderItems}>
                  <BiVial boxsize={1} />
                  <Text {...styles.subheaderTextleft}>Version Number</Text>
                  <Text {...styles.subheaderText}>
                    : {filteredFirmware[0]?.version_number}
                  </Text>
                </Flex>
                <Flex {...styles.subheaderItems}>
                  <BiMemoryCard boxsize={1} />
                  <Text {...styles.subheaderTextleft}>Model</Text>
                  <Text {...styles.subheaderText}>
                    : {filteredFirmware[0]?.device_model?.name}
                  </Text>
                </Flex>
                <Flex {...styles.subheaderItems}>
                  <BiDevices boxsize={1} />
                  <Text {...styles.subheaderTextleft}>Product Type</Text>
                  <Text {...styles.subheaderText}>
                    : {filteredFirmware[0]?.device_type?.name}
                  </Text>
                </Flex>

                <Flex {...styles.subheaderItems}>
                  <BiSelectMultiple boxsize={1} />
                  <Text {...styles.subheaderTextleft}>Devices </Text>
                  <Text {...styles.subheaderText}>
                    : {filteredFirmware[0]?.device_model?.number_of_devices}{" "}
                  </Text>
                </Flex>

                <Flex {...styles.subheaderItems}>
                  <BiCodeBlock boxsize={1} />
                  <Text {...styles.subheaderTextleft}>Filename</Text>
                  <Text {...styles.subheaderText}>
                    :{" "}
                    <a href={filteredFirmware[0]?.file_path} target="_blank">
                      {" "}
                      <DownloadIcon> </DownloadIcon>Download File{" "}
                    </a>
                  </Text>
                </Flex>
              </Box>

              <Flex
                flexDirection={"col"}
                fill={"100%"}
                justifyContent={"flex-end"}
                alignSelf={"center"}
                display={"flex"}
              >
                <Box display="flex" flexDirection="column" gap={2}>
                  <Button
                    variant="outline"
                    width="10rem"
                    colorScheme="black"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleModifyClick();
                    }}
                  >
                    Modify
                  </Button>
                  <Button
                    width="10rem"
                    variant="outline"
                    colorScheme="red"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteModalOpen();
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      )}

      {isEditModelOpen && filteredFirmware?.length && (
        <EditFirmwareModal
          isOpen={isEditModelOpen}
          onClose={handleEditModelCloseModal}
          setIsModalOpen={setIsEditModelOpen}
          selectedFirmware={filteredFirmware[0]}
        />
      )}

      {isDeleteModalOpen && filteredFirmware?.length && (
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={filteredFirmware[0]}
          onSave={onSaveDeleteModel}
          type={deletionType}
        />
      )}
      {/* {isSuccessModalOpen && filteredFirmware?.length && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`${successMessage}!`}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )} */}
    </>
  );
}

export default FirmwareDetails;
