import React, { useState } from "react";
import {
  Button,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import AddTeamMemberModal from "./addTeamMemberModal";
import { useDispatch, useSelector } from "react-redux";
import {
  approveMemberAPI,
  approveSellerMemberAPI,
  deleteMemberAPI,
  deleteSellerMemberAPI,
  editMemberAPI,
  editSellerMemberAPI,
  getMemberAPI,
  getSellerMemberAPI,
} from "../redux/helpers/teamAPI";
import SuccessMessageModal from "./successMessageModal";
import Loader from "./loader";
import {
  TriangleUpIcon,
  TriangleDownIcon,
  CheckIcon,
  CloseIcon,
} from "@chakra-ui/icons";

export default function TeamMembersTable({ enableActions, teamMembers }) {
  const dispatch = useDispatch();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  // const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "first_name",
    direction: "ascending",
  });
  const user_type = useSelector((state) => state?.auth?.user_type);

  const handleMemberApprove = (teamMemberPayload) => {
    setLoading(true);
    const payload = {
      member_id: teamMemberPayload?.id,
    };
    let removeMemberRes = user_type === "Manufacturer" ? dispatch(approveMemberAPI(payload)): dispatch(approveSellerMemberAPI(payload))
    removeMemberRes.then((data) => {
      if (data?.payload?.success) {
        setLoading(false);
        const getMemberRes = user_type === "Manufacturer" ? dispatch(getMemberAPI()) :dispatch(getSellerMemberAPI())
        getMemberRes.then((mem_list) => {
          if (mem_list?.payload?.success) {
            toast({
              title: "Success",
              position: "top-right",
              description: "Successfully Approved the member",
              status: "success",
              duration: 6000,
              isClosable: true,
            });
          }
        });
      } else {
        setLoading(false);
      }
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
  };

  const handleMemberReject = (teamMemberPayload) => {
    setLoading(true);
    const payload = {
      member_id: teamMemberPayload?.id,
    };
    let removeMemberRes = user_type === "Manufacturer" ? dispatch(deleteMemberAPI(payload)): dispatch(deleteSellerMemberAPI(payload))
    removeMemberRes.then((data) => {
      if (data?.payload?.success) {
        setLoading(false);
        const getMemberRes = user_type === "Manufacturer" ? dispatch(getMemberAPI()) :dispatch(getSellerMemberAPI())
        getMemberRes.then((mem_list) => {
          if (mem_list?.payload?.success) {
            toast({
              title: "Success",
              position: "top-right",
              description: "Successfully deactivated the member",
              status: "success",
              duration: 6000,
              isClosable: true,
            });
          }
        });
      } else {
        setLoading(false);
      }
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTeamMember(null);
  };

  const handleModifyClick = (teamMember) => {
    console.log("teamMember", teamMember, enableActions);

    if (enableActions === false) {
      alert("Owner of the account can modify team members");
    } else {
      // alert ('not handled ')
      setSelectedTeamMember(teamMember);
      setIsModalOpen(true);
    }
  };

  const onSave = async (payload) => {
    setLoading(true);
    try {
      payload.member_id = selectedTeamMember?.id;

      const editMemberReq =
        user_type === "Manufacturer"
          ? dispatch(editMemberAPI(payload))
          : dispatch(editSellerMemberAPI(payload));
      editMemberReq
        .then((result) => {
          const { success, message } = result?.payload;
          if (success) {
            const getmemberReq =
              user_type === "Manufacturer"
                ? dispatch(getMemberAPI())
                : dispatch(getSellerMemberAPI());
            getmemberReq.then(() => {
              handleCloseModal();
              // setisSuccessModalOpen(true);
              setLoading(false);
              toast({
                title: "Success",
                position: "top-right",
                description: message,
                status: "success",
                duration: 6000,
                isClosable: true,
              });
            });
          } else {
            console.log(result?.payload?.error);
            setLoading(false);
            toast({
              title: "Error",
              description:
                message || result?.payload?.error || "Something went wrong!",
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "60vh",
    },
    teamMemberName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
      width: "100%",
    },
  };

  // Sorting handler
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Sorting function
  const sortedteamMembers = React.useMemo(() => {
    if (sortConfig.key) {
      return teamMembers?.slice().sort((a, b) => {
        const aValue = a?.[sortConfig.key]?.name || a?.[sortConfig.key];
        const bValue = b?.[sortConfig.key]?.name || b?.[sortConfig.key];
        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return teamMembers;
  }, [teamMembers, sortConfig]);

  // Helper function to render sort icon
  const renderSortIcon = (key) => {
    const isActive = sortConfig.key === key;
    const iconColor = isActive ? "blue.500" : "gray.400";
    const icon =
      sortConfig.direction === "ascending" ? (
        <TriangleUpIcon ml={1} color={iconColor} />
      ) : (
        <TriangleDownIcon ml={1} color={iconColor} />
      );
    return isActive ? icon : <TriangleUpIcon ml={1} color="gray.400" />;
  };

  return (
    <>
      <Loader loading={loading} />
      <TableContainer {...styles.tableContainer}>
        <Table size={"sm"} variant="striped" colorScheme="blackAlpha">
          <Thead>
            <Tr>
              <Th width="30%" onClick={() => handleSort("first_name")}>
                FIRST NAME {renderSortIcon("first_name")}
              </Th>
              <Th width="30%" onClick={() => handleSort("last_name")}>
                LAST NAME {renderSortIcon("last_name")}
              </Th>
              <Th width="20%" onClick={() => handleSort("email")}>
                EMAIL {renderSortIcon("email")}
              </Th>
              <Th width="20%" onClick={() => handleSort("phone_number")}>
                PHONE {renderSortIcon("phone_number")}
              </Th>
              <Th width="15%" onClick={() => handleSort("role")}>
                PRIVILEGE {renderSortIcon("role")}
              </Th>
              <Th width="15%" onClick={() => handleSort("status")}>
                STATUS {renderSortIcon("status")}
              </Th>
              <Th width="15%" visibility={enableActions}>
                ACTIONS
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedteamMembers?.map((teamMember, index) => (
              <Tr key={index}>
                <Td {...styles.teamMemberName}>{teamMember?.first_name}</Td>
                <Td {...styles.teamMemberName}>{teamMember?.last_name}</Td>
                <Td>{teamMember?.email}</Td>
                <Td>{teamMember?.phone_number}</Td>
                <Td>{teamMember?.role?.name} </Td>
                <Td>{teamMember?.status}</Td>
                <Td>
                  {teamMember?.is_approved === false ? (
                    <Stack direction="row" spacing={4}>
                      <Button
                        leftIcon={<CheckIcon />}
                        colorScheme="teal"
                        variant="outline"
                        size="sm"
                        disabled={enableActions}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (enableActions === false) {
                            alert("Owner of the account can modify team members");
                          } else{
                            handleMemberApprove(teamMember)
                          }
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        rightIcon={<CloseIcon />}
                        colorScheme="red"
                        variant="outline"
                        size="sm"
                        disabled={enableActions}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (enableActions === false) {
                            alert("Owner of the account can modify team members");
                          } else{
                            handleMemberReject(teamMember);
                          }
                        }}
                      >
                        Reject
                      </Button>
                    </Stack>
                  ) : (
                    <Button
                      {...styles.actionButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleModifyClick(teamMember);
                      }}
                      disabled={enableActions}
                      // display={enableActions ? 'flex': 'none'}
                    >
                      Modify
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {isModalOpen && (
          <AddTeamMemberModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            setIsModalOpen={setIsModalOpen}
            onSave={onSave}
            teamMember={selectedTeamMember}
            type={`Edit "${selectedTeamMember?.first_name} ${selectedTeamMember?.last_name}"`}
          />
        )}
        {/* {isSuccessModalOpen && (
          <SuccessMessageModal
            isOpen={isSuccessModalOpen}
            message={"Team member details updated successfully!"}
            onClose={() => {
              setisSuccessModalOpen(false);
              window.location.reload();
            }}
          />
        )} */}
      </TableContainer>
    </>
  );
}

TeamMembersTable.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  enableActions: PropTypes.array.isRequired,
  refreshTeamMembers: PropTypes.func,
};
