import axios from "axios";

const API = axios.create({
  baseURL: "https://backends-wintergardenz.alamanceinc.com/v1/api",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});

export default API;

// //

// manufacturer@gmail.com
// User@123
