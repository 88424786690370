import {
  Box,
  Button,
  Grid,
  GridItem,
  Image,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Congratulations from "../assets/images/congratulations.jpg";
import {
  addModelAPI,
  addTypeAPI,
  getModelsAPI,
  getTypesAPI,
} from "../redux/helpers/controllerAPI";
import {
  addOrganizationAPI,
  getCustomerAPI,
} from "../redux/helpers/customerAPI";
import { getFirmwareListAPI } from "../redux/helpers/firmwareAPI";
import { addMemberAPI, getMemberAPI } from "../redux/helpers/teamAPI";
import { AddCustomerModal } from "./addCustomerModal";
import AddDeviceModal from "./addDeviceModal";
import AddDeviceModelModal from "./addDeviceModelModal";
import AddDeviceTypeModal from "./addDeviceTypeModal";
import { AddFirmwareModal } from "./addFirmwareModal";
import AddTeamMemberModal from "./addTeamMemberModal";
import SuccessMessageModal from "./successMessageModal";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

// import * as Yup from "yup";
// import { clearConfigCache } from "prettier";

const Onboardingmodal = ({ isOpen, onclose }) => {
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [isDevModelOpen, setIsDevModelOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isDevTypeModalOpen, setIsDevTypeModalOpen] = useState(false);
  const [isDevFirmwareOpen, setIsDevFirmwareOpen] = useState(false);
  const user = useSelector((state) => state?.auth?.userProfile);
  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  //   setIsOpenedForModify(false);
  //   reloadDataForAccount();
  // };

  const handleCloseCustomerModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseDeviceModal = () => {
    setIsDeviceModalOpen(false);
  };

  const handleDevModelCloseModal = () => {
    setIsDevModelOpen(false);
  };

  const handleDevTypeCloseModal = () => {
    setIsDevTypeModalOpen(false);
  };

  const handleCloseDevFirwareModal = () => {
    setIsDevFirmwareOpen(false);
  };

  const dispatch = useDispatch();

  const handleCloseTeamModal = () => {
    setIsTeamModalOpen(false);
  };

  const refreshTeamMembers = () => {
    dispatch(getMemberAPI());
  };

  const onSaveDevModel = (payload) => {
    payload = { ...payload };
    console.log("onSaveDevModel payload", payload);
    dispatch(addModelAPI(payload));
    setSuccessMessage("Model Added successfully");
    dispatch(getModelsAPI());
    setisSuccessModalOpen(true);
    setIsDevModelOpen(false);
  };

  const onSaveDevType = (payload) => {
    payload = { ...payload };
    console.log("onSaveDevType payload", payload);
    setSuccessMessage("Device Type Added successfully");
    dispatch(addTypeAPI(payload));
    dispatch(getTypesAPI());
    setisSuccessModalOpen(true);
    setIsDevTypeModalOpen(false);
  };

  const onSaveFirmware = () => {
    dispatch(getFirmwareListAPI());
    setIsDevFirmwareOpen(false);
  };

  const refreshCustomers = () => {
    dispatch(getCustomerAPI());
  };

  const onSave = async (payload) => {
    console.log("onSave payload", payload);
    payload.role_id = payload.privilege;

    const addCustomerReq = dispatch(addOrganizationAPI(payload));
    addCustomerReq
      .then((result) => {
        console.log(result);

        if (result?.payload?.success) {
          refreshCustomers();
          handleCloseCustomerModal();
          toast({
            title: "Customer added successfully!",
            description: result?.payload?.message,
            status: "success",
            duration: 6000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: result?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSaveTeam = async (payload) => {
    console.log("onSave payload", payload);

    const addMemberReq = dispatch(addMemberAPI(payload));
    addMemberReq
      .then((result) => {
        console.log(result);

        if (result?.payload?.success) {
          refreshTeamMembers();
          handleCloseTeamModal();
          toast({
            title: "Team Member added successfully!",
            description: result?.payload?.message,
            status: "success",
            duration: 6000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: result?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDevTypeOpen = () => {
    setIsDevTypeModalOpen(true);
  };

  const handleDevfirmwareOpen = () => {
    setIsDevFirmwareOpen(true);
  };

  const handleDevModelOpen = () => {
    setIsDevModelOpen(true);
  };

  const styles = {
    sideimage: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      // bgImage: Congratulations,
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    sideblank: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    main: {
      justifyContent: "center",
      alignItems: "center",
      w: "80%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    row: {
      marginY: "5%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    listrow: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "row",
    },
    listtext: {
      w: "60%",
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "facebook",
    },
    listbutton: {
      w: "40%",
    },
  };

  const navigate = useNavigate();

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onclose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <div style={{ alignItems: "center" }}>
            <ModalHeader pb={0}>Complete Your Account Setup</ModalHeader>
            <ModalCloseButton
              size="sm"
              px={8}
              fontSize={12}
              fontWeight={"500"}
              borderColor={"#385898"}
              border={"2px solid"}
            >
              Skip
            </ModalCloseButton>
          </div>
          <ModalBody className="my-element" mt={-5} pt={0}>
            <Grid
              {...styles.row}
              // templateAreas={`"nav main main"`}
              // gridTemplateRows={"50px 1fr 30px"}
              // gridTemplateColumns={"100px 1fr"}
              // h="200px"
              gap="2"
            >
              <GridItem {...styles.sideimage}>
                <Image
                  src={Congratulations}
                  // style={{ transform: scaleX(-1) }}
                />
              </GridItem>
              <GridItem {...styles.main}>
                <Text>
                  Hello {" " }
                  {user?.first_name
                    ? user?.first_name + " " + user?.last_name
                    : ""}
                  !
                </Text>
                <Text mt={2}>
                  We're thrilled to have you on board! Manage your IoT enabled sensors from single one place, with ease. 
                </Text>
              </GridItem>
              <GridItem {...styles.sideimage}>
                <Image src={Congratulations} />
              </GridItem>
            </Grid>
            <Grid
              {...styles.row}
              // templateAreas={`"nav main main"`}
              // gridTemplateRows={"50px 1fr 30px"}
              // gridTemplateColumns={"100px 1fr"}
              // h="200px"
              gap="1"
            >
              <GridItem {...styles.sideblank} />
              <GridItem {...styles.main}>
                <Text>Few things you can do on this platform are </Text>
                <Text style={{ marginTop: "20px" }}>
                  <UnorderedList>
                    <Grid {...styles.listrow} gap="1">
                      <GridItem {...styles.listtext}>
                        <ListItem>Configure Devices you plan to sell</ListItem>
                      </GridItem>
                      <GridItem {...styles.listbutton}>
                        {/* <AddDeviceModal
                          isOpen={isDeviceModalOpen}
                          onClose={handleCloseDeviceModal}
                          setIsModalOpen={setIsDeviceModalOpen}
                          handleDevTypeOpen={handleDevTypeOpen}
                          handleDevModelOpen={handleDevModelOpen}
                          handleDevfirmwareOpen={handleDevfirmwareOpen}
                        /> */}
                        <Box style={{ marginInline: 8 }}>
                          <Button
                            {...styles.button}
                            rightIcon={<AddIcon />}
                            onClick={() => navigate("/product-configuration")}
                          >
                            Configure
                          </Button>
                        </Box>
                        {isDevTypeModalOpen && (
                          <AddDeviceTypeModal
                            isOpen={isDevTypeModalOpen}
                            onClose={handleDevTypeCloseModal}
                            setIsModalOpen={setIsDevTypeModalOpen}
                            onSave={onSaveDevType}
                          />
                        )}
                        {isDevModelOpen && (
                          <AddDeviceModelModal
                            isOpen={isDevModelOpen}
                            onClose={handleDevModelCloseModal}
                            setIsModalOpen={setIsDevModelOpen}
                            onSave={onSaveDevModel}
                          />
                        )}
                        {isSuccessModalOpen && (
                          <SuccessMessageModal
                            isOpen={isSuccessModalOpen}
                            message={`${successMessage}!`}
                            onClose={() => {
                              setisSuccessModalOpen(false);
                              // reloadDataForAccount();
                            }}
                          />
                        )}
                        {isDevFirmwareOpen && (
                          <AddFirmwareModal
                            isOpen={isDevFirmwareOpen}
                            onClose={handleCloseDevFirwareModal}
                            setIsModalOpen={setIsDevFirmwareOpen}
                            onSaveFirmware={onSaveFirmware}
                          />
                        )}
                      </GridItem>
                    </Grid>
                    <Grid {...styles.listrow} gap="1">
                      <GridItem {...styles.listtext}>
                        <ListItem>Manage Customer Accounts</ListItem>
                      </GridItem>
                      <GridItem {...styles.listbutton}>
                        {/* <AddCustomerModal
                          isOpen={isModalOpen}
                          onClose={handleCloseCustomerModal}
                          setIsModalOpen={setIsModalOpen}
                          onSave={onSave}
                          type={"Customer"}
                        /> */}
                        <Box style={{ marginInline: 8 }}>
                          <Button
                            {...styles.button}
                            rightIcon={<AddIcon />}
                            onClick={() =>
                              navigate("/customers", {
                                state: { isOpen: true },
                              })
                            }
                          >
                            Add Customer
                          </Button>
                        </Box>
                      </GridItem>
                    </Grid>
                    <Grid {...styles.listrow} gap="1">
                      <GridItem {...styles.listtext}>
                        <ListItem>Collaborate with your team</ListItem>
                      </GridItem>
                      <GridItem {...styles.listbutton}>
                        {/* <AddTeamMemberModal
                          isOpen={isTeamModalOpen}
                          onClose={handleCloseTeamModal}
                          setIsModalOpen={setIsTeamModalOpen}
                          onSave={onSaveTeam}
                          // teamMember={selectedTeamMember}
                          type={"Team Member"}
                        /> */}
                        <Box style={{ marginInline: 8 }}>
                          <Button
                            {...styles.button}
                            rightIcon={<AddIcon />}
                            onClick={() =>
                              navigate("/team", {
                                state: { isOpen: true },
                              })
                            }
                          >
                            Add Team
                          </Button>
                        </Box>
                      </GridItem>
                    </Grid>
                  </UnorderedList>
                </Text>
              </GridItem>
              <GridItem {...styles.sideblank} />
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

Onboardingmodal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
};
export default Onboardingmodal;
