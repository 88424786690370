import React, { useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Heading,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import ModelFooterButtons from "./modelFooterButtons";
import ModelsTriggerButton from "./modelsTriggerButton";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-number-input/style.css";

export const AddCustomerModal = ({
  isOpen,
  onClose,
  setIsModalOpen,
  onSave,
}) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const nameRegex = /^[a-zA-Z1-9\s]+$/;
  const AddCustomerValidationSchema = Yup.object().shape({
    organization_name: Yup.string()
      .matches(
        nameRegex,
        "Organization Name must contain only numbers, letters and spaces"
      )
      .required("Organization Name is required")
      .max(36, "Organization Name must be at most 36 characters long"),
    organization_address: Yup.string().required("Location/Address is required"),
    postal_code: Yup.string()
      .matches(/^\d+$/, "Postal Code only contains numbers") // Corrected regex to match one or more digits
      .required("Postal Code is required")
      .max(6, "Postal Code must be at most 6 characters long"),
    org_contact_person_first_name: Yup.string()
      .matches(
        nameRegex,
        "First Name must contain only numbers, letters and spaces"
      )
      .required("First Name is required")
      .max(36, "First Name must be at most 36 characters long"),
    org_contact_person_last_name: Yup.string()
      .matches(
        nameRegex,
        "Last Name must contain only numbers, letters and spaces"
      )
      .required("Last Name is required")
      .max(36, "Last Name must be at most 36 characters long"),
    org_contact_person_email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(255, "Email must be at most 255 characters.")
      .required("Email is required"),
    org_contact_person_phone_number: Yup.string()
      // .matches(
      //   /^\+[0-9]{1,3}-[0-9]{4}-[0-9]{3}$/,
      //   "Enter valid Phone Number (e.g., +81-1223-333)"
      // )
      .required("Phone Number is required"),
  });

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };
  return (
    <>
      <ModelsTriggerButton setIsModalOpen={setIsModalOpen} type="Customer" />

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Customer</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                email: "",
                first_name: "",
                last_name: "",
                phone_number: "",
                privilege: "",
                organization_name: "",
                postal_code: "",
                organization_address: "",
                org_contact_person_first_name: "",
                org_contact_person_last_name: "",
                org_contact_person_email: "",
                org_contact_person_phone_number: "",
              }}
              validationSchema={AddCustomerValidationSchema}
              onSubmit={(values) => {
                console.log(values);
                onSave(values);
              }}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* <Box
                    style={{
                      marginBottom: 22,
                      padding: 4,
                      marginTop: 4,
                      border: "1px dotted lightgrey",
                      borderRadius: 8,
                    }}
                  > */}
                  <FormControl
                    isInvalid={
                      errors.organization_name && touched.organization_name
                    }
                  >
                    <FormLabel>Organization Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="organization_name"
                      name="organization_name"
                      type="text"
                      placeholder="XYZ Company"
                    />
                    <FormErrorMessage>
                      {errors?.organization_name}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      errors.org_contact_person_first_name &&
                      touched.org_contact_person_first_name
                    }
                  >
                    <FormLabel mt={4}>Primary Contact First Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="org_contact_person_first_name"
                      name="org_contact_person_first_name"
                      type="text"
                      placeholder="michal"
                    />
                    <FormErrorMessage>
                      {errors?.org_contact_person_first_name}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={
                      errors.org_contact_person_last_name &&
                      touched.org_contact_person_last_name
                    }
                  >
                    <FormLabel mt={4}>Primary Contact Last Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="org_contact_person_last_name"
                      name="org_contact_person_last_name"
                      type="text"
                      placeholder="jordan"
                    />
                    <FormErrorMessage>
                      {errors?.org_contact_person_last_name}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={
                      errors.org_contact_person_phone_number &&
                      touched.org_contact_person_phone_number
                    }
                  >
                    <FormLabel mt={4}>Phone Number</FormLabel>
                    {/* <Field
                      as={Input}
                      {...styles.input}
                      id="org_contact_person_phone_number"
                      name="org_contact_person_phone_number"
                      type="tel"
                      placeholder="+81-1223-333"
                    /> */}
                    <PhoneInput
                      style={{
                        borderWidth: 1,
                        borderRadius: 5,
                        height: "30px",
                        paddingLeft: 8,
                      }}
                      placeholder="275 555 76"
                      defaultCountry="NZ"
                      value={formatPhoneNumberIntl(
                        values?.org_contact_person_phone_number
                      )}
                      onChange={(phone) => {
                        setFieldValue("org_contact_person_phone_number", phone);
                      }}
                    />
                    <FormErrorMessage>
                      {errors?.org_contact_person_phone_number}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={
                      errors.org_contact_person_email &&
                      touched.org_contact_person_email
                    }
                  >
                    <FormLabel mt={4}>Email</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="org_contact_person_email"
                      name="org_contact_person_email"
                      type="email"
                      placeholder="michel@example.com"
                    />
                    <FormErrorMessage>
                      {errors?.org_contact_person_email}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors.organization_address &&
                      touched.organization_address
                    }
                  >
                    <FormLabel mt={4}>Location / Address</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="organization_address"
                      name="organization_address"
                      type="text"
                      placeholder="150 Lumsden Place, Waipatu, Hastings"
                    />
                    <FormErrorMessage>
                      {errors?.organization_address}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors.postal_code && touched.postal_code}
                  >
                    <FormLabel mt={4}>Postal Code</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="postal_code"
                      name="postal_code"
                      type="number"
                      placeholder="4122"
                      onChange={(e) => {
                        setFieldValue("postal_code", String(e.target.value));
                      }}
                    />
                    <FormErrorMessage>{errors?.postal_code}</FormErrorMessage>
                  </FormControl>
                  {/* </Box> */}

                  {/* <FormControl
                    {...styles.formItem}
                    isInvalid={errors.privilege && touched.privilege}
                  >
                    <FormLabel>Privilege</FormLabel>
                    <Select
                      {...styles.input}
                      placeholder="Select option"
                      name="privilege"
                      value={values.privilege}
                      onChange={(e) => {
                        handleChange(e.target.value);
                        console.log("e.target.value", e.target.value);
                        setFieldValue("privilege", e.target.value);
                      }}
                    >
                      {roles.map((role) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{errors?.privilege}</FormErrorMessage>
                  </FormControl> */}

                  <ModelFooterButtons onClose={onClose} onSave={handleSubmit} />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

AddCustomerModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
