import { DeleteIcon, TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function FirmwareTable({
  data,
  handleModalOpen,
  handleDeleteModalOpen,
}) {
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });

  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "60vh",
    },
    firmwareName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      width: "70%",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
    },
  };

  const handleRowClick = (selectedFirmware) => {
    navigate("/firmware/details", { state: { selectedFirmware } });
  };

  // Sorting handler
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Sorting function
  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      console.log(sortConfig,'SORTCONFIG KEY')
      if (sortConfig.key==='name'){
        return sortConfig.direction === 'ascending'? data?.slice()?.sort((a, b) => a?.name?.localeCompare(b?.name)): data?.slice()?.sort((a, b) => b?.name?.localeCompare(a?.name))
      }
      else {
        return data.slice().sort((a, b) => {
          const aValue = a?.[sortConfig.key]?.name || a?.[sortConfig.key];
          const bValue = b?.[sortConfig.key]?.name || b?.[sortConfig.key];
          if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
    }
    return data;
  }, [data, sortConfig]);

  // Helper function to render sort icon
  const renderSortIcon = (key) => {
    const isActive = sortConfig.key === key;
    const iconColor = isActive ? "blue.500" : "gray.400";
    const icon = sortConfig.direction === 'ascending' ? (
      <TriangleUpIcon ml={1} color={iconColor} />
    ) : (
      <TriangleDownIcon ml={1} color={iconColor} />
    );
    return isActive ? icon : <TriangleUpIcon ml={1} color="gray.400" />
  };

  return (
    <>
      <TableContainer {...styles.tableContainer}>
        <Table size={"sm"} variant="striped" colorScheme="blackAlpha">
          <Thead>
            <Tr>
              <Th onClick={() => handleSort('name')} width={"20%"}>
                NAME {renderSortIcon('name')}
              </Th>
              <Th onClick={() => handleSort('version_number')} >
                VERSION NUMBER {renderSortIcon('version_number')}
              </Th>
              <Th onClick={() => handleSort('release_date')} >
                RELEASE DATE {renderSortIcon('release_date')}
              </Th>
              <Th onClick={() => handleSort('device_type')} >
                Product {renderSortIcon('device_type')}
              </Th>
              <Th onClick={() => handleSort('device_model')} >
                MODEL {renderSortIcon('device_model')}
              </Th>
              {/* <Th width={"10%"}>ACTIONs</Th> */}
            </Tr>
          </Thead>
          <Tbody cursor="pointer">
            {sortedData?.map((firmware, index) => (
                <Tr key={index} onClick={() => handleRowClick(firmware)}>
                  <Td>
                    <Text {...styles.firmwareName}>{firmware?.name}</Text>
                  </Td>
                  <Td>{firmware?.version_number}</Td>

                  <Td>
                    {moment(firmware?.release_date).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </Td>
                  <Td>
                    <Text>{firmware?.device_type?.name}</Text>
                  </Td>
                  <Td>
                    <Text>{firmware?.device_model?.name}</Text>
                  </Td>

                  {/* <Td>
                    <Button
                      {...styles.actionButton}
                      onClick={() => {
                        handleModalOpen(firmware);
                      }}
                    >
                      Modify
                    </Button>
                    <DeleteIcon
                      cursor="pointer"
                      w={8}
                      h={8}
                      mx={3}
                      color="red"
                      boxSize={6}
                      onClick={() => {
                        handleDeleteModalOpen(firmware);
                      }}
                    />
                  </Td> */}
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

FirmwareTable.propTypes = {
  data: PropTypes.array.isRequired,
  handleModalOpen: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func,
};
