import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./loader";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { getUnitsAPI } from "../redux/helpers/controllerAPI";

export default function UnitsTable({}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const units = useSelector((state) => state?.controllers?.units);

  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "60vh",
    },
    unitName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    measurementName: {
      fontSize: 12,
      fontWeight: 500,
    },
    searchInput: {
      mb: 4,
    },
  };

  // Sorting handler
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig?.key === key && sortConfig?.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Compare function to use in sorting
  const compare = (valueA, valueB) => {
    if (valueA < valueB) return sortConfig?.direction === "ascending" ? -1 : 1;
    if (valueA > valueB) return sortConfig?.direction === "ascending" ? 1 : -1;
    return 0;
  };

  // Filter and sort units
  const filteredAndSortedUnits = React.useMemo(() => {
    const lowerCaseSearchQuery = searchQuery?.toLowerCase();
    const filteredData = units
      .map((unit) => ({
        ...unit,
        measurements: [...unit?.measurements],
      }))
      .filter(
        (unit) =>
          unit?.name?.toLowerCase().includes(lowerCaseSearchQuery) ||
          unit?.measurements?.some((measurement) =>
            measurement?.name?.toLowerCase().includes(lowerCaseSearchQuery)
          )
      );

    if (sortConfig?.key) {
      filteredData?.sort((a, b) => {
        // Handle sorting by category name
        if (sortConfig?.key === "name") {
          return compare(a?.name, b?.name);
        }

        return 0;
      });

      // Sort individual measurements within each unit category
      filteredData?.forEach((unit) => {
        unit?.measurements?.sort((a, b) => {
          if (sortConfig?.key === "measurementName") {
            return compare(a?.name, b?.name);
          }
          if (sortConfig?.key === "unit") {
            return compare(a?.unit, b?.unit);
          }
          return 0;
        });
      });
    }

    return filteredData;
  }, [units, sortConfig, searchQuery]);

  // Helper function to render sort icon
  const renderSortIcon = (key) => {
    const isActive = sortConfig?.key === key;
    const iconColor = isActive ? "blue.500" : "gray.400";
    const icon =
      sortConfig?.direction === "ascending" ? (
        <TriangleUpIcon ml={1} color={iconColor} />
      ) : (
        <TriangleDownIcon ml={1} color={iconColor} />
      );
    return isActive ? icon : <TriangleUpIcon ml={1} color="gray.400" />;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getUnitsAPI());
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <Input
        placeholder="Search"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e?.target?.value)}
        {...styles.searchInput}
      />
      {filteredAndSortedUnits?.length > 0 ? (
        <TableContainer {...styles.tableContainer}>
          <Table
            size={{ base: "sm", md: "md" }}
            variant="striped"
            colorScheme="blackAlpha"
          >
            <Thead>
              <Tr>
                <Th onClick={() => handleSort("name")}>
                  Measurement {renderSortIcon("name")}
                </Th>
                <Th onClick={() => handleSort("measurementName")}>
                  Unit Name {renderSortIcon("measurementName")}
                </Th>
                <Th onClick={() => handleSort("unit")}>
                  Unit Abbreviation {renderSortIcon("unit")}
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {filteredAndSortedUnits?.map((unit, index) => (
                <React.Fragment key={index}>
                  <Tr>
                    <Td {...styles.unitName} rowSpan={unit?.measurements?.length}>
                      {unit?.name}
                    </Td>
                    <Td {...styles.measurementName}>
                      {unit?.measurements[0]?.name}
                    </Td>
                    <Td>{unit?.measurements[0]?.unit}</Td>
                  </Tr>
                  {unit?.measurements?.slice(1).map((measurement, subIndex) => (
                    <Tr key={subIndex}>
                      <Td {...styles.measurementName}>{measurement?.name}</Td>
                      <Td>{measurement?.unit}</Td>
                    </Tr>
                  ))}
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text align={"center"} p={4}>
          No units found
        </Text>
      )}
    </>
  );
}
