import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import ControllerModels from "../components/controllerModels";
import ControllerTypes from "../components/controllerTypes";
import { getModelsAPI, getTypesAPI } from "../redux/helpers/controllerAPI";
import { useDispatch } from "react-redux";
import UnitsTable from "../components/unitsTable";

const ProductConfiguration = () => {
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  // const tourindex = location.state?.tourindex || 0;
  const [tabIndex, setTabIndex] = useState(
    location?.state?.tourindex ? location.state?.tourindex : 0
  );
  const dispatch = useDispatch();
  // setTabIndex(location.state?.tourindex || 0);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const navigate = useNavigate();
  const styles = {
    flexContainer: {
      gap: 4,
      flexDirection: "column",
    },
    graphBox: {
      p: 4,
      boxShadow: "md",
      bgColor: "white",
      borderRadius: 6,
    },
    tabHeading: {
      fontWeight: "bold",
    },
    tabindecater: {
      mt: "-1.5px",
      height: "2px",
      bg: "blue.500",
      borderRadius: "1px",
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "facebook",
    },
    bredcrumbsContainer: {
      mb: 6,
      spacing: "8px",
      cursor: isHovered ? "pointer" : "default",
      textDecoration: isHovered ? "underline" : "default",
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getModelsAPI());
        await dispatch(getTypesAPI());
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  return (
    <Box>
      <Flex direction={"row"} justifyContent={"space-between"}>
        {/* <Breadcrumbs /> */}
        <Text
          {...styles.bredcrumbsContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Measurements & Product Configuration
        </Text>
        {tabIndex === 1 ? (
          <Box style={{ marginInline: 8 }}>
            <Button
              tour-guide="step-8"
              {...styles.button}
              rightIcon={<AddIcon />}
              onClick={() => navigate("/add-type")}
            >
              Add Product
            </Button>
          </Box>
        ) : ( 

            <Box style={{ marginInline: 8 }}>
              <Button
                // tour-guide="step-8"
                {...styles.button}
                rightIcon={<AddIcon />}
                onClick={() => navigate("/add-units")}
              >
                Add Units
              </Button>
            </Box>
          // )
        )}
      </Flex>
      <Flex {...styles.flexContainer}>
        <Box {...styles.graphBox}>
          <Tabs
            position="relative"
            variant="enclosed"
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList>
              
              {/* <Tab {...styles.tabHeading} tour-guide="step-9">
                Models
              </Tab> */}
              <Tab {...styles.tabHeading} >
                Units & Measurments
              </Tab>
              <Tab {...styles.tabHeading} tour-guide="step-7">
                Products
              </Tab>
              
              {/* <Tab {...styles.tabHeading}>Billing</Tab>
          <Tab {...styles.tabHeading}>Tickets</Tab> */}
            </TabList>
            <TabIndicator {...styles.tabindecater} />
            <TabPanels index={tabIndex}>
              
            <TabPanel>
                <UnitsTable />
              </TabPanel><TabPanel>
                <ControllerTypes />
              </TabPanel>
              {/* <TabPanel>
                <ControllerModels />
              </TabPanel> */}
         
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Box>
  );
};

// ProductConfiguration.proptypes = {
//   tourindex: PropTypes?.number,
// };

export default ProductConfiguration;
