import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vendorsData: [
    {
      vendor: "MAKER SHOP",
      email: "makershop@gmail.com",
      phoneNumber: "8985598886",
      component: "Component 1",
    },
    {
      vendor: "TOOL SMITHS",
      email: "toolsmith@gmail.com",
      phoneNumber: "8985598886",
      component: "Component 2",
    },
    {
      vendor: "MAYHEM",
      email: "mayhem@gmail.com",
      phoneNumber: "8985598886",
      component: "Component 3",
    },
    {
      vendor: "ELECTROSHOP",
      email: "electroshop@gmail.com",
      phoneNumber: "8985598886",
      component: "Component 4",
    },
    {
      vendor: "VENDOR 5",
      email: "example@gmail.com",
      phoneNumber: "8985598886",
      component: "Component 5",
    },
    {
      vendor: "VENDOR 6",
      email: "example@gmail.com",
      phoneNumber: "8985598886",
      component: "Component 6",
    },
    {
      vendor: "VENDOR 7",
      email: "makershop@gmail.com",
      phoneNumber: "8985598886",
      component: "Component 7",
    },
  ],
};

export const vendorsSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    resetStateVendors: (state, action) => {
      state = initialState;
      return initialState;
    },
  },
  extraReducers: () => {},
});

export const { resetStateVendors } = vendorsSlice.actions;

export default vendorsSlice.reducer;
