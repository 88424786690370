import { Button, ModalFooter, Spacer, useToast } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMemberAPI, deleteSellerMemberAPI, getMemberAPI, getSellerMemberAPI } from "../redux/helpers/teamAPI";
import Loader from "./loader";

export default function ModelFooterButtons({
  onClose,
  onSave,
  type,
  data = null,
  disabled,
}) {
  // console.log(disabled);
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  // const token = useSelector((state) => state?.auth?.token);
  const user_type = useSelector((state) => state?.auth?.user_type);

  const removeMember = () => {
    setLoading(true);
    const payload = {
      member_id: data?.id,
    };
    let removeMemberRes = user_type === "Manufacturer" ? dispatch(deleteMemberAPI(payload)): dispatch(deleteSellerMemberAPI(payload))
    removeMemberRes
      .then((data) => {
        if (data?.payload?.success) {
          setLoading(false);
          onClose();
          const getMemberRes = user_type === "Manufacturer" ? dispatch(getMemberAPI()) :dispatch(getSellerMemberAPI())
          getMemberRes.then((mem_list) => {
            if (mem_list?.payload?.success) {
              toast({
                title: "Success",
                position: "top-right",
                description: "Successfully deactivated the member",
                status: "success",
                duration: 6000,
                isClosable: true,
              });
            }
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const styles = {
    cancelButton: {
      mr: 3,
      size: "sm",
      colorScheme: "red",
      variant: "outline",
    },
    submitButton: {
      colorScheme: "facebook",
      size: "sm",
    },
    removeButton: {
      colorScheme: "red",
      size: "sm",
    },
  };
  return (
    <ModalFooter
      style={{
        padding: 0,
        marginTop: 15,
        marginBottom: 15,
      }}
    >
      <Loader loading={loading} />
      {type?.includes("Edit") ? (
        <Button
          {...styles.removeButton}
          onClick={() => (data ? removeMember() : console.log(""))}
        >
          Deactivate
        </Button>
      ) : null}
      <Spacer />
      <Button onClick={onClose} {...styles.cancelButton}>
        Cancel
      </Button>
      <Button
        type="submit"
        isDisabled={disabled}
        onClick={onSave}
        {...styles.submitButton}
      >
        Submit
      </Button>
    </ModalFooter>
  );
}

ModelFooterButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.object,
};
