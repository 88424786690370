import { DeleteIcon, SettingsIcon, AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
// import { GoDotFill } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteTypesAPI,
  getModelsAPI,
  getTypesAPI,
  updateTypesAPI,
} from "../redux/helpers/controllerAPI";
import { setActiveType } from "../redux/reducers/controllersReducer";
import DeleteDeviceModal from "./deleteDeviceModal";
import EditDeviceTypeModal from "./editDeviceTypeModal";
import SuccessMessageModal from "./successMessageModal";
import Loader from "./loader";

const ControllerTypes = () => {
  const navigate = useNavigate();
  let types = useSelector((state) =>
    (state?.controllers?.types?.length
      ? [...state?.controllers?.types]
      : []
    ).sort((a, b) => a.id - b.id)
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [singleDeviceType, setSingleDeviceType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [isEditTypeModalOpen, setIsEditTypeModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const deletionType = "device-type";

  const dispatch = useDispatch();
  const toast = useToast();

  const handleTypeClick = (typeData) => {
    dispatch(setActiveType(typeData));
    navigate(`models`, {
      state: { typeData },
    });
  };

  useEffect(() => {
    dispatch(getTypesAPI());
  }, []);

  const handleEditTypeCloseModal = () => {
    setIsEditTypeModalOpen(false);
  };

  const handleEditTypeModalOpen = (device) => {
    setIsEditTypeModalOpen(true);
    setSingleDeviceType(device);
  };

  const handleDeleteModalOpen = (device) => {
    setIsDeleteModalOpen(true);
    setSingleDeviceType(device);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSaveEditTypeModel = (payload) => {
    setLoading(true);
    const data = Object.fromEntries(
      Object.entries(payload).filter(([, value]) => value !== "")
    );

    let editTypeRes = dispatch(updateTypesAPI(data));
    editTypeRes.then((data) => {
      if (data?.payload?.success) {
        dispatch(getTypesAPI())
          .then(() => {
            handleEditTypeCloseModal();
            setLoading(false);
            toast({
              title: "Success",
              position: "top-right",
              description: data?.payload?.message,
              status: "success",
              duration: 6000,
              isClosable: true,
            });
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            toast({
              title: "Error",
              description: "Something went wrong!",
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          });
      } else {
        setLoading(false);
        dispatch(getTypesAPI());
        toast({
          title: "Error",
          description: data?.payload?.message,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }
    });
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    let deleteTypesRes = dispatch(deleteTypesAPI(payload));
    deleteTypesRes
      .then((result) => {
        console.log(result);
        if (result?.payload?.success) {
          dispatch(getTypesAPI()).then(() => {
            handleCloseDeleteModal();
            setLoading(false);
            toast({
              title: "Success",
              position: "top-right",
              description: "Product Deleted successfully",
              status: "success",
              duration: 6000,
              isClosable: true,
            });
          });
        } else {
          setLoading(false);
          dispatch(getTypesAPI());
          toast({
            title: "Error",
            description: result?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // useEffect(() => {
  //   dispatch(getTypesAPI());
  // }, []);

  const styles = {
    cardTop: {
      display: "flex",
    },
    card: {
      w: "32%",
      cursor: "pointer",
    },
    cardHeading: {
      fontSize: 20,
      fontWeight: 600,
      py: "2",
    },
    align: {
      alignItems: "center",
      gap: 2,
      p: 2,
    },
    item: {
      p: 2,
      fontSize: 14,
      fontWeight: 400,
    },
    itemValue: {
      p: 2,
      fontSize: 14,
      fontWeight: 600,
    },
  };

  const showStartupMessage = () => {
    return (
      <Flex flexDirection={"column"}>
        <Text m={2}>Setup your account by adding products</Text>
        <Box style={{ marginInline: 8 }}>
          <Button
            {...styles.button}
            rightIcon={<AddIcon />}
            onClick={() => navigate("/add-type")}
          >
            Add Product
          </Button>
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Box>
        <Loader loading={loading} />

        {/* <Flex gap={2} pt={2} ml={3}>
          <Flex>
            <Center>
              <GoDotFill color={"green"} />
              Online
            </Center>
          </Flex>
          <Flex>
            <Center>
              <GoDotFill color={"red"} />
              Offline
            </Center>
          </Flex>
        </Flex> */}
        <Flex
          {...styles.cardTop}
          flexWrap="wrap"
          gap={4}
          align-items="flex-start"
        >
          {types?.map((val, index) => (
            <Card
              {...styles.card}
              key={index}
              onClick={() => handleTypeClick(val)}
            >
              <CardHeader
                py={0}
                bgColor="#EBEBEB"
                height={10}
                borderTopRadius="md"
              >
                <Flex
                  {...styles.cardHeader}
                  alignItems="center"
                  style={{
                    display: "flex",
                    height: "100%",
                  }}
                >
                  <Tooltip
                    hasArrow
                    placement="auto"
                    label={val?.name}
                    fontSize="md"
                  >
                    <Heading
                      {...styles.cardHeading}
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {val?.name}
                    </Heading>
                  </Tooltip>
                </Flex>
              </CardHeader>
              <CardBody px={4} py={2}>
                <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Devices</Heading>
                  <Heading {...styles.itemValue}>
                    {val?.number_of_devices}
                  </Heading>
                </Flex>
                <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Models</Heading>
                  <Heading {...styles.itemValue}>
                    {val?.number_of_models}
                  </Heading>
                </Flex>
                <Flex justifyContent="space-between" paddingY={2}>
                  <Button
                    size={"xs"}
                    colorScheme={"red"}
                    variant={"outline"}
                    isDisabled={val?.number_of_devices || val?.number_of_models}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteModalOpen(val);
                    }}
                  >
                    Delete
                    <DeleteIcon
                      w={4}
                      h={4}
                      colorScheme="red"
                      marginLeft={2}
                      boxSize={3}
                    />
                  </Button>
                  <Button
                    size={"xs"}
                    colorScheme="teal"
                    color="white"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditTypeModalOpen(val);
                    }}
                  >
                    Modify
                    <SettingsIcon
                      w={4}
                      h={4}
                      color="white"
                      marginLeft={2}
                      boxSize={3}
                    />
                  </Button>
                </Flex>
              </CardBody>
            </Card>
          ))}

          {types?.length === 0 ? showStartupMessage() : <> </>}
        </Flex>
        {isDeleteModalOpen && (
          <DeleteDeviceModal
            isOpen={isDeleteModalOpen}
            onclose={handleCloseDeleteModal}
            device={singleDeviceType}
            onSave={onSaveDeleteModel}
            type={deletionType}
          />
        )}

        {isEditTypeModalOpen && (
          <EditDeviceTypeModal
            isOpen={isEditTypeModalOpen}
            onClose={handleEditTypeCloseModal}
            setIsModalOpen={setIsEditTypeModalOpen}
            onSave={onSaveEditTypeModel}
            device={singleDeviceType}
          />
        )}

        {isSuccessModalOpen && (
          <SuccessMessageModal
            isOpen={isSuccessModalOpen}
            message={`${successMessage}!`}
            onClose={() => {
              setisSuccessModalOpen(false);
            }}
          />
        )}
      </Box>
    </>
  );
};

ControllerTypes.propTypes = {
  devices: PropTypes.array,
  handleRowClick: PropTypes.func,
  handleModalOpen: PropTypes.func,
};

export default ControllerTypes;
