import { Text, Box, Button, Flex, Spacer, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteDeviceModal from "../components/deleteDeviceModal";
import { EditFirmwareModal } from "../components/editFirmwareModal";
import FirmwareTable from "../components/firmwareTable";
import Loader from "../components/loader";
import SearchFilter from "../components/searchFilter";
import SuccessMessageModal from "../components/successMessageModal";
import {
  deleteFirmwareAPI,
  getFirmwareListAPI,
} from "../redux/helpers/firmwareAPI";
import Breadcrumbs from "../components/breadcrumbs";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

export default function Firmware() {
  const dispatch = useDispatch();
  const firmwareData = useSelector((state) => state.firmware.firmwareData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedFirmware, setSelectedFirmware] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const deletionType = "firmware";
  const navigate = useNavigate();

  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "facebook",
    },
  };

  const handleModalOpen = (firmware) => {
    setIsEditModalOpen(true);
    setSelectedFirmware(firmware);
  };

  useEffect(() => {
    if (!firmwareData?.length) {
      setLoading(true);
      let getFirmwareRes = dispatch(getFirmwareListAPI());
      getFirmwareRes.then((result) => {
        setfilteredFirmwareData(result?.payload?.data);
        setLoading(false);
      });
    } else {
      setfilteredFirmwareData(firmwareData);
    }
  }, []);

  const [searchParams, setSearchParams] = useState({
    name: "",
  });
  const [filteredFirmwareData, setfilteredFirmwareData] =
    useState(firmwareData);

  const handleSearch = () => {
    const newfilteredFirmwareData = firmwareData?.filter((firmware) => {
      const modelMatch =
        !searchParams.name ||
        firmware?.device_model?.name
          ?.toLowerCase()
          .includes(searchParams.name.toLowerCase());

      return modelMatch;
    });

    setfilteredFirmwareData(newfilteredFirmwareData);
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
    });

    setfilteredFirmwareData(firmwareData);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const handleDeleteModalOpen = (teamMember) => {
    setIsDeleteModalOpen(true);
    setSelectedFirmware(teamMember);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDeleteModel payload", payload);

      const resDeleteApi = dispatch(deleteFirmwareAPI(payload));
      resDeleteApi.then((res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage("Firmware Deleted successfully");
          dispatch(getFirmwareListAPI()).then((devices) => {
            setfilteredFirmwareData(devices?.payload?.data);
          });
          handleCloseDeleteModal();
          setisSuccessModalOpen(true);
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Firmware",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex>
        <Box>
          <Breadcrumbs />
        </Box>
        <Spacer />
        <Box style={{ marginInline: 8 }}>
          <Button
            {...styles.button}
            rightIcon={<AddIcon />}
            onClick={() => navigate("/add-firmware")}
          >
            Add Firmware
          </Button>
        </Box>
      </Flex>

      <Box {...styles.topBox} key={firmwareData?.length}>
        <SearchFilter
          type="model"
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <FirmwareTable
          data={filteredFirmwareData}
          handleModalOpen={handleModalOpen}
          handleDeleteModalOpen={handleDeleteModalOpen}
        />
        {isEditModalOpen && (
          <EditFirmwareModal
            isOpen={isEditModalOpen}
            onClose={handleEditCloseModal}
            selectedFirmware={selectedFirmware}
          />
        )}

        {isDeleteModalOpen && (
          <DeleteDeviceModal
            isOpen={isDeleteModalOpen}
            onclose={handleCloseDeleteModal}
            device={selectedFirmware}
            onSave={onSaveDeleteModel}
            type={deletionType}
          />
        )}

        {isSuccessModalOpen && (
          <SuccessMessageModal
            isOpen={isSuccessModalOpen}
            message={successMessage}
            onClose={() => {
              setisSuccessModalOpen(false);
              window.location.reload();
            }}
          />
        )}
      </Box>
    </Box>
  );
}
