import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Congratulations from "../assets/images/congratulations.jpg";
import ModalBackground from "../assets/images/modalBackground.jpg";

const SuspendCustomerModal = ({ isOpen, onclose, activeCustomer, onSave }) => {
  const [reason, setReason] = useState("");

  const styles = {
    sideimage: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgImage: Congratulations,
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    sideblank: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    main: {
      justifyContent: "center",
      alignItems: "center",
      w: "80%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    row: {
      marginY: "5%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    listrow: {
      marginY: "2%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    listtext: {
      w: "60%",
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "facebook",
    },
    listbutton: {
      w: "40%",
    },
    mainrow: {
      background: ModalBackground,
    },
    modalBody: {
      justifyContent: "start",
      display: "flex",
      alignItems: "start",
    },
    input: {
      borderRadius: 6,
      height: 35,
      marginTop: 10,
    },
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onclose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader {...styles.modalBody}>Confirm</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {activeCustomer?.is_suspended ? "To un-suspend " : "To suspend "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {activeCustomer?.name || activeCustomer?.first_name}
              </span>
              , Please provide appropriate reason.
            </Text>
            <Input
              style={{ ...styles.input }}
              id="reason"
              name="reason"
              type="text"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Reason"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              mr={2}
              size={"sm"}
              colorScheme="red"
              variant="outline"
              onClick={onclose}
            >
              Cancel
            </Button>
            <Button
              size={"sm"}
              isDisabled={reason?.length == 0}
              colorScheme="facebook"
              onClick={() => onSave(reason)}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

SuspendCustomerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  activeCustomer: PropTypes.string.isRequired,
  type: PropTypes.string,
};
export default SuspendCustomerModal;
