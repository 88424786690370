import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddDeviceModal from "./addDeviceModal";
import AddDeviceModelModal from "./addDeviceModelModal";
import AddDeviceTypeModal from "./addDeviceTypeModal";
import SuccessMessageModal from "./successMessageModal";
import {
  addModelAPI,
  addTypeAPI,
  getModelsAPI,
  getTypesAPI,
} from "../redux/helpers/controllerAPI";
import PropTypes from "prop-types";
import { getDeviceAPI } from "../redux/helpers/deviceAPI";
import { useToast } from "@chakra-ui/react";
import { getFirmwareListAPI } from "../redux/helpers/firmwareAPI";
import { AddFirmwareModal } from "./addFirmwareModal";
import Loader from "./loader";

const ControllerButtons = ({
  selectedDevice,
  forModify,
  modalIsOpen,
  reloadDataForAccount,
}) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [isDevFirmwareOpen, setIsDevFirmwareOpen] = useState(false);
  // const devices = useSelector((state) => state.controllers.devices);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    dispatch(getDeviceAPI());

    setIsModalOpen(modalIsOpen);
    setIsOpenedForModify(forModify);
    setSingleDevice(selectedDevice);
  }, [modalIsOpen, forModify, selectedDevice]);

  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(modalIsOpen);
  const [isOpenedForModify, setIsOpenedForModify] = useState(forModify);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSingleDevice("");
    setIsOpenedForModify(false);
    reloadDataForAccount();
  };

  const handleCloseDevFirwareModal = () => {
    setIsDevFirmwareOpen(false);
  };

  const [singleDevice, setSingleDevice] = useState(selectedDevice);

  // Manage Device Type
  const [isDevTypeModalOpen, setIsDevTypeModalOpen] = useState(false);

  const handleDevTypeCloseModal = () => {
    setIsDevTypeModalOpen(false);
  };

  const handleDevfirmwareOpen = () => {
    setIsDevFirmwareOpen(true);
  };

  const onSaveFirmware = async () => {
    setLoading(true);
    try {
      await dispatch(getFirmwareListAPI());
      setIsDevFirmwareOpen(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onSaveDevType = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDevType payload", payload);

      let addTypeRes = dispatch(addTypeAPI(payload));
      addTypeRes.then((data) => {
        if (data.payload.success) {
          handleDevTypeCloseModal();
          setSuccessMessage("Product Added successfully");
          dispatch(getTypesAPI());
          setisSuccessModalOpen(true);
          reloadDataForAccount();
          setLoading(false);
        } else {
          setLoading(false);
          toast({
            title: "Error",
            description: data?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
    }
  };

  // End - Manage Device Type

  // Manage Models
  // DevModel == Device Model
  const [isDevModelOpen, setIsDevModelOpen] = useState(false);

  const handleDevModelCloseModal = () => {
    setIsDevModelOpen(false);
  };

  const onSaveDevModel = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDevModel payload", payload);

      let addModelres = dispatch(addModelAPI(payload));
      addModelres.then((data) => {
        console.log("addModelres", data);
        if (data?.payload?.success) {
          setSuccessMessage("Model Added successfully");
          dispatch(getModelsAPI());
          setisSuccessModalOpen(true);
          handleDevModelCloseModal();
          reloadDataForAccount();
          setLoading(false);
        } else {
          setLoading(false);
          toast({
            title: "Error",
            description: data?.payload?.message || data?.payload?.error,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleDevTypeOpen = () => {
    setIsDevTypeModalOpen(true);
  };

  const handleDevModelOpen = () => {
    setIsDevModelOpen(true);
  };
  // End Manage Model

  // Reload Data for account

  /**
   * 1. reload devices types
   * 2. reload device models
   * 3. reload devices
   */

  // const reloadDataForAccount = () => {
  //   dispatch(getTypesAPI());
  //   dispatch(getDeviceAPI());
  //   dispatch(getModelsAPI());
  // };

  // End Reload Data for account

  return (
    <>
      <Loader loading={loading} />
      {/* <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem onClick={setIsModalOpen}>Add Device</MenuItem>
            <MenuItem onClick={setIsDevTypeModalOpen}>Add Device Type</MenuItem>
            <MenuItem>Mark as Draft</MenuItem>
            <MenuItem>Delete</MenuItem>
            <MenuItem>Attend a Workshop</MenuItem>
          </MenuList>
        </Menu> */}

      <AddDeviceModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        setIsModalOpen={setIsModalOpen}
        device={singleDevice}
        handleDevTypeOpen={handleDevTypeOpen}
        handleDevModelOpen={handleDevModelOpen}
        isModify={isOpenedForModify}
        reloadDataForAccount={reloadDataForAccount}
        handleDevfirmwareOpen={handleDevfirmwareOpen}
      />

      <AddDeviceModelModal
        isOpen={isDevModelOpen}
        onClose={handleDevModelCloseModal}
        setIsModalOpen={setIsDevModelOpen}
        onSave={onSaveDevModel}
      />

      <AddDeviceTypeModal
        isOpen={isDevTypeModalOpen}
        onClose={handleDevTypeCloseModal}
        setIsModalOpen={setIsDevTypeModalOpen}
        onSave={onSaveDevType}
      />

      {isDevFirmwareOpen && (
        <AddFirmwareModal
          isOpen={isDevFirmwareOpen}
          onClose={handleCloseDevFirwareModal}
          setIsModalOpen={setIsDevFirmwareOpen}
          onSaveFirmware={onSaveFirmware}
        />
      )}

      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`${successMessage}!`}
          onClose={() => {
            setisSuccessModalOpen(false);
            // window.location.reload();
            // reloadDataForAccount();
          }}
        />
      )}
    </>
  );
};

ControllerButtons.propTypes = {
  forModify: PropTypes.bool.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  selectedDevice: PropTypes.object.isRequired,
  reloadDataForAccount: PropTypes.func,
};

export default ControllerButtons;
