import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";
import { addTypeAPI, getTypesAPI } from "../redux/helpers/controllerAPI";
import { useDispatch } from "react-redux";

function ProductsTab() {
  const location = useLocation();
  const navigate = useNavigate();
  const { customer } = location.state || {};
  // const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [successMessage, setSuccessMessage] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const toast = useToast();

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
      width: "40rem",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    formItem: {
      m: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
    bredcrumbsContainer: {
      mb: 6,
      spacing: "8px",
      cursor: isHovered ? "pointer" : "default",
      textDecoration: isHovered ? "underline" : "default",
    },
    cancelButton: {
      mr: 5,
      size: "sm",
      justifyContent: "start",
    },
    submitButton: {
      colorScheme: "facebook",
      size: "sm",
      justifyContent: "end",
    },
  };

  const initialRef = useRef(null);

  const nameRegex = /^[a-zA-Z0-9\s]+$/;
  const formValidationSchema = Yup.object().shape({
    type: Yup.string()
      .matches(nameRegex, "Product name must contain only letters, numbers and spaces")
      .max(45, "Product name must be at most 45 characters.")
      .required("Product name is required"),
    notes: Yup.string().max(300, "Notes must be at most 300 characters."),
  });

  const onSave = (payload) => {
    setLoading(true);
    try {
      let addTypeRes = dispatch(addTypeAPI(payload));
      addTypeRes.then((data) => {
        const { success, message } = data?.payload;
        if (success) {
          dispatch(getTypesAPI())
            .then(() => {
              toast({
                title: "Success",
                position: "top-right",
                description: message,
                status: "success",
                duration: 6000,
                isClosable: true,
              });
              setLoading(false);
              handleBackClick();
            })
            .catch((error) => {
              console.log(error);
              toast({
                title: "Error",
                description: "Error in fetching products!",
                status: "error",
                duration: 6000,
                isClosable: true,
              });
            });
        } else {
          setLoading(false);
          toast({
            title: "Error",
            description: data?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Something went wrong!",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleBackClick = () => {
    navigate("/product-configuration", { state: { tourindex: 0 } });
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex
        direction={"row"}
        justifyContent={"space-between"}
        alignContent={"center"}
      >
        <Text
          {...styles.bredcrumbsContainer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Add New Product
        </Text>
      </Flex>
      <Card size={{ base: "sm", md: "md" }}>
        <CardBody>
          <Flex {...styles.flexContainer}>
            <Formik
              initialValues={{
                type: "",
                notes: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => {
                const payload = {
                  note: values?.notes,
                  type: values?.type,
                };
                onSave(payload);
              }}
            >
              {({ handleSubmit, errors, touched, resetForm }) => (
                <form onSubmit={handleSubmit}>
                  <VStack {...styles.formStack}>
                    <FormControl isInvalid={errors?.type && touched?.type}>
                      <FormLabel htmlFor="type">Name</FormLabel>
                      <Field
                        as={Input}
                        ref={initialRef}
                        {...styles.input}
                        id="type"
                        name="type"
                        type="text"
                        placeholder="Product Name"
                      />
                      <FormErrorMessage>{errors?.type}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors?.notes && touched?.notes}>
                      <FormLabel htmlFor="notes">Notes</FormLabel>
                      <Field
                        as={Textarea}
                        {...styles.input}
                        id="notes"
                        name="notes"
                        type="textarea"
                        placeholder="Notes for this type"
                      />

                      <FormErrorMessage>{errors?.notes}</FormErrorMessage>
                    </FormControl>

                    <Box justifyContent={"flex-end"} width={"100%"}></Box>
                  </VStack>
                  <Flex mt={4} justifyContent={"spa"}>
                    <Button
                      colorScheme="red"
                      variant="outline"
                      size="sm"
                      mr={5}
                      onClick={handleBackClick}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => {
                        resetForm();
                      }}
                      {...styles.cancelButton}
                    >
                      Reset
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      {...styles.submitButton}
                    >
                      Create Product
                    </Button>
                  </Flex>
                </form>
              )}
            </Formik>
            {/* {isSuccessModalOpen && (
              <SuccessMessageModal
                isOpen={isSuccessModalOpen}
                message={`Device ${successMessage} successfully!`}
                onClose={() => {
                  setisSuccessModalOpen(false);
                }}
              />
            )} */}
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
}

export default ProductsTab;
