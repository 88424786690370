/**
 * Used to Manage Team Member for WG Team ( Internal )
 */
import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addMemberAPI = createAsyncThunk(
  "addMember",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/manufacturer/team`,
        {
          first_name: payload.first_name,
          last_name: payload.last_name,
          role_id: payload.privilege,
          email: payload.email,
          phone_number: payload.phone_number,
        },
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const addSellerMemberAPI = createAsyncThunk(
  "addSellerMemberAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/seller/team`,
        {
          first_name: payload.first_name,
          last_name: payload.last_name,
          role_id: payload.privilege,
          email: payload.email,
          phone_number: payload.phone_number,
        },
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const editMemberAPI = createAsyncThunk(
  "editMember",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.put(
        `/manufacturer/team/${payload.member_id}`,
        {
          first_name: payload.first_name,
          last_name: payload.last_name,
          role_id: payload.privilege,
          email: payload.email,
          phone_number: payload.phone_number,
          // is_approved: payload.is_approved,
        },
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const editSellerMemberAPI = createAsyncThunk(
  "editSellerMemberAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.put(
        `/seller/team/${payload.member_id}`,
        {
          first_name: payload.first_name,
          last_name: payload.last_name,
          role_id: payload.privilege,
          email: payload.email,
          phone_number: payload.phone_number,
          // is_approved: payload.is_approved,
        },
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getMemberAPI = createAsyncThunk(
  "getMember",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(`/manufacturer/team`, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const getSellerMemberAPI = createAsyncThunk(
  "getSellerMemberAPI",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(`/seller/team`, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);
/**
 * updateTypesAPI - Use this for both update and soft-delete
 */

export const deleteMemberAPI = createAsyncThunk(
  "deleteMemberAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.delete(
        `/manufacturer/team/${payload?.member_id}`,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const deleteSellerMemberAPI = createAsyncThunk(
  "deleteSellerMemberAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.delete(
        `/seller/team/${payload?.member_id}`,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const approveMemberAPI = createAsyncThunk(
  "approveMemberAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.put(
        `/manufacturer/approve/team-member/${payload?.member_id}`,
        payload,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);

export const approveSellerMemberAPI = createAsyncThunk(
  "approveSellerMemberAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.put(
        `/seller/approve/team-member/${payload?.member_id}`,
        payload,
        config
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  }
);