import React from "react";
import {
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import CustomerDevices from "./customerDevices";
// import CustomerBilling from "./customerBilling";
// import CustomerTickets from "./customerTickets";
import CustomerTeam from "./customerTeamTable";

function CustomerTabs() {
  const styles = {
    graphBox: {
      p: 4,
      boxShadow: "md",
      bgColor: "white",
      borderRadius: 6,
    },
    tabHeading: {
      fontWeight: "bold",
    },
    tabindecater: {
      mt: "-1.5px",
      height: "2px",
      bg: "blue.500",
      borderRadius: "1px",
    },
  };

  return (
    <Box {...styles.graphBox}>
      <Tabs position="relative" variant="unstyled">
        <TabList>
          <Tab {...styles.tabHeading}>Devices</Tab>
          <Tab {...styles.tabHeading}>Team</Tab>
          {/* <Tab {...styles.tabHeading}>Billing</Tab>
          <Tab {...styles.tabHeading}>Tickets</Tab> */}
        </TabList>
        <TabIndicator {...styles.tabindecater} />
        <TabPanels>
          <TabPanel p={0}>
            <CustomerDevices />
          </TabPanel>
          <TabPanel>
            <CustomerTeam />
          </TabPanel>
          {/* <TabPanel p={0}>
            <CustomerBilling />
          </TabPanel>
          <TabPanel p={0}>
            <CustomerTickets />
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default CustomerTabs;
