import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Checkbox,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { GoDotFill } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/breadcrumbs";
import ControllerSearchFilters from "../components/controllersSearchFilters";
import Loader from "../components/loader";
import { assignDeviceAPI, getDeviceAPI } from "../redux/helpers/deviceAPI";
import SuccessMessageModal from "../components/successMessageModal";
import CustomerDetails from "../components/customerDetails";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";

function AssignDeviceToCustomerPop() {
  const { onClose } = useDisclosure();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { customer } = location.state || {};
  const devices = useSelector((state) =>
    state?.controllers?.devices?.filter(
      (device) => device?.assigned_to_seller_organization?.id == null
    )
  );

  const initialRef = useRef(null);

  const [filteredDevices, setFilteredDevices] = useState(devices);
  const dispatch = useDispatch();
  const toast = useToast();
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);

  const [searchParams, setSearchParams] = useState({
    name: "",
    type: "",
    status: "",
    model: "",
  });

  const activeCustomerOrg = useSelector(
    (state) => state?.customer?.activeCustomerOrg
  );

  const [progressCount, setProgressCount] = useState(0);

  const firmware = useSelector((state) => state?.firmware?.firmwareData);

  const styles = {
    flexContainer: {
      gap: 4,
      flexDirection: "column",
    },
    formFlex: {
      alignItems: "center",
      gap: 2,
    },
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
    },
    buttonFlex: {
      justifyContent: "end",
    },
    cancelButton: {
      mr: 5,
      size: "sm",
      width: "10rem",
    },
    submitButton: {
      size: "sm",
      colorScheme: "facebook",
      width: "10rem",
    },
    tableContainer: {
      overflowY: "auto",
      h: "40vh",
    },
    indecatorBox: {
      mb: -1,
      pr: 2,
    },
    deviceName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    deviceModel: {
      fontSize: 13,
    },
    latestVersionText: {
      fontSize: 12,
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
      width: "100%",
    },
    flexContainer2: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  };

  const [checkedItems, setCheckedItems] = useState([false, false]);

  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedDevice, setSelectedDevice] = useState();

  const assignDevice = () => {
    setLoading(true);
    try {
      for (let i = 0; i < addIDS?.length; i++) {
        const payload = {
          device_id: addIDS[i],
          assigned_to_id: activeCustomerOrg?.id,
        };
        const onAssignedReq = dispatch(assignDeviceAPI(payload));
        onAssignedReq.then(async (data) => {
          if (data?.payload?.success) {
            await dispatch(getDashboardDataAPI());
            dispatch(getDeviceAPI())
              .then((response) => {
                setFilteredDevices(
                  response?.payload?.data?.filter(
                    (device) =>
                      device?.assigned_to_seller_organization?.id == null
                  )
                );
                toast({
                  title: "Success",
                  position: "top-right",
                  description: data?.payload.message,
                  status: "success",
                  duration: 6000,
                  isClosable: true,
                });
                setLoading(false);
                navigate(-1);
                onCloseModal();
                onClose();
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
              });
          } else {
            setLoading(false);
            onCloseModal();
            toast({
              title: "Error while assigning",
              description: data?.payload.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        });
        setProgressCount(i + 1);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleSearch = () => {
    const newFilteredDevices = devices?.filter((device) => {
      const nameMatch =
        !searchParams?.name ||
        device?.name
          ?.toLowerCase()
          .includes(searchParams?.name?.toLowerCase()) ||
        device?.serial_number
          ?.toLowerCase()
          .includes(searchParams?.name?.toLowerCase());
      const typeMatch =
        !searchParams?.type ||
        device?.device_type?.id == searchParams?.type?.toLowerCase();
      const statusMatch =
        !searchParams?.status ||
        device?.status?.toLowerCase() === searchParams?.status?.toLowerCase();
      const modelMatch =
        !searchParams?.model ||
        device?.device_model?.id == searchParams?.model?.toLowerCase();

      return nameMatch && typeMatch && statusMatch && modelMatch;
    });

    setFilteredDevices(newFilteredDevices);
  };

  const handleResetCheckbox = () => {
    initialRef?.current?.reset();
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
      type: "",
      status: "",
      model: "",
    });

    setFilteredDevices(devices);
  };

  const [addIDS, setAddIDS] = useState([]);

  const handleAddIds = (device) => {
    if (addIDS.includes(device?.id)) {
      let newIds = [...addIDS];
      const index = newIds?.indexOf(device?.id);
      newIds.splice(index, 1);
      setAddIDS(newIds);
    } else {
      setAddIDS((prev) => [...prev, device?.id]);
    }
  };

  const handleRowClick = () => {
    setIsOpen(true);
    // setSelectedDevice(deviceData);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const onAddProductBtn = () => {
    navigate("/add-product");
  };

  return (
    <>
      <Box>
        {progressCount != 0 ? (
          <Progress hasStripe value={progressCount / addIDS?.length} />
        ) : null}
        <Loader loading={loading} />
        <Flex>
          <Box>
            <Breadcrumbs />
          </Box>
        </Flex>
        <Flex {...styles.flexContainer}>
          {/* <CustomerDetails /> */}
          <Card size={{ base: "sm", md: "md" }}>
            <CardBody>
              <Flex {...styles.flexContainer2}>
                <Flex
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <Avatar {...styles.avatar} name={activeCustomerOrg?.name} />
                  <Box ml={4}>
                    <Flex gap={2}>
                      <Heading {...styles.deviceHeader}>
                        {activeCustomerOrg?.name}
                      </Heading>
                    </Flex>
                    <Flex {...styles.subheaderItems} mt={1}>
                      {activeCustomerOrg?.first_name}{" "}
                      {activeCustomerOrg?.last_name}
                    </Flex>

                    <Flex {...styles.subheaderItems} mt={1}>
                      <Flex {...styles.subheaderItems}>
                        <PhoneIcon />
                        <Text {...styles.subheaderText}>
                          {activeCustomerOrg?.phone_number}{" "}
                        </Text>
                      </Flex>
                      <Flex
                        {...styles.subheaderItems}
                        style={{ marginLeft: 16 }}
                      >
                        <EmailIcon />
                        <Text {...styles.subheaderText}>
                          {activeCustomerOrg?.email}{" "}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
          <Flex flexDirection={"column"}>
            <Box {...styles.topBox}>
              <ControllerSearchFilters
                searchParams={searchParams}
                handleInputChange={handleInputChange}
                handleSearch={handleSearch}
                handleReset={handleReset}
              />

              {filteredDevices?.length > 0 ? (
                <TableContainer {...styles.tableContainer}>
                  <Table
                    size={"sm"}
                    // variant="striped"
                    colorScheme="blackAlpha"
                  >
                    <Thead>
                      <Tr>
                        <Th width="2%">
                          {/* <Checkbox
                          isChecked={allChecked}
                          isIndeterminate={isIndeterminate}
                          onChange={(e) =>
                            setCheckedItems([
                              e.target.checked,
                              e.target.checked,
                            ])
                          }
                        ></Checkbox> */}
                        </Th>
                        <Th width="30%">NAME</Th>
                        <Th width="20%">PRODUCT</Th>
                        <Th width="15%">MODEL</Th>
                        {/* <Th width="12%">PRESSURE THRESHOLD</Th> */}
                        <Th width="15%">FIRMWARE</Th>
                        {/* <Th width="12%">MANUFACTURER</Th> */}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredDevices?.map((device, index) => {
                        return (
                          <Tr
                            key={index}
                            // onClick={() => handleRowClick(device)}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleAddIds(device);
                            }}
                          >
                            <Td>
                              <Checkbox
                                isChecked={addIDS.includes(device.id)}
                                onChange={() => handleAddIds(device)}
                                initialRef={initialRef}
                              ></Checkbox>
                            </Td>
                            <Td>
                              <Flex>
                                <Center>
                                  <Box {...styles.indecatorBox}>
                                    <GoDotFill
                                      color={
                                        device?.status === "online"
                                          ? "green"
                                          : "red"
                                      }
                                    />
                                  </Box>
                                  <Box>
                                    <Text {...styles.deviceName}>
                                      {" "}
                                      Sl. No {device?.serial_number}{" "}
                                      {device?.name == null
                                        ? "unassigned"
                                        : device?.name}{" "}
                                    </Text>
                                    <Text {...styles.deviceModel}>
                                      {device?.device_model?.name}
                                    </Text>
                                  </Box>
                                </Center>
                              </Flex>
                            </Td>
                            <Td>
                              <Text>{device?.device_type?.name}</Text>
                            </Td>
                            <Td>
                              <Text> {device?.device_model?.name} </Text>
                            </Td>
                            {/* <Td>
                              {device?.data_points?.map((val) => {
                                return (
                                  <>
                                    {val?.minimum} - {val?.maximum}{" "}
                                    {val?.unit?.unit}
                                  </>
                                );
                              })}
                            </Td> */}
                            <Td>
                              {device?.firmware_version
                                ? firmware
                                    ?.filter((val) => {
                                      return (
                                        val?.version_number ==
                                        device?.firmware_version
                                      );
                                    })
                                    .map((val) => {
                                      return val?.name;
                                    })
                                : "N/A"}
                            </Td>
                            {/* <Td>{device?.manufacturer.organization}</Td> */}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Flex
                  justify={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Text display="flex" justifyContent="center" p={4}>
                    No device to assign
                  </Text>
                  <Button width={200} onClick={() => onAddProductBtn()}>
                    {" "}
                    Add New Product
                  </Button>
                </Flex>
              )}
              <Flex mt={5} justifyContent={"right"} mr={4}>
                {filteredDevices?.length > 0 ? (
                  <>
                    <Button
                      colorScheme="red"
                      variant="outline"
                      size="sm"
                      mr={5}
                      width="10rem"
                      onClick={handleBackClick}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant={"outline"}
                      size="sm"
                      onClick={() => {
                        handleResetCheckbox();
                        setAddIDS([]);
                      }}
                      {...styles.cancelButton}
                    >
                      Clear Section
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleRowClick}
                      {...styles.submitButton}
                    >
                      Save
                    </Button>{" "}
                  </>
                ) : null}
              </Flex>
            </Box>
          </Flex>
        </Flex>
        <Modal isOpen={isOpen} onClose={onCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader {...styles.modalBody}>Assign Products</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                Do you want to assign {`products`} to {customer?.name} ?
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                mr={2}
                size={"sm"}
                colorScheme="red"
                variant="outline"
                onClick={() => {
                  onCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button
                size={"sm"}
                colorScheme="facebook"
                onClick={() => {
                  assignDevice();
                }}
              >
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`Devices has been assigned successfully!`}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </>
  );
}

AssignDeviceToCustomerPop.prototypes = {
  deviceData: PropTypes.object,
};

export default AssignDeviceToCustomerPop;
