import { CloseIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ModelFooterButtons from "./modelFooterButtons";
import { useSelector } from "react-redux";
import {
  assignDeviceAPI,
  getDeviceAPI,
  updateDeviceAPI,
} from "../redux/helpers/deviceAPI";
import { getCustomerAPI } from "../redux/helpers/customerAPI";
import { Field, FieldArray, Formik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import Loader from "./loader";
import { deviceConfigHistoryAPI } from "../redux/helpers/controllerAPI";
import _ from "lodash";

const EditDeviceModal = ({
  isOpen,
  onClose,
  device,
  reloadDataForAccount,
  setisSuccessModalOpen,
  setSuccessMessage,
}) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const units = useSelector((state) => state?.controllers?.units);
  const models = useSelector((state) => state?.controllers?.models);
  const [device_type, setDeviceType] = useState(null);
  const [customer_id, setAssignCustomer] = useState([]);
  const [currentModels, setCurrentModels] = useState(
    models?.filter((model) => model.device_type.id == device?.device_type?.id)
  );
  var payloadData = {};
  const types = useSelector((state) => state?.controllers?.types);
  const customerOrgList = useSelector((state) => state.customer.organizations);
  const [isAssignToCustomer, setIsAssignToCustomer] = useState(
    device?.assigned_to_seller_organization?.id
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    dispatch(getCustomerAPI());
  }, []);

  useEffect(() => {
    // this is used to set existing device data to UI

    if (device?.update) {
      let currentModelsSelected = models?.filter(
        (model) => model.device_type.id == device?.device_type?.id
      );
      setCurrentModels(currentModelsSelected);
    }

    if (device_type) {
      let currentModelsSelected = models?.filter(
        (model) => model.device_type.id == device_type
      );
      setCurrentModels(currentModelsSelected);
    } else {
      setCurrentModels(models);
    }
  }, [models?.length]);

  const onTypeSelected = (typeID) => {
    setDeviceType(typeID);
    let currentModelsSelected = models?.filter(
      (model) => model.device_type.id == typeID
    );
    setCurrentModels(currentModelsSelected);
  };

  const onCustomerSelected = (customer) => {
    setAssignCustomer(customer);
  };

  // Reload Data for account

  /**
   * 1. reload devices types
   * 2. reload device models
   * 3. reload devices
   */

  // const reloadDataForAccount = () => {
  //   dispatch(getTypesAPI(token));
  //   dispatch(getDeviceAPI(token));
  //   dispatch(getModelsAPI(token));
  // }

  // End Reload Data for account
  const nameRegex = /^[a-zA-Z0-9-\s]+$/;
  const formValidationSchema = Yup.object().shape({
    device_name: Yup.string()
      .max(45, "Model name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Device name can only contain Letters, Numbers & Spaces"
      )
      .required("Device name is required"),
    device_type: Yup.string().required("Device type is required"),
    device_model: Yup.string().required("Device model is required"),
    device_attributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          // .required("Monitoring Value is required")
          .max(45, "Monitoring Value must be at most 45 characters.")
          .matches(
            nameRegex,
            "Monitoring Value can only contain Letters, Numbers & Spaces"
          ),
        min: Yup.number().required("Minimum value is required"),
        max: Yup.number().required("Maximum value is required"),
        unit_id: Yup.string().required("Unit is required"),
      })
    ),
  });

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  return (
    <>
      <Loader loading={loading} />
      <Modal
        blockScrollOnMount={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Modify Unit</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                device_name: device?.name || "",
                device_type: device?.device_type?.id || "",
                device_model: device?.device_model?.id || "",
                serial_no: device?.serial_number || "",
                device_attributes:
                  (device?.data_points &&
                    device?.data_points?.map((dataPoint) => ({
                      monitoring_value: dataPoint?.unit?.type || "",
                      unit_id: dataPoint?.unit?.id || "",
                      min: dataPoint?.minimum || "",
                      max: dataPoint?.maximum || "",
                      data_point_id: dataPoint?.id || "",
                    }))) ||
                  [],
                provisioning_status:
                  device?.provision_status == "Initialized" ? true : false,
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => {
                setLoading(true);
                try {
                  const provision_status_value =
                    device?.provision_status == "Ready" ? true : false;
                  const data = {
                    name:
                      device?.name == values?.device_name
                        ? ""
                        : values?.device_name,
                    type_id:
                      device?.device_type?.id == values?.device_type
                        ? ""
                        : values?.device_type,
                    model_id:
                      device?.device_model?.id == values?.device_model
                        ? ""
                        : values?.device_model,
                    provisioningStatus:
                      provision_status_value == values?.provisioning_status
                        ? ""
                        : values?.provisioning_status,
                    data_points: values.device_attributes,
                    device_id: device?.id,
                  };

                  const payload = Object.fromEntries(
                    Object.entries(data).filter(([, value]) => value !== "")
                  );

                  const updateDeviceReq = dispatch(updateDeviceAPI(payload));
                  updateDeviceReq
                    .then(async (data) => {
                      if (data?.payload?.success) {
                        if (!device?.assigned_to_customer?.id) {
                          const assign_data = {
                            device_id: device?.id,
                            assigned_to_id: customer_id,
                          };
                          dispatch(assignDeviceAPI(assign_data));
                        }
                        dispatch(getDeviceAPI());
                        dispatch(
                          deviceConfigHistoryAPI({ device_id: device?.id })
                        );
                        onClose();
                        setSuccessMessage("Device updated successfully");
                        setisSuccessModalOpen(true);
                        setLoading(false);
                      } else {
                        await dispatch(getDeviceAPI());
                        await dispatch(
                          deviceConfigHistoryAPI({ device_id: device?.id })
                        );
                        toast({
                          title: "Error while updating Controller",
                          description:
                            data?.payload?.message || data?.payload?.error,
                          status: "error",
                          duration: 6000,
                          isClosable: true,
                        });
                        // onClose();
                        setLoading(false);
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    });
                } catch (err) {
                  setLoading(false);
                  console.log("errror");
                }
              }}
            >
              {({
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                values,
                handleChange,
              }) => {
                const device_attributes =
                  (device?.data_points &&
                    device?.data_points?.map((dataPoint) => ({
                      monitoring_value: dataPoint?.unit?.type || "",
                      unit_id: dataPoint?.unit?.id || "",
                      min: dataPoint?.minimum || "",
                      max: dataPoint?.maximum || "",
                      data_point_id: dataPoint?.id || "",
                    }))) ||
                  [];
                const provision_status_value =
                  device?.provision_status == "Initialized" ? true : false;
                const data = {
                  name:
                    device?.name == values?.device_name
                      ? ""
                      : values?.device_name,
                  type_id:
                    device?.device_type?.id == values?.device_type
                      ? ""
                      : values?.device_type,
                  model_id:
                    device?.device_model?.id == values?.device_model
                      ? ""
                      : values?.device_model,
                  provisioningStatus:
                    provision_status_value == values?.provisioning_status
                      ? ""
                      : values?.provisioning_status,
                  data_points: _.isEqual(
                    values.device_attributes,
                    device_attributes
                  )
                    ? ""
                    : values.device_attributes,
                };

                const payload = Object.fromEntries(
                  Object.entries(data).filter(([, value]) => value !== "")
                );
                payloadData = { ...payload };

                return (
                  <form onSubmit={handleSubmit}>
                    <FormControl {...styles.formItem}>
                      <FormLabel> Device Serial No </FormLabel>

                      <Field
                        as={Input}
                        {...styles.input}
                        style={{ marginTop: 10 }}
                        ref={initialRef}
                        placeholder="Serial No"
                        value={device?.serial_number}
                        disabled={true}
                        id="serial_no"
                        name="serial_no"
                      />
                      <FormErrorMessage>{errors.serial_no}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.device_name && touched.device_name}
                    >
                      <FormLabel>Name</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        defaultValue={device?.name}
                        placeholder="Device name"
                        name="device_name"
                      />
                      <FormErrorMessage>{errors.device_name}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.device_type && touched.device_type}
                    >
                      <FormLabel>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          Type
                        </Box>
                      </FormLabel>
                      <Select
                        {...styles.input}
                        id="device_type"
                        disabled={true}
                        defaultValue={device?.device_type?.id}
                        name="device_type"
                        placeholder="Select Type"
                        onChange={(event) => {
                          setFieldValue("device_type", event.target.value);
                          onTypeSelected(event.target.value);
                        }}
                      >
                        {types?.map((val, index) => {
                          return (
                            <option key={index} value={val.id}>
                              {val.name}
                            </option>
                          );
                        })}
                      </Select>
                      <FormErrorMessage>{errors.device_type}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.device_model && touched.device_model}
                    >
                      <FormLabel>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          Model
                        </Box>
                      </FormLabel>
                      <Select
                        {...styles.input}
                        placeholder="Select Type"
                        id="device_model"
                        disabled={true}
                        defaultValue={device?.device_model?.id}
                        name="device_model"
                        onChange={(event) => {
                          setFieldValue("device_model", event.target.value);
                        }}
                      >
                        {currentModels?.map((val, index) => {
                          return (
                            <option key={index} value={val.id}>
                              {val.name}
                            </option>
                          );
                        })}
                      </Select>
                      <FormErrorMessage>{errors.device_model}</FormErrorMessage>
                    </FormControl>
                    <Text style={{ marginTop: 10 }}>
                      Device Data Attributes
                    </Text>
                    <Text
                      style={{
                        marginTop: 6,
                        fontSize: 12,
                        fontStyle: "normal",
                        fontWeight: 300,
                      }}
                    >
                      This encompasses both the collection of data points
                      through the device and the ability for the customer to
                      adjust alert thresholds.
                    </Text>
                    {device && (
                      <FieldArray
                        name="device_attributes"
                        style={{
                          marginBottom: 22,
                          padding: 4,
                          marginTop: 6,
                          border: "1px dotted lightgrey",
                          borderRadius: 8,
                        }}
                      >
                        {(arrayHelpers) => (
                          <>
                            {values.device_attributes.map((_, index) => (
                              <Box
                                key={index}
                                style={{
                                  marginBottom: 22,
                                  padding: 6,
                                  marginTop: 4,
                                  border: "1px dotted lightgrey",
                                  borderRadius: 8,
                                }}
                              >
                                {/* <Flex justifyContent={"end"}>
                                <IconButton
                                  size={"xs"}
                                  fontSize={"10px"}
                                  color={"red"}
                                  icon={<CloseIcon />}
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </Flex> */}

                                <FormControl
                                  // marginTop={2}
                                  isInvalid={
                                    errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    touched.device_attributes &&
                                    touched.device_attributes[index]
                                  }
                                >
                                  <FormLabel fontSize={"13px"}>
                                    {`Monitoring Value ${index + 1}`}
                                  </FormLabel>
                                  <Input
                                    {...styles.input}
                                    // disabled
                                    placeholder={`Monitoring Value`}
                                    name={`device_attributes.${index}.monitoring_value`}
                                    // value={values.device_attributes[index].name}
                                    defaultValue={
                                      device?.data_points[index]?.unit?.type
                                    }
                                    onChange={handleChange}
                                    // disabled
                                  />
                                  <FormErrorMessage>
                                    {errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      errors.device_attributes[index].name}
                                  </FormErrorMessage>
                                  <FormControl
                                    pt={2}
                                    isInvalid={
                                      errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      touched.device_attributes &&
                                      touched.device_attributes[index]
                                    }
                                  >
                                    <FormLabel fontSize={"13px"}>
                                      Unit
                                    </FormLabel>
                                    <Select
                                      {...styles.input}
                                      // disabled
                                      placeholder="Select Unit"
                                      name={`device_attributes.${index}.unit_id`}
                                      id={`device_attributes.${index}.unit_id`}
                                      value={
                                        values.device_attributes[index].unit_id
                                      }
                                      // defaultValue={
                                      //   device?.model_data_points[index]?.data_point
                                      //     ?.id
                                      // }
                                      onChange={handleChange}
                                    >
                                      {units?.map((val) => (
                                        <option key={uuidv4()} value={val.id}>
                                          {val.name}
                                        </option>
                                      ))}
                                    </Select>
                                    <FormErrorMessage>
                                      {errors.device_attributes &&
                                        errors.device_attributes[index] &&
                                        errors.device_attributes[index].unit_id}
                                    </FormErrorMessage>
                                  </FormControl>
                                </FormControl>
                                <Center pt={2}>
                                  <FormControl
                                    isInvalid={
                                      errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      touched.device_attributes &&
                                      touched.device_attributes[index]
                                    }
                                  >
                                    <FormLabel fontSize={"13px"}>
                                      Minimum
                                    </FormLabel>
                                    <Input
                                      {...styles.input}
                                      // disabled
                                      type="number"
                                      placeholder="Minimum"
                                      name={`device_attributes.${index}.min`}
                                      // value={values.device_attributes[index].min}
                                      defaultValue={
                                        device?.data_points[index]?.minimum
                                      }
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {errors.device_attributes &&
                                        errors.device_attributes[index] &&
                                        errors.device_attributes[index].min}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <Box px={2}></Box>
                                  <FormControl
                                    isInvalid={
                                      errors.device_attributes &&
                                      errors.device_attributes[index] &&
                                      touched.device_attributes &&
                                      touched.device_attributes[index]
                                    }
                                  >
                                    <FormLabel fontSize={"13px"}>
                                      Maximum
                                    </FormLabel>
                                    <Input
                                      {...styles.input}
                                      // disabled
                                      type="number"
                                      placeholder="Maximum"
                                      name={`device_attributes.${index}.max`}
                                      // value={values.device_attributes[index].max}
                                      defaultValue={
                                        device?.data_points[index]?.maximum
                                      }
                                      onChange={handleChange}
                                    />
                                    <FormErrorMessage>
                                      {errors.device_attributes &&
                                        errors.device_attributes[index] &&
                                        errors.device_attributes[index].max}
                                    </FormErrorMessage>
                                  </FormControl>
                                </Center>
                              </Box>
                            ))}
                            {/* <Button
                            // isDisabled={true}
                            onClick={() =>
                              arrayHelpers.push({
                                name: "",
                                min: "",
                                max: "",
                                unit_id: "",
                              })
                            }
                            size={"xs"}
                            colorScheme="teal"
                          >
                            Add Attribute
                          </Button> */}
                          </>
                        )}
                      </FieldArray>
                    )}

                    <FormControl
                      {...styles.formItem}
                      isInvalid={
                        errors.assign_Customer && touched.assign_Customer
                      }
                    >
                      <Checkbox
                        name="assign_Customer"
                        colorScheme="green"
                        isDisabled={device?.assigned_to_seller_organization?.id}
                        isChecked={isAssignToCustomer}
                        onChange={(e) =>
                          setIsAssignToCustomer(e.target.checked)
                        }
                      >
                        Assign to Customers?
                      </Checkbox>
                      {isAssignToCustomer && (
                        <Select
                          marginTop={"10px"}
                          {...styles.input}
                          placeholder="Select Customer"
                          id="assign_Customer"
                          disabled={device?.assigned_to_seller_organization?.id}
                          defaultValue={
                            device?.assigned_to_seller_organization?.id
                          }
                          name="assign_Customer"
                          onChange={(event) =>
                            onCustomerSelected(event.target.value)
                          }
                        >
                          {customerOrgList?.map((val, index) => {
                            return (
                              <option key={index} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </Select>
                      )}
                      <FormErrorMessage>
                        {errors.assign_Customer}
                        {errors.assign_Customer}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={
                        errors.provisioning_status &&
                        touched.provisioning_status
                      }
                    >
                      <FormLabel>Provision Status </FormLabel>
                      <Checkbox
                        name="provisioning_status"
                        id="provisioning_status"
                        colorScheme="green"
                        isChecked={values?.provisioning_status}
                        onChange={(e) =>
                          setFieldValue("provisioning_status", e.target.checked)
                        }
                      >
                        Provisioned
                      </Checkbox>
                      <FormErrorMessage>
                        {errors.provisioning_status}
                      </FormErrorMessage>
                    </FormControl>
                    <ModelFooterButtons
                      disabled={Object.keys(payloadData)?.length == 0}
                      onClose={onClose}
                      onSave={handleSubmit}
                    />
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

EditDeviceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  reloadDataForAccount: PropTypes.func,
  setisSuccessModalOpen: PropTypes.func,
  setSuccessMessage: PropTypes.func,
};
export default EditDeviceModal;
