import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Breadcrumbs from "../components/breadcrumbs";
import CustomerDetails from "../components/customerDetails";
import CustomerTabs from "../components/customerTabs";

function Customer() {
  const styles = {
    flexContainer: {
      gap: 4,
      flexDirection: "column",
    },
  };
  return (
    <Box>
      <Breadcrumbs />
      <Flex {...styles.flexContainer}>
        <CustomerDetails />
        <CustomerTabs />
      </Flex>
    </Box>
  );
}

export default Customer;
